import React from 'react';
import { Tabs } from '@multifarm/widget-components';
// Static
import { RANGES } from '../../../common/static';
export const ChartCardHeader = ({ rangeOptions }) => {
    let rangesComponent;
    if (rangeOptions) {
        rangesComponent = (React.createElement(Tabs, { tabs: RANGES, value: rangeOptions.range, onChange: (value) => {
                rangeOptions.onRange(value);
            }, large: true }));
    }
    if (!rangesComponent) {
        return null;
    }
    return React.createElement(React.Fragment, null, rangesComponent);
};
