export var formatDate = function (date, options) {
    var _date = typeof date === 'string' ? new Date(date) : date;
    return _date.toLocaleDateString('en', options);
};
export var formatAxisDate = function (date) {
    return formatDate(date, { month: 'short', day: '2-digit' });
};
export var formatTooltipDate = function (date) {
    return formatDate(date, {
        month: 'long',
        day: '2-digit',
        year: 'numeric'
    });
};
export function formatNumber(_a) {
    var value = _a.value, _b = _a.zero, zero = _b === void 0 ? true : _b, _c = _a.digits, digits = _c === void 0 ? 2 : _c;
    if (!value) {
        return zero ? 0 : 'N/A';
    }
    var num = Math.abs(value);
    var isNegative = value < 0;
    var lookup = [
        { value: 1e9, symbol: 'B' },
        { value: 1e6, symbol: 'M' },
        { value: 1e3, symbol: 'k' }
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.find(function (item) { return num >= item.value; });
    var formattedNumber = item
        ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
        : num;
    return isNegative ? "-".concat(formattedNumber) : formattedNumber;
}
export var formatChartMoney = function (value) {
    return "$".concat(formatNumber({ value: value }));
};
export var formatTooltipMoney = function (value) {
    return value.toLocaleString('en', { style: 'currency', currency: 'USD' });
};
export var formatPercent = function (value, fixed) {
    if (fixed === void 0) { fixed = 2; }
    return "".concat(value.toFixed(fixed), "%");
};
