// Libs
import React, { useMemo } from 'react';
import { SWRConfig } from 'swr';
// Components
import { Header } from './components/Header';
// Pages
import { Overview } from './pages/overview';
// Utils
import { getFetcher } from './utils/api';
// Styles
import './styles/global.css';
export var BastionDashboard = function (_a) {
    var token = _a.token;
    var config = useMemo(function () { return ({ fetcher: getFetcher(token) }); }, [token]);
    return (React.createElement(SWRConfig, { value: config },
        React.createElement("div", { className: "multifarm-bastion-dashboard" },
            React.createElement(Header, null),
            React.createElement("main", null,
                React.createElement(Overview, null)))));
};
