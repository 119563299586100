var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ethers } from 'ethers';
// Components
import { Dropdown } from '../Dropdown';
import { MaxAmountButton } from '../MaxAmountButton';
// Hooks
import { useDeposit } from '../../web3/hooks/useDeposit';
// Static
import { USDC_ADDRESS } from '../../constants';
// Icons
import { DepositIcon, USDCIcon } from '../../assets/icons';
// Styles
import s from './DepositForm.module.css';
const tokens = [
    {
        label: 'USDC',
        value: USDC_ADDRESS,
        icon: USDCIcon
    }
];
const tokensMappings = {
    [USDC_ADDRESS]: 'USDC'
};
const getButtonLabel = (status, message) => {
    if (status === 'approving') {
        return 'Approving...';
    }
    if (status === 'pending') {
        return 'Depositing...';
    }
    return message;
};
const formatFinalDeposit = (amount) => {
    const _amount = amount || 0;
    return _amount.toLocaleString(navigator.language, {
        minimumFractionDigits: 1,
        maximumFractionDigits: 2
    });
};
export const DepositForm = ({ hfiPrice = undefined }) => {
    const { isSubmitting, deposit, status } = useDeposit();
    const [selectedAddress, setSelectedAddress] = useState(USDC_ADDRESS);
    const [depositAmount, setDepositAmount] = useState('');
    const [hfiAmount, setHFIAmount] = useState('');
    const [error, setError] = useState(null);
    const finalDeposit = useMemo(() => {
        return formatFinalDeposit((+depositAmount * 85) / 100);
    }, [depositAmount]);
    /**
     * Calculates the receivable ammount of HFI based on the given USD amount.
     * @param usdAmount
     * @returns HFI Amount
     */
    const calculateHFIAmount = useCallback((usdAmount) => {
        if (!hfiPrice || !usdAmount)
            return 0;
        return Math.round((usdAmount * 85) / hfiPrice) / 100;
    }, [hfiPrice]);
    /**
     * Calculates the required ammount of USD based on the given HFI amount.
     * @param hfiAmount
     * @returns USD Amount
     */
    const calculateDepositAmount = useCallback((hfiAmount) => {
        if (!hfiPrice || !hfiAmount)
            return 0;
        return (hfiAmount * 100 * hfiPrice) / 85;
    }, [hfiPrice]);
    /**
     * Calculates the receivable ammount of HFI based on the given USD amount and updates the state.
     * @param usdAmount
     */
    const updateHFIAmount = (usdAmount) => {
        const hfiAmount = calculateHFIAmount(usdAmount);
        setHFIAmount(hfiAmount ? hfiAmount + '' : '');
    };
    /**
     * Calculates the required ammount of USD based on the given HFI amount and updates the state.
     * @param hfiAmount
     */
    const updateDepositAmount = (hfiAmount) => {
        const usdAmount = calculateDepositAmount(hfiAmount);
        setDepositAmount(usdAmount ? usdAmount + '' : '');
    };
    useEffect(() => {
        updateHFIAmount(+depositAmount);
    }, [hfiPrice]);
    /**
     * Handles a deposit amount change event.
     * Will caclulate receivable amount of HFI and update the state.
     * @param eventObject
     */
    const handleDepositAmountChange = ({ target }) => {
        if (isNaN(+target.value)) {
            return;
        }
        const regex = /\d+(\.\d{0,2})?/;
        const [deposit] = target.value.match(regex) || [''];
        setDepositAmount(deposit);
        updateHFIAmount(+deposit);
    };
    /**
     * Handles a HFT amount change event.
     * Will caclulate required amount of USD and update the state.
     * @param eventObject
     */
    const handleHFIAmountChange = ({ target }) => {
        if (isNaN(+target.value)) {
            return;
        }
        setHFIAmount(target.value);
        updateDepositAmount(+target.value);
    };
    const tokenLabel = tokensMappings[selectedAddress];
    const handleDeposit = (event) => __awaiter(void 0, void 0, void 0, function* () {
        event.preventDefault();
        if (isSubmitting || !depositAmount) {
            return;
        }
        setError(null);
        try {
            yield deposit(depositAmount, selectedAddress);
            setDepositAmount('');
            setHFIAmount('');
        }
        catch (error) {
            setError(error);
        }
    });
    const handleMaxButtonClick = (balance) => {
        if (balance.isZero()) {
            return;
        }
        const value = ethers.utils.formatUnits(balance, 'mwei');
        setDepositAmount(value);
        updateHFIAmount(+value);
    };
    return (React.createElement("div", { className: "multifarm-hybrid-dashboard__invest-page-form" },
        React.createElement("div", { className: "card_top-half-wrapper" },
            React.createElement("div", { className: "card-item_icon-wrapper" },
                React.createElement("div", { className: "icon_invest w-embed" },
                    React.createElement(DepositIcon, { width: 32, height: 32 })),
                React.createElement("div", { className: "text-block-5" }, "Deposit")),
            React.createElement("div", { className: "w-form" },
                React.createElement("form", { className: s.depositForm, onSubmit: handleDeposit },
                    React.createElement(Dropdown, { label: "Token", placeholder: "Select Token", items: tokens, selected: selectedAddress, onSelect: setSelectedAddress }),
                    React.createElement("div", { className: "form-field-wrapper last" },
                        React.createElement("label", { htmlFor: "name" }, "Deposit Amount"),
                        React.createElement("div", { className: "multifarm-hybrid-dashboard__input-wrapper" },
                            React.createElement("input", { type: "text", className: "form-input shadow-medium w-input", value: depositAmount, onChange: handleDepositAmountChange, maxLength: 256, name: "depositAmount", placeholder: `0 ${tokenLabel}`, id: "deopsit-amount" }),
                            React.createElement(MaxAmountButton, { className: "multifarm-hybrid-dashboard__input-btn", contractAddress: selectedAddress, onClick: handleMaxButtonClick }))),
                    React.createElement("div", { className: "form-field-wrapper last" },
                        React.createElement("label", { htmlFor: "name" }, "HFI Amount Receivable"),
                        React.createElement("input", { type: "text", className: "form-input shadow-medium w-input", value: hfiAmount, onChange: handleHFIAmountChange, maxLength: 256, name: "amountReceivable", placeholder: `0 HFI`, id: "amount-receivalbe" }))),
                React.createElement("div", { className: "w-form-done" },
                    React.createElement("div", null, "Thank you! Your submission has been received!")),
                error && (React.createElement("div", { className: s.formFailBlock },
                    React.createElement("div", null, "Oops! Something went wrong while submitting the form."))))),
        React.createElement("div", { className: "card_bottom-half-wrapper" },
            React.createElement("div", { className: "info-display_component" },
                React.createElement("div", { className: "text-align-left" }, "Tax"),
                React.createElement("div", { className: "text-align-right" }, "-15%"),
                React.createElement("div", { className: "text-align-left" }, "Final Desposit"),
                React.createElement("div", { className: "text-align-right" },
                    finalDeposit,
                    " ",
                    tokenLabel)),
            React.createElement("div", { className: "button-row is-button-row-center" },
                React.createElement("button", { type: "button", disabled: isSubmitting || !depositAmount, className: "button-secondary max-width-full shadow-medium w-button", onClick: handleDeposit }, getButtonLabel(status, `Deposit ${formatFinalDeposit(+depositAmount)} ${tokenLabel}`))))));
};
