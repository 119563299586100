// Models
import { TopItem } from './top-item.model';
var WalletStats = /** @class */ (function () {
    function WalletStats(data) {
        var _a, _b;
        this.totalHoldings = 0;
        this.totalHoldingsUsd = 0;
        this.topNfts = [];
        this.topAssets = [];
        this.totalHoldings = data.total_holdings_tokens || 0;
        this.totalHoldingsUsd = data.total_holdings_usd || 0;
        this.topNfts = ((_a = data.top_nfts) === null || _a === void 0 ? void 0 : _a.map(function (item) { return new TopItem(item); })) || [];
        this.topAssets = ((_b = data.top_assets) === null || _b === void 0 ? void 0 : _b.map(function (item) { return new TopItem(item); })) || [];
    }
    return WalletStats;
}());
export { WalletStats };
