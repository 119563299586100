import React from 'react';
import { LineChart, Card } from '@multifarm/widget-components';
// Utils
import { formatDate } from '../../../../../common/utils';
// Static
import { CAPSULE_ANALYTICS_CHARTS, NFT_TYPES } from '../../../../../common/static/chart.static';
var tooltipOptions = {
    formatCategory: function (value) { return formatDate(value); },
    formatTooltipKey: function (key) { var _a; return (_a = NFT_TYPES[key]) !== null && _a !== void 0 ? _a : key; }
};
export var OpenBidsChart = function (_a) {
    var keys = _a.keys, data = _a.data, loading = _a.loading;
    return (React.createElement(Card, { className: "multifarm-thor-finance-card borderless-grid", title: "NUMBER OF NFTS WITH OPEN BIDS" },
        React.createElement(LineChart, { data: data, dataKeys: keys, colors: CAPSULE_ANALYTICS_CHARTS.COLORS, chartOptions: CAPSULE_ANALYTICS_CHARTS.OPTIONS, isLoading: loading, tooltipOptions: tooltipOptions, xTickFormatter: function (tick) { return formatDate(tick); } })));
};
