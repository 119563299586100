// Static
import { RANGE_OPTION_NAMES } from '../static';
/**
 * Checks is value empty, null or undefined.
 * If value is array, checks if the length of the array is greater than zero.
 * @param value - Value to be checked.
 * @returns Is value empty boolean.
 */
export var isEmpty = function (value) {
    if (value === null || value === undefined) {
        return true;
    }
    // Validate the lenght of array.
    if (Array.isArray(value)) {
        return value.length === 0;
    }
    // Check if object has any key defined
    if (typeof value === 'object') {
        var keys = Object.keys(value);
        if (keys.length === 0)
            return true;
    }
    return false;
};
/**
 * @param array
 * @returns The reversed array.
 */
export var reverse = function (array) {
    return array.slice().reverse();
};
/**
 * Creates range options based on the pased range values.
 * @param ranges - Range values.
 * @returns Range options
 */
export var createRangeOptions = function (ranges) {
    return ranges.map(function (range) {
        var _a;
        return ({
            name: (_a = RANGE_OPTION_NAMES[range]) !== null && _a !== void 0 ? _a : range,
            value: range
        });
    });
};
/**
 * Omits the empty keys from the given object.
 * @param object
 * @returns Object with keys that have values.
 */
export var omitEmpty = function (object) {
    var filtered = {};
    Object.keys(object).forEach(function (key) {
        if (object[key]) {
            filtered[key] = object[key];
        }
    });
    return filtered;
};
/**
 * Checks that the given value is not empty
 * @param value - Any given value.
 * @returns Boolean indicating that the value is not empty.
 */
export var isNotEmpty = function (value) {
    return value !== null && value !== undefined;
};
