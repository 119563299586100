import React, { useState, useMemo } from 'react';
// Static
import { PageEnum, PageComponentMap, hybridPages } from './common/static/app.static';
// Icons
import { DiscordIcon, HybridFinanceIcon, TwitterIcon } from './assets/icons';
import { HybridHeader } from './components/HybridHeader';
export const App = () => {
    const [page, setPage] = useState(PageEnum.DASHBOARD);
    const Page = useMemo(() => PageComponentMap[page], [page]);
    return (React.createElement(React.Fragment, null,
        React.createElement(HybridHeader, { currentPage: page, onChange: setPage }),
        React.createElement("div", { className: "page-wrapper" },
            React.createElement("div", { className: "global-styles w-embed" }),
            React.createElement("main", { className: "main-wrapper" },
                React.createElement(Page, null))),
        React.createElement("div", { className: "footer_component" },
            React.createElement("div", { className: "page-padding" },
                React.createElement("div", { className: "container-large" },
                    React.createElement("div", { className: "padding-vertical padding-medium" },
                        React.createElement("div", { className: "padding-bottom" },
                            React.createElement("div", { className: "w-layout-grid footer_top-wrapper" },
                                React.createElement("button", { onClick: () => setPage(PageEnum.DASHBOARD), id: "w-node-_59392ccc-d963-1bc4-e136-75c0c8533620-c853361a", className: "footer_logo-link w-nav-brand" },
                                    React.createElement(HybridFinanceIcon, { width: 120, height: 38, className: "navbar1_logo" })),
                                React.createElement("div", { className: "w-layout-grid footer_link-list" }, hybridPages.map((item, index) => (React.createElement("button", { key: `footer-link${index}`, onClick: () => setPage(item), "aria-current": "page", className: `footer_link ${page === item && 'active-link'} ` }, item.toString())))),
                                React.createElement("div", { id: "w-node-_59392ccc-d963-1bc4-e136-75c0c8533629-c853361a", className: "w-layout-grid footer_social-list" },
                                    React.createElement("a", { href: "https://twitter.com/Hybrid_Finance", target: "_blank", rel: "noreferrer noopener", className: "footer_social-link w-inline-block" },
                                        React.createElement("div", { className: "social-icon-2 w-embed" },
                                            React.createElement(TwitterIcon, { width: 24, height: 24 }))),
                                    React.createElement("a", { href: "https://discord.com/invite/hybridfinance", target: "_blank", rel: "noreferrer noopener", className: "footer_social-link w-inline-block" },
                                        React.createElement("div", { className: "social-icon-2 w-embed" },
                                            React.createElement(DiscordIcon, { width: 24, height: 24 })))))),
                        React.createElement("div", { className: "divider wide" }),
                        React.createElement("div", { className: "padding-top padding-medium" },
                            React.createElement("div", { className: "w-layout-grid footer_bottom-wrapper" },
                                React.createElement("div", { id: "w-node-_59392ccc-d963-1bc4-e136-75c0c8533631-c853361a", className: "footer_credit-text" }, "\u00A9 2022 Hybrid. All right reserved."),
                                React.createElement("a", { href: "#", className: "footer4_legal-link" }, "Privacy Policy"),
                                React.createElement("a", { href: "#", className: "footer4_legal-link" }, "Terms of Service"),
                                React.createElement("a", { href: "#", className: "footer4_legal-link" }, "Cookies Settings")))))))));
};
