// Libs
import clsx from 'clsx';
import React from 'react';
// Components
import { WalletInfo } from '../wallet-info';
// Styles
import './sidebar.scss';
export var Sidebar = function (_a) {
    var walletAddresses = _a.walletAddresses, className = _a.className;
    return (React.createElement("aside", { className: clsx('multifarm-solana-realms__sidebar', className) },
        React.createElement("div", { className: "multifarm-solana-realms__sidebar-header" }, "DAO Wallets"),
        walletAddresses.map(function (walletAddress, index) { return (React.createElement(WalletInfo, { key: index, walletAddress: walletAddress, shouldBeOpened: index === 0 })); })));
};
