export const TOKEN = 'ZawByKd4WLczzLcLOBS2aEG3uNXkyitk'
export const REFI_TOKEN = 'H9iKCZExLCjALi6ywhEvpCUWXLXA75LZ'
export const EXPO_FARMING_TOKEN = 'WkUCfmsMEz9U8Zrv46X1AT9r7RU3-OWL'
export const EXPO_DEGEN_TOKEN = 'S0ZxplgIpcQZXt-i0gDOr5kjUgsBRbga'
export const EXPO_GAMEFI_TOKEN = 'eZz-tB7C02vis9l87Le8W7ZfKbFz9Yle'
export const FF_TOKEN = 'p_7QVt37v8Cu3KygGObW1Lc4c8rKyRyc'
export const DIGITS_TOKEN = 'l7uYGeu-TjWkLVqqz3NijHb1OgTl1Txc'
export const SPHERE_TOKEN = '7BE5OKIO-QdRkAINwLpHuZM52z_niqq3'
export const UMAMI_TOKEN = 'jhF4-ud_aCO17OABxHxKp9kD_5XV9rd8'
export const MAIA_TOKEN = 'J2EeF4dlHA0CRxYbHhbV3zWwB0Z_5bWE'
export const REDACTED_TOKEN = '_AMxby87mXP5nZtJgwL8qx6OIwT4Y19c'
export const PARAGON_TOKEN = 'vEERI3HXKoQeN187RIRl95gRbtQb8oIE'
export const ALCHEMIX_TOKEN = 'gSNt_IdvmyLhMJMTLIQeo9Fo_7hdpAnL'
export const ALCHEMIX_TREASURY_TOKEN = 'di50relXm_XAKKAUKvZ9Igd9pVzk2gm1'
export const ALCHEMIX_PRODUCTS_TOKEN = 'rBs3Kau4a_AQegm2LJQ2ldRBrvCoFfQb'
export const OSMOSIS_TOKEN = '6HAjgDHTDBtfPbTraIDWVKO_Km7vPmQo'
export const BALANCER_TOKEN = 'EL2ftxC5k1dzkzgrfI5PfPDjxkp1nQB9'
export const LAYER2DAO_TOKEN = 'zHvZwE65gFPk5n0Y1RgmcNBjdKiZCPLR'
export const METAVAULT_TOKEN = 'mTZ9gnlm7E_T1t8DpgZKrjWTx7SakkGB'
export const VIRAL_COIN_TOKEN = 'uFb2H3gsQnaEJWIIT38S5tzBwYKv4nLB'
export const BASTION_TOKEN = 'CNGeT8vX_NLsx64TTQsztwO1mSWP6z2l'
export const THOR_FINANCE_TOKEN = 'bBbWj2S8hK0v3Mur33ZFgOeJXE9EI1gP'
