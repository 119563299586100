var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
// Contexts
import { useWeb3Context } from '../contexts/web3.context';
// Utils
import { getUserLockInfo, getWalletAddress } from '../utils/helpers';
export const useUserLockInfo = (lockerAddress) => {
    const { provider } = useWeb3Context();
    const [userLockInfo, setUserLockInfo] = useState(null);
    const [isLoading, setLoading] = useState(false);
    useEffect(() => {
        const init = () => __awaiter(void 0, void 0, void 0, function* () {
            if (!provider) {
                return;
            }
            try {
                setLoading(true);
                const address = yield getWalletAddress(provider);
                const data = yield getUserLockInfo(provider, address, lockerAddress);
                setUserLockInfo(data);
                setLoading(false);
            }
            catch (error) {
                setLoading(false);
                setUserLockInfo(null);
            }
        });
        init();
    }, [provider, lockerAddress]);
    return {
        isLoading,
        userLockInfo
    };
};
