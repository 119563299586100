var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useState } from 'react';
import { utils } from 'ethers';
import { useWeb3Context } from '../contexts/web3.context';
import { approveTransaction, withdrawTokens } from '../utils/helpers';
import { INFINITY_POOL_ADDRESS, V2_ADDRESS } from '../../constants';
export const useWithdrawal = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [status, setStatus] = useState('not_running');
    const { provider } = useWeb3Context();
    const withdraw = useCallback((amount) => __awaiter(void 0, void 0, void 0, function* () {
        if (!provider) {
            return;
        }
        try {
            setIsSubmitting(true);
            const signer = yield provider.getSigner();
            const weiAmount = utils.parseEther(amount);
            setStatus('approving');
            yield approveTransaction(signer, weiAmount, INFINITY_POOL_ADDRESS, V2_ADDRESS);
            setStatus('pending');
            yield withdrawTokens(signer, weiAmount);
            setStatus('not_running');
            setIsSubmitting(false);
        }
        catch (error) {
            setStatus('not_running');
            setIsSubmitting(false);
            console.error(error);
            throw error;
        }
    }), [provider]);
    return {
        isSubmitting,
        withdraw,
        status
    };
};
