import useSwr from 'swr';
// API - Service
import { fetcher } from '../../services/api';
// Utils
import { stringifyURL } from '../../common/utils/api.utils';
export const useBlockchainDistribution = (params) => {
    const { data, error } = useSwr(() => stringifyURL('/auth/get_blockchain_distributions', params), fetcher);
    return {
        isLoading: !data && !error,
        data: data || {}
    };
};
