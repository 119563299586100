import { useState } from 'react';
import useSwr from 'swr';
// API - Service
import { fetcher } from '../../services/api';
// Utils
import { stringifyURL } from '../../common/utils/api.utils';
export const usePoolsByField = (params) => {
    const [page, setPage] = useState(1);
    const onPage = (newPage) => {
        setPage(newPage);
    };
    let { data, error } = useSwr(() => stringifyURL('/auth/get_pools_by_field', Object.assign({ page }, params)), fetcher);
    const isLoading = !data && !error;
    data = data || {};
    const pools = data.pools || [];
    return {
        isLoading,
        data: pools,
        onPage,
        maxPages: data.max_pages || 0
    };
};
