import React from 'react';
// Components
import { InfoCard } from '../../../../components/info-card/InfoCard';
// Utils
import { isNotEmpty } from '../../../../common/utils';
// Styles
import './statistics-cards.scss';
export var StatisticsCards = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    var analytics = _a.analytics;
    return (React.createElement("div", { className: "multifarm-thor-finance-statistics-cards" },
        React.createElement(InfoCard, { title: "NUMBER OF ORIGINS", body: isNotEmpty((_b = analytics.originNodes.total) === null || _b === void 0 ? void 0 : _b.totalSupply)
                ? "".concat((_c = analytics.originNodes.total) === null || _c === void 0 ? void 0 : _c.totalSupply)
                : 'N/A' }),
        React.createElement(InfoCard, { title: "NUMBER OF DRIFTS", body: isNotEmpty((_d = analytics.driftNodes.total) === null || _d === void 0 ? void 0 : _d.totalSupply)
                ? "".concat((_e = analytics.driftNodes.total) === null || _e === void 0 ? void 0 : _e.totalSupply)
                : 'N/A' }),
        React.createElement(InfoCard, { title: "NUMBER OF KEYCARDS", body: isNotEmpty((_f = analytics.keycardNodes.total) === null || _f === void 0 ? void 0 : _f.totalSupply)
                ? "".concat((_g = analytics.keycardNodes.total) === null || _g === void 0 ? void 0 : _g.totalSupply)
                : 'N/A' }),
        React.createElement(InfoCard, { title: "NUMBER OF CAPSULES", body: isNotEmpty((_h = analytics.capsuleNodes.total) === null || _h === void 0 ? void 0 : _h.totalSupply)
                ? "".concat((_j = analytics.capsuleNodes.total) === null || _j === void 0 ? void 0 : _j.totalSupply)
                : 'N/A' }),
        React.createElement(InfoCard, { title: "NUMBER OF PERKS", body: isNotEmpty((_k = analytics.perkNodes.total) === null || _k === void 0 ? void 0 : _k.totalSupply)
                ? "".concat((_l = analytics.perkNodes.total) === null || _l === void 0 ? void 0 : _l.totalSupply)
                : 'N/A' }),
        React.createElement(InfoCard, { title: "NUMBER OF PERKS APPLIED", body: isNotEmpty((_m = analytics.totalNodes.total) === null || _m === void 0 ? void 0 : _m.perksAppliedTotal)
                ? "".concat((_o = analytics.totalNodes.total) === null || _o === void 0 ? void 0 : _o.perksAppliedTotal)
                : 'N/A' })));
};
