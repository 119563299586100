import React, { useCallback, useState } from 'react';
import { Input } from '@multifarm/widget-components';
// Utils
import { formatNumber } from '../../../../../../../common/utils';
// Styles
import './deposit.scss';
const AVAILABLE_TOKENS_AMOUNT = 354128;
export const DepositTab = () => {
    const [depositAmount, setDepositAmount] = useState('');
    /**
     * Handles deposit amount change.
     * @param value - Deposit amount value.
     */
    const handleDepositChange = useCallback((value) => {
        const numeric = +value;
        if (isNaN(numeric)) {
            return;
        }
        if (numeric === 0) {
            setDepositAmount('0');
            return;
        }
        if (numeric > AVAILABLE_TOKENS_AMOUNT) {
            setDepositAmount(AVAILABLE_TOKENS_AMOUNT + '');
            return;
        }
        setDepositAmount(value);
    }, []);
    /**
     * Handles clear action.
     */
    const handleClearAction = useCallback(() => {
        setDepositAmount('');
    }, []);
    /**
     * Handles clear action.
     */
    const handleMaxAction = useCallback(() => {
        setDepositAmount(AVAILABLE_TOKENS_AMOUNT + '');
    }, []);
    return (React.createElement("div", { className: "numisme-vault-tab-deposit" },
        React.createElement("div", { className: "numisme-vault-tab-section numisme-vault-tab-section-highlight" },
            React.createElement("span", { className: "text-primary" },
                "Available USDC amount:",
                ' ',
                React.createElement("span", { className: "text-secondary text-bold" }, formatNumber(AVAILABLE_TOKENS_AMOUNT, 2, true))),
            React.createElement("div", { className: "deposit-container" },
                React.createElement(Input, { onChange: handleDepositChange, value: depositAmount, placeholder: "0", title: "Deposit:", className: "text-secondary text-bold" }),
                React.createElement("div", { className: "deposit-actions" },
                    React.createElement("span", { className: "clickable", onClick: handleMaxAction }, "MAX"),
                    React.createElement("span", { className: "clickable", onClick: handleClearAction }, "CLEAR")))),
        React.createElement("div", { className: "numisme-vault-tab-action-button clickable text-primary" }, "Deposit")));
};
