var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
// Context
import { useWeb3Context } from '../../context/web3.context';
// Assets
import { MetaMaskIcon } from '../../assets/icons';
// Utils
import { getNetworkIcon, getNetworkName, shortenAddress } from '../../common/utils';
// Styles
import './wallet-connect.scss';
export const WalletConnect = () => {
    const { connect, connected, provider, chain, disconnect } = useWeb3Context();
    const [connectionDetails, setConnectionDetails] = useState(null);
    const [disconnectOption, showDisconnectOption] = useState(false);
    /**
     * Hook for updating connection details.
     */
    useEffect(() => {
        /**
         * Retrieves connection details from the provider.
         */
        const getConnectionDetails = () => __awaiter(void 0, void 0, void 0, function* () {
            if (!provider || !connected)
                return;
            const [address, network] = yield Promise.all([
                provider.getSigner().getAddress(),
                provider.getNetwork()
            ]);
            setConnectionDetails({
                address,
                network
            });
        });
        getConnectionDetails();
    }, [provider, connected, chain]);
    /**
     * Memorising network icon and information.
     */
    const [NetworkIcon, networkInfo] = useMemo(() => {
        if (!connectionDetails)
            return [];
        return [
            getNetworkIcon(connectionDetails.network.chainId),
            `${getNetworkName(connectionDetails.network)}  ${shortenAddress(connectionDetails.address)}`
        ];
    }, [connectionDetails]);
    return (React.createElement("div", { className: "wallet-connect", onClick: () => {
            if (connected) {
                showDisconnectOption(!disconnectOption);
                return;
            }
            connect();
        } }, !connected || !connectionDetails ? (React.createElement("div", { className: "wallet-connect-option" },
        React.createElement(MetaMaskIcon, null),
        "Connect your wallet")) : (React.createElement(React.Fragment, null,
        React.createElement(NetworkIcon, null),
        networkInfo,
        React.createElement("div", { className: clsx('wallet-disconnect-option', {
                active: disconnectOption
            }), onClick: () => disconnect() }, "Disconnect")))));
};
