import React from 'react';
import cx from 'clsx';
import { Card as CardComponent } from '@multifarm/widget-components';
import s from './card.module.css';
export var Card = function (_a) {
    var title = _a.title, titleComponent = _a.titleComponent, className = _a.className, children = _a.children, subtitle = _a.subtitle, headerComponent = _a.headerComponent, footerComponent = _a.footerComponent, footerClassName = _a.footerClassName;
    return (React.createElement(CardComponent, { title: title, className: cx(s.card, className), subtitle: subtitle, titleComponent: titleComponent, headerComponent: headerComponent },
        children,
        footerComponent && (React.createElement("div", { className: cx(s.cardFooter, footerClassName) }, footerComponent))));
};
Card.defaultProps = {
    className: ''
};
