export const DEFAULT_DATE_FORMAT = 'MMM dd';
export const DATE_FORMAT_SECONDARY = 'dd.MM.YYYY';
export const API_DATE_FORMAT_PRIMARY = 'yyyy.MM.dd';
export const API_DATE_FORMAT_SECONDARY = 'yyyy-MM-dd';
export const CHART_TOOLTIP_DATE_PRIMARY = 'MMMM dd, yyyy';
export var RangeTypeEnum;
(function (RangeTypeEnum) {
    RangeTypeEnum["7D"] = "7d";
    RangeTypeEnum["14D"] = "14d";
    RangeTypeEnum["30D"] = "30d";
    RangeTypeEnum["90D"] = "90d";
    RangeTypeEnum["180D"] = "180d";
    RangeTypeEnum["1Y"] = "1y";
    RangeTypeEnum["MAX"] = "max";
})(RangeTypeEnum || (RangeTypeEnum = {}));
export const RANGE_OPTION_NAMES = {
    [RangeTypeEnum['7D']]: '7d',
    [RangeTypeEnum['14D']]: '14d',
    [RangeTypeEnum['30D']]: '30d',
    [RangeTypeEnum['90D']]: '90d',
    [RangeTypeEnum['180D']]: '180d',
    [RangeTypeEnum['1Y']]: '1y',
    [RangeTypeEnum.MAX]: 'Max'
};
export const RANGES = [
    { name: RANGE_OPTION_NAMES[RangeTypeEnum['7D']], value: RangeTypeEnum['7D'] },
    {
        name: RANGE_OPTION_NAMES[RangeTypeEnum['14D']],
        value: RangeTypeEnum['14D']
    },
    {
        name: RANGE_OPTION_NAMES[RangeTypeEnum['30D']],
        value: RangeTypeEnum['30D']
    },
    {
        name: RANGE_OPTION_NAMES[RangeTypeEnum['90D']],
        value: RangeTypeEnum['90D']
    },
    {
        name: RANGE_OPTION_NAMES[RangeTypeEnum['180D']],
        value: RangeTypeEnum['180D']
    },
    { name: RANGE_OPTION_NAMES[RangeTypeEnum['1Y']], value: RangeTypeEnum['1Y'] },
    { name: RANGE_OPTION_NAMES[RangeTypeEnum['MAX']], value: RangeTypeEnum.MAX }
];
export var CurrencyEnum;
(function (CurrencyEnum) {
    CurrencyEnum["USD"] = "USD";
    CurrencyEnum["ETH"] = "ETH";
})(CurrencyEnum || (CurrencyEnum = {}));
export const CURRENCIES = [
    { name: 'USD', value: CurrencyEnum.USD },
    { name: 'ETH', value: CurrencyEnum.ETH }
];
