import React from 'react';
// Components
import { PieChartTable } from '../../components/charts/pie-chart-table/PieChartTable';
// Hooks - API
import { useWalletStats } from '../../common/hooks/api/useWalletStats';
// Contexts
import { useSolanaRealmsContext } from '../../context/solana-realms.context';
// Static
import { DASHBOARD_CHARTS } from '../../common/static';
// Styles
import './dashboard.scss';
export var Dashboard = function () {
    var walletAddresses = useSolanaRealmsContext().walletAddresses;
    var data = useWalletStats(walletAddresses).data;
    console.log(data);
    return (React.createElement("div", { className: "multifarm-dashboard-page" },
        React.createElement(PieChartTable, { title: "Token Breakdown", hint: "Token Breakdown", data: (data === null || data === void 0 ? void 0 : data.topAssets) || [], chartDataKey: "distribution", colors: DASHBOARD_CHARTS.COLORS, tooltipOptions: DASHBOARD_CHARTS.TOKEN_BREAKDOWN.TOOLTIP_OPTIONS, noDataPlaceholder: DASHBOARD_CHARTS.TOKEN_BREAKDOWN.NO_PLACEHOLDER_DATA, tableColumns: DASHBOARD_CHARTS.TOKEN_BREAKDOWN.TABLE_COLUMNS }),
        React.createElement(PieChartTable, { title: "NFT Breakdown", hint: "NFT Breakdown", data: (data === null || data === void 0 ? void 0 : data.topNfts) || [], chartDataKey: "holdingsUsd", colors: DASHBOARD_CHARTS.COLORS, tooltipOptions: DASHBOARD_CHARTS.NFT_BREAKDOWN.TOOLTIP_OPTIONS, noDataPlaceholder: DASHBOARD_CHARTS.NFT_BREAKDOWN.NO_PLACEHOLDER_DATA, tableColumns: DASHBOARD_CHARTS.NFT_BREAKDOWN.TABLE_COLUMNS })));
};
