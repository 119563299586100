var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useMemo, useState } from 'react';
import { ethers } from 'ethers';
// Components
import { PeriodTabs } from '../PeriodTabs';
import { MaxAmountButton } from '../../../MaxAmountButton';
// Hooks
import { useTokenLock } from '../../../../web3/hooks/useTokenLock';
import { useNextTime } from '../../../../web3/hooks/useNextTime';
import { useLockerRatio } from '../../../../web3/hooks/useLockerRatio';
// Contexts
import { useWeb3Context } from '../../../../web3/contexts/web3.context';
// Static
import { LOCKER_3_ADDRESS, V2_ADDRESS } from '../../../../constants';
const formatButtonText = (lockAmount) => {
    if (!lockAmount) {
        return '0.0';
    }
    return Number(lockAmount).toLocaleString(navigator.language);
};
export const LockForm = ({ periods }) => {
    const { lock } = useTokenLock();
    const { config } = useWeb3Context();
    const [selectedPeriod, setPeriod] = useState(LOCKER_3_ADDRESS);
    const [lockAmount, setLockAmount] = useState('');
    // Contract calls
    const { nextTime, isLoading: isNextTimeLoading } = useNextTime(selectedPeriod);
    const { maxRatio } = useLockerRatio(selectedPeriod);
    const lockedPeriod = useMemo(() => {
        const _selected = periods.find(({ value }) => value === selectedPeriod);
        if (!_selected) {
            return periods[0];
        }
        return _selected;
    }, [selectedPeriod, periods]);
    const veHFIAmount = useMemo(() => {
        const amount = Number(lockAmount);
        const veHFI = amount * maxRatio;
        return veHFI.toLocaleString(navigator.language, {
            maximumFractionDigits: 3,
            minimumFractionDigits: 1
        });
    }, [lockAmount, maxRatio]);
    const handleChange = ({ target }) => {
        if (isNaN(+target.value)) {
            return;
        }
        setLockAmount(target.value);
    };
    const handleLock = (event) => __awaiter(void 0, void 0, void 0, function* () {
        event.preventDefault();
        if (!lockAmount) {
            return;
        }
        try {
            yield lock(selectedPeriod, lockAmount);
        }
        catch (error) {
            console.log(error);
        }
    });
    const handleMaxButtonClick = (balance) => {
        if (balance.isZero()) {
            return;
        }
        setLockAmount(ethers.utils.formatEther(balance));
    };
    return (React.createElement("div", { "data-w-tab": "Lock", className: "tab-pane-lock w-tab-pane w--tab-active" },
        React.createElement("div", { className: "div-block-4" },
            React.createElement("div", { className: "card_top-half-wrapper" },
                React.createElement(PeriodTabs, { label: "Select Lock Period", items: periods, selectedPeriod: selectedPeriod, onSelect: setPeriod }),
                React.createElement("div", { className: "w-form" },
                    config.locker.unlocked === selectedPeriod ? (React.createElement("form", { id: "wf-form-Lock-Amount", name: "wf-form-Lock-Amount", "data-name": "Lock Amount", method: "get", className: "form-wrapper", onSubmit: handleLock },
                        React.createElement("div", { className: "form-field-wrapper last" },
                            React.createElement("label", { htmlFor: "Lock-Amount" }, "Lock Amount"),
                            React.createElement("div", { className: "multifarm-hybrid-dashboard__input-wrapper" },
                                React.createElement("input", { type: "text", className: "form-input shadow-medium w-input", maxLength: 256, name: "Lock-Amount", placeholder: "0 HFI", value: lockAmount, onChange: handleChange }),
                                React.createElement(MaxAmountButton, { className: "multifarm-hybrid-dashboard__input-btn", contractAddress: V2_ADDRESS, onClick: handleMaxButtonClick }))))) : (React.createElement("div", { className: "div-block-2" },
                        React.createElement("div", { className: "text-block-3" }, config.locker.message))),
                    React.createElement("div", { className: "w-form-done" },
                        React.createElement("div", null, "Thank you! Your submission has been received!")),
                    React.createElement("div", { className: "w-form-fail" },
                        React.createElement("div", null, "Oops! Something went wrong while submitting the form.")))),
            React.createElement("div", { className: "card_bottom-half-wrapper" },
                React.createElement("div", { className: "info-display_component" },
                    React.createElement("div", { className: "text-align-left" }, "Locking Period"),
                    React.createElement("div", { className: "text-align-right" }, `${lockedPeriod.period} ${lockedPeriod.periodLabel}`),
                    React.createElement("div", { className: "text-align-left" }, "Potential HFI Rewards"),
                    React.createElement("div", { className: "text-align-right" },
                        veHFIAmount,
                        " HFI")),
                React.createElement("div", { className: "button-row is-button-row-center" },
                    React.createElement("button", { type: "button", className: "button-secondary max-width-full shadow-medium w-button", onClick: handleLock, disabled: isNextTimeLoading ||
                            (nextTime === null || nextTime === void 0 ? void 0 : nextTime.isLocked) ||
                            config.locker.unlocked !== selectedPeriod },
                        "Lock ",
                        formatButtonText(lockAmount),
                        " HFI"))))));
};
