export var NodeName;
(function (NodeName) {
    NodeName["ORIGIN"] = "ORIGIN";
    NodeName["DRIFT"] = "DRIFT";
    NodeName["KEYCARD"] = "KEY";
    NodeName["CAPSULE"] = "CAPSULE";
    NodeName["PERK"] = "PERK";
    NodeName["TOTAL"] = "TOTAL";
    NodeName["GAMMA"] = "GAMMA";
    NodeName["SIGMA"] = "SIGMA";
    NodeName["DELTA"] = "DELTA";
    NodeName["VOUCHER"] = "VOUCHER";
    NodeName["THOR_ORIGIN"] = "THOR_ORIGIN";
    NodeName["THOR_DRIFT"] = "THOR_DRIFT";
    NodeName["THOR_KEYCARD"] = "THOR_KEY";
    NodeName["THOR_CAPSULE"] = "THOR_CAPSULE";
    NodeName["THOR_PERK"] = "THOR_PERK";
    NodeName["ODIN_ORIGIN"] = "ODIN_ORIGIN";
    NodeName["ODIN_DRIFT"] = "ODIN_DRIFT";
    NodeName["ODIN_KEYCARD"] = "ODIN_KEY";
    NodeName["ODIN_CAPSULE"] = "ODIN_CAPSULE";
    NodeName["ODIN_PERK"] = "ODIN_PERK";
    NodeName["UNKNOWN"] = "UNKNOWN";
})(NodeName || (NodeName = {}));
export var ACCEPTABLE_NODE_NAMES = [
    NodeName.ORIGIN,
    NodeName.DRIFT,
    NodeName.KEYCARD,
    NodeName.CAPSULE,
    NodeName.PERK,
    NodeName.TOTAL,
    NodeName.GAMMA,
    NodeName.SIGMA,
    NodeName.DELTA,
    NodeName.VOUCHER,
    NodeName.THOR_ORIGIN,
    NodeName.THOR_DRIFT,
    NodeName.THOR_KEYCARD,
    NodeName.THOR_CAPSULE,
    NodeName.THOR_PERK,
    NodeName.ODIN_ORIGIN,
    NodeName.ODIN_DRIFT,
    NodeName.ODIN_KEYCARD,
    NodeName.ODIN_CAPSULE,
    NodeName.ODIN_PERK
];
export var NODE_NAME_ANALYTICS_MAP = new Map([
    [NodeName.ORIGIN, { base: 'originNodes', category: 'total' }],
    [NodeName.DRIFT, { base: 'driftNodes', category: 'total' }],
    [NodeName.KEYCARD, { base: 'keycardNodes', category: 'total' }],
    [NodeName.CAPSULE, { base: 'capsuleNodes', category: 'total' }],
    [NodeName.PERK, { base: 'perkNodes', category: 'total' }],
    [NodeName.TOTAL, { base: 'totalNodes', category: 'total' }],
    [NodeName.GAMMA, { base: 'perkNodes', category: 'gamma' }],
    [NodeName.SIGMA, { base: 'perkNodes', category: 'sigma' }],
    [NodeName.DELTA, { base: 'perkNodes', category: 'delta' }],
    [NodeName.VOUCHER, { base: 'perkNodes', category: 'voucher' }],
    [NodeName.THOR_ORIGIN, { base: 'originNodes', category: 'thor' }],
    [NodeName.THOR_DRIFT, { base: 'driftNodes', category: 'thor' }],
    [NodeName.THOR_KEYCARD, { base: 'keycardNodes', category: 'thor' }],
    [NodeName.THOR_CAPSULE, { base: 'capsuleNodes', category: 'thor' }],
    [NodeName.THOR_PERK, { base: 'perkNodes', category: 'thor' }],
    [NodeName.ODIN_ORIGIN, { base: 'originNodes', category: 'odin' }],
    [NodeName.ODIN_DRIFT, { base: 'driftNodes', category: 'odin' }],
    [NodeName.ODIN_KEYCARD, { base: 'keycardNodes', category: 'odin' }],
    [NodeName.ODIN_CAPSULE, { base: 'capsuleNodes', category: 'odin' }],
    [NodeName.ODIN_PERK, { base: 'perkNodes', category: 'odin' }],
    [NodeName.UNKNOWN, null]
]);
