// Libs
import React, { useMemo } from 'react';
import { LoadingPlaceholder, NoDataPlaceholder } from '@multifarm/widget-components';
import cx from 'clsx';
// Components
import { Card } from '../Card';
// Utils
import { getRalativeFormat } from '../../utils/time';
// Styles
import s from './StatTableCard.module.css';
export var StatTableCard = function (_a) {
    var stats = _a.stats, updatedAt = _a.updatedAt, isLoading = _a.isLoading;
    var dateDiff = useMemo(function () {
        if (!updatedAt) {
            return null;
        }
        return getRalativeFormat(new Date(), updatedAt);
    }, [updatedAt]);
    var renderContent = function () {
        if (isLoading) {
            return React.createElement(LoadingPlaceholder, { minHeight: 180 });
        }
        if (!stats.length) {
            return React.createElement(NoDataPlaceholder, { minHeight: 180 });
        }
        return stats.map(function (_a) {
            var title = _a.title, value = _a.value, className = _a.className;
            return (React.createElement("div", { key: title, className: cx(s.statBlock, className) },
                React.createElement("span", null, title),
                React.createElement("span", null, value)));
        });
    };
    return (React.createElement(Card, { className: s.card, footerComponent: updatedAt && "Last updated ".concat(dateDiff) }, renderContent()));
};
