// Libs
import useSWR from 'swr';
// Services
import { fetcher } from '../../services/api';
const getKey = (address) => {
    if (!address) {
        return null;
    }
    return `/auth/my_investment?holder_address=${address}`;
};
export const useCustomerInvestment = (address) => {
    const { data: responseData, error } = useSWR(getKey(address), fetcher);
    const { data } = responseData || {};
    const [investmentData] = data || [];
    return {
        isLoading: !responseData && !error,
        data: investmentData
    };
};
