var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useContext, useMemo, useCallback, useEffect } from 'react';
import { providers } from 'ethers';
import Web3Modal from '@multifarm/web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
// Hooks
import { getCustomRPC } from '../common/utils';
// Utils
import { isEmpty } from '../common/utils';
const Web3Context = React.createContext(null);
export const useWeb3Context = () => {
    const web3Context = useContext(Web3Context);
    if (!web3Context) {
        throw new Error('useWeb3Context() can only be used inside of <Web3ContextProvider />, ' +
            'please declare it at a higher level.');
    }
    return web3Context;
};
const web3Modal = new Web3Modal({
    cacheProvider: true,
    providerOptions: {
        walletconnect: {
            package: WalletConnectProvider,
            options: {
                rpc: getCustomRPC(),
                infuraId: process.env.INFURA_KEY
            }
        }
    }
});
export const Web3ContextProvider = ({ children }) => {
    const [connected, setConnected] = useState(false);
    const [provider, setProvider] = useState(null);
    const [chain, setChain] = useState(null);
    const initListeners = useCallback((rawProvider) => {
        if (!rawProvider.on) {
            return;
        }
        rawProvider.on('accountsChanged', (...data) => {
            const isConnected = !isEmpty(data) && !isEmpty(data[0]);
            setConnected(isConnected);
        });
        rawProvider.on('chainChanged', (chain) => {
            setChain(chain);
        });
        rawProvider.on('network', (_newNetwork, oldNetwork) => {
            console.log('network', _newNetwork, oldNetwork);
        });
    }, [provider, setConnected]);
    const connect = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const rawProvider = yield web3Modal.connect();
        initListeners(rawProvider);
        const connectedProvider = new providers.Web3Provider(rawProvider, 'any');
        setProvider(connectedProvider);
        setConnected(true);
        return rawProvider;
    }), [web3Modal, setConnected, setProvider]);
    const disconnect = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!web3Modal) {
            return;
        }
        web3Modal.clearCachedProvider();
        if (localStorage.getItem('walletconnect')) {
            localStorage.removeItem('walletconnect');
        }
        setConnected(false);
    }), []);
    useEffect(() => {
        if (web3Modal.cachedProvider) {
            connect();
        }
    }, [web3Modal]);
    const context = useMemo(() => ({
        connect,
        disconnect,
        provider,
        connected,
        chain
    }), [connect, disconnect, provider, connected, chain]);
    return (React.createElement(Web3Context.Provider, { value: context }, children));
};
