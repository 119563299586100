import React from 'react';
// Components
import { InfoCards, AssetBreakdownChart, LoanBreakdownChart, RecentLoanRepaymentsChart, VaultApyChart } from './components';
export const GeneralOverview = () => {
    return (React.createElement("div", { className: "general-overview-page" },
        React.createElement(InfoCards, null),
        React.createElement(AssetBreakdownChart, null),
        React.createElement(LoanBreakdownChart, null),
        React.createElement(RecentLoanRepaymentsChart, null),
        React.createElement(VaultApyChart, null)));
};
