import React, { useMemo, useState } from 'react';
import cx from 'clsx';
import s from './Dropdown.module.css';
export const Dropdown = ({ className, items, selected, onSelect, placeholder = 'Select value', label }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedLabel] = useMemo(() => {
        const _selected = items.find(({ value }) => value === selected);
        if (!_selected) {
            return [placeholder, null];
        }
        return [_selected.label, _selected.icon];
    }, [items, selected, placeholder]);
    const handleSelect = (value) => {
        onSelect(value);
        setIsOpen(false);
    };
    return (React.createElement("div", { className: cx(s.dropdownWrapper, className) },
        label && React.createElement("label", { className: s.dropdownLabel }, label),
        React.createElement("div", { className: "dropdown_component shadow-medium w-dropdown" },
            React.createElement("button", { type: "button", className: cx('dropdown_toggle w-dropdown-toggle', s.dropdownButton), onClick: () => setIsOpen((prev) => !prev) },
                React.createElement("div", { className: cx('dropdown_dropdown-icon w-icon-dropdown-toggle', s.dropdownButtonIcon, {
                        [s.dropdownButtonIconActive]: isOpen
                    }) }),
                React.createElement("div", { className: "text-block-4" }, selectedLabel)),
            isOpen && (React.createElement("nav", { className: s.dropdownList }, items.map(({ value, icon: Icon, label }) => (React.createElement("button", { key: value, type: "button", className: cx('token-wrapper', s.dropdownItem, {
                    [s.dropdownItemActive]: selected === value
                }), onClick: () => handleSelect(value) },
                Icon && (React.createElement(Icon, { width: 32, height: 32, className: "token-image" })),
                React.createElement("div", { className: "dropdown2_dropdown-link w-dropdown-link" }, label)))))))));
};
