// Libs
import React from 'react';
import { Table, TableCell, TableRow } from '@multifarm/widget-components';
import { Card } from '../../../components/Card';
// Styles
import s from '../overview.module.css';
var TABLE_DATA = [
    {
        asset: 'ETH',
        collateral_total: '1.5M',
        debt_total: '0.5M',
        colateral_top_10: '20%',
        debt_top_10: '30%'
    },
    {
        asset: 'WBTC',
        collateral_total: '2M',
        debt_total: '0.1M',
        colateral_top_10: '22%',
        debt_top_10: '10%'
    },
    {
        asset: 'USDC',
        collateral_total: '1.7M',
        debt_total: '1M',
        colateral_top_10: '40%',
        debt_top_10: '27%'
    },
    {
        asset: 'USDT',
        collateral_total: '2.3M',
        debt_total: '1.5M',
        colateral_top_10: '60%',
        debt_top_10: '34%'
    }
];
var TABLE_COLUMNS = [
    {
        name: 'Assets',
        key: 'asset',
        align: 'left',
        width: '20%'
    },
    {
        name: 'Total Collateral',
        key: 'collateral_total',
        align: 'right',
        width: '15%'
    },
    {
        name: 'Total Debt',
        key: 'debt_total',
        align: 'right',
        width: '15%'
    },
    {
        name: 'Top 10 Account Collateral %',
        key: 'colateral_top_10',
        align: 'right',
        width: '25%'
    },
    {
        name: 'Top 10 Account Debt %',
        key: 'debt_top_10',
        align: 'right',
        width: '25%'
    }
];
export var AssetAllocationOverview = function () {
    return (React.createElement(Card, { className: s.tableContainer },
        React.createElement(Table, { className: s.table, columns: TABLE_COLUMNS, isEmpty: false, isLoading: false }, TABLE_DATA.map(function (row, index) { return (React.createElement(TableRow, { key: index }, TABLE_COLUMNS.map(function (col) { return (React.createElement(TableCell, { key: col.key, width: col.width, align: col.align }, col.format ? col.format(row[col.key]) : row[col.key])); }))); }))));
};
