import { Dashboard, MultifarmProvider } from '@multifarm/widget'
import Content from '../components/Content'
import InvalidContent from '../content/invalid.md'

export default function Invalid() {
  return (
    <div className="flex flex-col w-full">
      <Content content={InvalidContent} />

      <MultifarmProvider token="">
        <Dashboard />
      </MultifarmProvider>
    </div>
  )
}
