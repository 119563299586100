var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useMemo, useState } from 'react';
import { ethers } from 'ethers';
// Components
import { MaxAmountButton } from '../MaxAmountButton';
// Icons
import { WithdrawalIcon } from '../../assets/icons';
// Hooks
import { useHFIBalance } from '../../web3/hooks/useHFIBalance';
import { getTokensPriceUSD } from '../../web3/utils/helpers';
import { useWithdrawal } from '../../web3/hooks/useWithdrawal';
// Utils
import { formatNumberString } from '../../common/utils';
// Static
import { V2_ADDRESS } from '../../constants';
// Styles
import s from './WithdrawalForm.module.css';
const validateWithdrawal = (amount, balance) => {
    try {
        if (!amount) {
            return { error: 'Withdrawal amount is required and cannot be 0' };
        }
        const _amount = ethers.utils.parseEther(amount);
        if (balance.lt(_amount)) {
            return {
                error: 'Insufficient balance. Please, check the withdrawal amount.'
            };
        }
    }
    catch (e) {
        return {
            error: e.reason
        };
    }
    return null;
};
const getButtonLabel = (status, message) => {
    if (status === 'approving') {
        return 'Approving...';
    }
    if (status === 'pending') {
        return 'Selling...';
    }
    return message;
};
export const WithdrawalForm = () => {
    const { isSubmitting, withdraw, status } = useWithdrawal();
    const balanceHFI = useHFIBalance();
    const [withdrawalAmount, setWithdrawalAmount] = useState('');
    const [errors, setErrors] = useState(null);
    const balance = useMemo(() => {
        return formatNumberString(ethers.utils.formatEther(balanceHFI.balance), 5);
    }, [balanceHFI.balance]);
    const usdAmount = useMemo(() => {
        const amount = ethers.utils.formatUnits(balanceHFI.usdAmount, 'mwei');
        return formatNumberString(amount);
    }, [balanceHFI.balance, balanceHFI.usdAmount]);
    const withdrawalUSD = useMemo(() => {
        if (!withdrawalAmount) {
            return '0.0';
        }
        try {
            const _amount = ethers.utils.parseEther(withdrawalAmount);
            const usdAmount = getTokensPriceUSD(_amount, balanceHFI.price);
            return formatNumberString(ethers.utils.formatUnits(usdAmount, 'mwei'));
        }
        catch (error) {
            setErrors(error.reason);
        }
    }, [withdrawalAmount, balanceHFI.price]);
    const handleChange = ({ target }) => {
        if (isNaN(+target.value)) {
            return;
        }
        setWithdrawalAmount(target.value);
    };
    const handleMaxButtonClick = (balance) => {
        if (balance.isZero()) {
            return;
        }
        setWithdrawalAmount(ethers.utils.formatEther(balance));
    };
    const handleWithdrawal = (event) => __awaiter(void 0, void 0, void 0, function* () {
        event.preventDefault();
        setErrors(null);
        try {
            const submitErrors = validateWithdrawal(withdrawalAmount, balanceHFI.balance);
            if (submitErrors || !withdrawalAmount) {
                setErrors(submitErrors === null || submitErrors === void 0 ? void 0 : submitErrors.error);
                return;
            }
            yield withdraw(withdrawalAmount);
            setWithdrawalAmount('');
        }
        catch (error) {
            setErrors('Oops! Something went wrong while submitting the form.');
        }
    });
    return (React.createElement("div", { className: "multifarm-hybrid-dashboard__invest-page-form" },
        React.createElement("div", { className: "card_top-half-wrapper" },
            React.createElement("div", { className: "card-item_icon-wrapper" },
                React.createElement("div", { className: "icon_invest w-embed" },
                    React.createElement(WithdrawalIcon, { width: 32, height: 32 })),
                React.createElement("div", { className: "text-block-5" }, "Withdraw")),
            React.createElement("div", { className: "tokens-held_component" },
                React.createElement("div", { className: "field-label" }, "Tokens Held"),
                React.createElement("div", { className: "div-block-2" },
                    React.createElement("div", { className: "text-block-2" },
                        balance,
                        React.createElement("sup", null, " HFI")),
                    React.createElement("div", { className: "text-block-3" },
                        "$",
                        usdAmount,
                        React.createElement("sup", null, " USD")))),
            React.createElement("div", { className: "w-form" },
                React.createElement("form", { id: "wf-form-Token-Select", name: "wf-form-Token-Select", "data-name": "Token Select", method: "get", className: "form-wrapper", onSubmit: handleWithdrawal },
                    React.createElement("div", { className: "form-field-wrapper last" },
                        React.createElement("label", { htmlFor: "name-2" }, "Withdrawal Amount"),
                        React.createElement("div", { className: "multifarm-hybrid-dashboard__input-wrapper" },
                            React.createElement("input", { type: "text", className: "form-input shadow-medium w-input", maxLength: 256, name: "withdrawalAmount", placeholder: "0 HFI", value: withdrawalAmount, onChange: handleChange }),
                            React.createElement(MaxAmountButton, { className: "multifarm-hybrid-dashboard__input-btn", contractAddress: V2_ADDRESS, onClick: handleMaxButtonClick })))),
                React.createElement("div", { className: "w-form-done" },
                    React.createElement("div", null, "Thank you! Your submission has been received!")),
                errors && (React.createElement("div", { className: s.formFailBlock },
                    React.createElement("div", null, errors))))),
        React.createElement("div", { className: "card_bottom-half-wrapper" },
            React.createElement("div", { className: "info-display_component" },
                React.createElement("div", { className: "text-align-left" }, "Withdrawal"),
                React.createElement("div", { className: "text-align-right" },
                    withdrawalAmount || '0.0',
                    " HFI"),
                React.createElement("div", { className: "text-align-left" }, "Value"),
                React.createElement("div", { className: "text-align-right" },
                    withdrawalUSD,
                    " USDC")),
            React.createElement("div", { className: "button-row is-button-row-center" },
                React.createElement("button", { disabled: isSubmitting || !withdrawalAmount, type: "button", className: "button-secondary max-width-full shadow-medium w-button", onClick: handleWithdrawal }, getButtonLabel(status, `Withdraw ${withdrawalUSD} USDC`))))));
};
