import React, { useMemo, useState } from 'react';
import { BigNumber, ethers } from 'ethers';
// Components
import { PeriodTabs } from '../PeriodTabs';
import { LockedBanner } from '../LockedBanner';
// Hooks
import { useTokenUnlock } from '../../../../web3/hooks/useTokenUnlock';
import { useUserLockInfo } from '../../../../web3/hooks/useUserLockInfo';
import { useNextUnlockTime } from '../../../../web3/hooks/useNextUnlockTime';
import { useNextTime } from '../../../../web3/hooks/useNextTime';
import { useLockerRatio } from '../../../../web3/hooks/useLockerRatio';
// Utils
import { formatNumberString } from '../../../../common/utils';
// Staic
import { LOCKER_3_ADDRESS } from '../../../../constants';
export const UnlockForm = ({ periods }) => {
    // Local states
    const { unlock } = useTokenUnlock();
    const [selectedPeriod, setSelectedPeriod] = useState(LOCKER_3_ADDRESS);
    // Contract calls
    const { isLoading, userLockInfo } = useUserLockInfo(selectedPeriod);
    const { nextTime, isLoading: isNextTimeLoading } = useNextTime(selectedPeriod);
    const { nextUnlockTime } = useNextUnlockTime({ userLockInfo, nextTime });
    const { currentRatio } = useLockerRatio(selectedPeriod);
    const lockedPeriod = useMemo(() => {
        const _selected = periods.find(({ value }) => value === selectedPeriod);
        if (!_selected) {
            return periods[0];
        }
        return _selected;
    }, [selectedPeriod, periods]);
    const lockedAmount = useMemo(() => {
        if (!userLockInfo) {
            return '0.0';
        }
        const { amount } = userLockInfo;
        const parsedAmount = ethers.utils.formatEther(amount);
        return formatNumberString(parsedAmount, 5);
    }, [userLockInfo === null || userLockInfo === void 0 ? void 0 : userLockInfo.amount]);
    const potentialReward = useMemo(() => {
        if (!userLockInfo) {
            return '0.0';
        }
        const { amount } = userLockInfo;
        const ratio = ethers.utils.parseEther(currentRatio.toString());
        const parsedAmount = amount.mul(ratio).div(BigNumber.from(10).pow(18));
        return formatNumberString(ethers.utils.formatEther(parsedAmount), 5);
    }, [userLockInfo === null || userLockInfo === void 0 ? void 0 : userLockInfo.amount, currentRatio]);
    return (React.createElement("div", { "data-w-tab": "Unlock", className: "tab-pane-unlock w-tab-pane" },
        React.createElement("div", { className: "div-block-4" },
            React.createElement("div", { className: "card_top-half-wrapper" },
                React.createElement(PeriodTabs, { label: "Select Unlock Period", items: periods, selectedPeriod: selectedPeriod, onSelect: setSelectedPeriod, hideMultiplier: true }),
                React.createElement("div", { className: "w-form" },
                    React.createElement("div", { className: "tokens-held_component" },
                        React.createElement("div", { className: "field-label" }, "Unlockable veHFI"),
                        Number.parseFloat(lockedAmount) > 0 ? (React.createElement(LockedBanner, { className: "div-block-2", unlockedClassName: "div-block-2", loading: isLoading || isNextTimeLoading, locked: nextTime === null || nextTime === void 0 ? void 0 : nextTime.isLocked, nextTimestamp: nextUnlockTime },
                            React.createElement("div", { className: "text-block-2" },
                                lockedAmount,
                                React.createElement("sup", null, " veHFI")))) : (React.createElement("div", { className: "div-block-2" },
                            React.createElement("div", { className: "text-block-2" }, "No veHFI Holdings")))),
                    React.createElement("div", { className: "w-form-done" },
                        React.createElement("div", null, "Thank you! Your submission has been received!")),
                    React.createElement("div", { className: "w-form-fail" },
                        React.createElement("div", null, "Oops! Something went wrong while submitting the form.")))),
            React.createElement("div", { className: "card_bottom-half-wrapper" },
                React.createElement("div", { className: "info-display_component" },
                    React.createElement("div", { className: "text-align-left" }, "Locking Period"),
                    React.createElement("div", { className: "text-align-right" }, `${lockedPeriod.period} ${lockedPeriod.periodLabel}`),
                    React.createElement("div", { className: "text-align-left" }, "HFI Rewards"),
                    React.createElement("div", { className: "text-align-right" },
                        potentialReward,
                        " HFI")),
                React.createElement("div", { className: "button-row is-button-row-center" },
                    React.createElement("button", { type: "button", className: "button-secondary max-width-full shadow-medium w-button", onClick: () => unlock(selectedPeriod), disabled: isLoading || isNextTimeLoading || (nextTime === null || nextTime === void 0 ? void 0 : nextTime.isLocked) },
                        "Unlock ",
                        potentialReward,
                        " HFI"))))));
};
