import React from 'react';
// Fragments
import { ListedNftsChart, OpenBidsChart, SoldNftsChart, TradesChart } from '../capsule-analytics/fragments';
// Hooks - API
import { useProtocolMetrics } from '../../common/hooks/api';
import { useCapsuleAnalytics } from '../../common/hooks/useCapsuleAnalytics';
// Static
import { CAPSULE_ANALYTICS_CHARTS } from '../../common/static/chart.static';
// Styles
import './capsule-analytics.scss';
export var CapsuleAnalytics = function () {
    var _a = useProtocolMetrics(), isLoading = _a.isLoading, data = _a.data;
    var _b = useCapsuleAnalytics(data), openBidsNfts = _b.openBidsNfts, listedNfts = _b.listedNfts, totalSold = _b.totalSold;
    return (React.createElement("div", { className: "multifarm-thor-finance-capsule-analytics-page" },
        React.createElement(TradesChart, { metrics: data }),
        React.createElement(ListedNftsChart, { keys: CAPSULE_ANALYTICS_CHARTS.KEYS, data: listedNfts, loading: isLoading }),
        React.createElement("div", { className: "multifarm-thor-finance-capsule-analytics-page-chart-container" },
            React.createElement(OpenBidsChart, { keys: CAPSULE_ANALYTICS_CHARTS.KEYS, data: openBidsNfts, loading: isLoading }),
            React.createElement(SoldNftsChart, { data: totalSold }))));
};
