// Components
import { Dashboard, Invest, MyInvestment, Treasury } from '../../pages';
export var PageEnum;
(function (PageEnum) {
    PageEnum["DASHBOARD"] = "Dashboard";
    PageEnum["INVEST"] = "Invest";
    PageEnum["MY_INVESTMENT"] = "My Investment";
    PageEnum["TREASURY"] = "Treasury";
})(PageEnum || (PageEnum = {}));
export const PageComponentMap = {
    [PageEnum.DASHBOARD]: Dashboard,
    [PageEnum.INVEST]: Invest,
    [PageEnum.MY_INVESTMENT]: MyInvestment,
    [PageEnum.TREASURY]: Treasury
};
export const hybridPages = [
    PageEnum.DASHBOARD,
    PageEnum.INVEST,
    PageEnum.MY_INVESTMENT,
    PageEnum.TREASURY
];
export const HYBRID_LOGO_LINK = 'https://media.discordapp.net/attachments/992095245783285921/1019315023077703770/emblem-lightgrey.png';
export const APP_COLOURS = [
    '#DEDEDE',
    '#C5C5C5',
    '#9A9A9A',
    '#6A6A6A',
    '#454545'
];
