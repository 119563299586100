var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { secondsToMilliseconds } from 'date-fns';
// Contexts
import { useWeb3Context } from '../contexts/web3.context';
// Static
import { getLockerNextTime } from '../utils/helpers';
/**
 * Returns the data regarding the next system's lock tick.
 * The system can be in the locked or the unlocked state. Response object will contain a "isLocked" boolean flag which we can levarage to determine in which state the system is.
 * The "nextTimestamp" is the moment of the next tick, either a lock or an unlock tick based on the current state of the system.
 */
export const useNextTime = (lockerAddress) => {
    const [isLoading, setIsLoading] = useState(false);
    const [nextTime, setNextTime] = useState(null);
    const [nextTimestamp, setNextTimestamp] = useState(0);
    const { provider } = useWeb3Context();
    useEffect(() => {
        const init = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            if (!provider) {
                return;
            }
            try {
                setIsLoading(true);
                const data = yield getLockerNextTime(provider, lockerAddress);
                const nextTimestamp = secondsToMilliseconds((_a = data.nextTimestamp) === null || _a === void 0 ? void 0 : _a.toNumber());
                setIsLoading(false);
                setNextTime(data);
                setNextTimestamp(nextTimestamp);
            }
            catch (e) {
                setIsLoading(false);
            }
        });
        init();
    }, [provider, lockerAddress]);
    return {
        nextTime,
        nextTimestamp,
        isLoading
    };
};
