var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ethers } from 'ethers';
// Contacts
import { ERC20ContractInteface, InfinityPoolContractInterface, LockerContractInterface } from '../../abi';
// Static
import { INFINITY_POOL_ADDRESS, Networks, USDC_ADDRESS } from '../../constants';
export const approveTransaction = (signer, amount, spender, contractAddress = USDC_ADDRESS) => __awaiter(void 0, void 0, void 0, function* () {
    const approvalContract = new ethers.Contract(contractAddress, ERC20ContractInteface, signer);
    const approveTransaction = yield approvalContract.approve(spender, amount);
    return approveTransaction.wait();
});
export const depositTokens = (signer, amount, tokenAddress) => __awaiter(void 0, void 0, void 0, function* () {
    const infinityPoolContract = new ethers.Contract(INFINITY_POOL_ADDRESS, InfinityPoolContractInterface, signer);
    const result = yield infinityPoolContract.buy(tokenAddress, amount);
    return result.wait();
});
export const getUserLockInfo = (provider, address, lockerAddress) => __awaiter(void 0, void 0, void 0, function* () {
    const lockerContract = new ethers.Contract(lockerAddress, LockerContractInterface, provider);
    return lockerContract.userLock(address);
});
export const lockTokens = (signer, amount, lockerAddress) => __awaiter(void 0, void 0, void 0, function* () {
    const lockerContract = new ethers.Contract(lockerAddress, LockerContractInterface, signer);
    const result = yield lockerContract.lock(amount);
    return result.wait();
});
export const increaseLockAmount = (signer, amount, lockerAddress) => __awaiter(void 0, void 0, void 0, function* () {
    const lockerContract = new ethers.Contract(lockerAddress, LockerContractInterface, signer);
    const result = yield lockerContract.increaseLockAmount(amount);
    return result.wait();
});
export const withdrawTokens = (signer, amount) => __awaiter(void 0, void 0, void 0, function* () {
    const infinityPoolContract = new ethers.Contract(INFINITY_POOL_ADDRESS, InfinityPoolContractInterface, signer);
    const result = yield infinityPoolContract.sell(amount);
    return result.wait();
});
export const unlockTokens = (signer, lockerAddress) => __awaiter(void 0, void 0, void 0, function* () {
    const lockerContract = new ethers.Contract(lockerAddress, LockerContractInterface, signer);
    const result = yield lockerContract.unlock();
    return result.wait();
});
export const getTokensPriceUSD = (tokensAmount, price) => {
    const HFIUnits = ethers.BigNumber.from(10).pow(18);
    return tokensAmount.mul(price).div(HFIUnits);
};
export const getCustomRPC = () => ({
    [Networks.ETH]: 'https://eth.public-rpc.com',
    [Networks.AVAX]: 'https://avalanche.public-rpc.com',
    [Networks.POLYGON]: 'https://polygon-rpc.com',
    [Networks.BSC]: 'https://bscrpc.com'
});
export const getLockerNextTime = (provider, lockerAddress) => {
    const lockerContract = new ethers.Contract(lockerAddress, LockerContractInterface, provider);
    return lockerContract.getNextTime();
};
export const getBalance = (provider, contractAddress, address) => __awaiter(void 0, void 0, void 0, function* () {
    const contract = new ethers.Contract(contractAddress, ERC20ContractInteface, provider);
    return contract.balanceOf(address);
});
export const getWalletAddress = (provider) => {
    const signer = provider.getSigner();
    return signer.getAddress();
};
export const getLockerMaxRatio = (provider, lockerAddress) => {
    const lockerContract = new ethers.Contract(lockerAddress, LockerContractInterface, provider);
    return lockerContract.maxRatio();
};
export const getLockerCurrentRatio = (provider, lockerAddress) => {
    const lockerContract = new ethers.Contract(lockerAddress, LockerContractInterface, provider);
    return lockerContract.getCurrentRatio();
};
