var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import { useWeb3Context } from '../contexts/web3.context';
import { InfinityPoolContractInterface } from '../../abi';
import { INFINITY_POOL_ADDRESS } from '../../constants';
export const useTokenPrice = () => {
    const [price, setPrice] = useState(ethers.BigNumber.from(0));
    const { provider } = useWeb3Context();
    useEffect(() => {
        const init = () => __awaiter(void 0, void 0, void 0, function* () {
            if (!provider) {
                return;
            }
            const contract = new ethers.Contract(INFINITY_POOL_ADDRESS, InfinityPoolContractInterface, provider);
            const tokenPrice = yield contract.getPrice();
            setPrice(tokenPrice);
        });
        init();
    }, [provider]);
    return {
        price
    };
};
