import { intervalToDuration, isBefore } from 'date-fns';
/**
 * Creates a date from the given timestamp in seconds
 * @param timestampInSeconds
 * @returns JS Date.
 */
export const createDateFromSeconds = (timestampInSeconds) => {
    return new Date(timestampInSeconds * 1000);
};
/**
 * @param nextDate
 * @returns A string representation of time remaning from the current moment to the given one.
 */
export const getDifferenceInStringFormat = (nextDate) => {
    const currentMoment = new Date();
    if (isBefore(nextDate, currentMoment)) {
        return '00:00:00';
    }
    const duration = intervalToDuration({ start: currentMoment, end: nextDate });
    if (duration.years && duration.years > 0) {
        const noun = duration.years > 1 ? 'years' : 'year';
        return `${duration.years} ${noun}`;
    }
    if (duration.months && duration.months > 0) {
        const noun = duration.months > 1 ? 'months' : 'month';
        return `${duration.months} ${noun}`;
    }
    if (duration.days && duration.days > 0) {
        const noun = duration.days > 1 ? 'days' : 'days';
        return `${duration.days} ${noun}`;
    }
    // Handle the difference that is less than 1 day.
    const hours = duration.hours
        ? duration.hours.toString().padStart(2, '0')
        : '00';
    const minutes = duration.minutes
        ? duration.minutes.toString().padStart(2, '0')
        : '00';
    const seconds = duration.seconds
        ? duration.seconds.toString().padStart(2, '0')
        : '00';
    return `${hours}:${minutes}:${seconds}`;
};
