import React, { useMemo } from 'react';
import cx from 'clsx';
// Components
import { BlockchainShare } from '../BlockchainShare';
import { TotalTreasuryShare } from '../TotalTreasuryShare';
import './TreasuryTabs.css';
export const TreasuryTabs = ({ blockchainDistribution, selectedTab, onChange }) => {
    const blockchains = useMemo(() => Object.keys(blockchainDistribution) || [], [blockchainDistribution]);
    return (React.createElement("div", { className: "multifarm-hybrid-dashboard__treasury-tabs" },
        blockchains.map((blockchain) => (React.createElement(BlockchainShare, { className: cx({
                ['multifarm-hybrid-dashboard__treasury-tab__active']: blockchain === selectedTab
            }), key: `share-${blockchain}`, blockchainShare: blockchainDistribution[blockchain], blockchain: blockchain, onClick: onChange }))),
        React.createElement(TotalTreasuryShare, { className: cx('multifarm-hybrid-dashboard__treasury-total-share', {
                ['multifarm-hybrid-dashboard__treasury-tab__active']: selectedTab === 'Total'
            }), onClick: onChange, blockchainDistribution: blockchainDistribution })));
};
