// Utils
import { formatMoney, formatPercent } from '../utils';
export const ASSET_BREAKDOWN_CHART = {
    COLUMNS: [
        {
            name: '',
            key: '',
            isColorColumn: true,
            width: '1%'
        },
        {
            name: 'Asset',
            key: 'asset',
            align: 'left',
            wrap: true,
            width: '10%'
        },
        {
            name: 'Holdings',
            key: 'holdings',
            format: (holdings) => `${formatMoney(holdings)}`,
            align: 'center',
            wrap: true,
            width: '10%'
        },
        {
            name: '% Breakdown',
            key: 'breakdown',
            format: (breakdown) => `${formatPercent(breakdown)}`,
            align: 'center',
            wrap: true,
            width: '10%'
        }
    ],
    DATA: [
        {
            asset: 'MIM3CRV',
            holdings: '4360000',
            breakdown: 30.47
        },
        {
            asset: 'alETHCRV',
            holdings: '2830000',
            breakdown: 19.77
        },
        {
            asset: 'crvCVXETH',
            holdings: '1840000',
            breakdown: 12.88
        },
        {
            asset: 'crv3crypro',
            holdings: '1320000',
            breakdown: 9.26
        }
    ],
    COLORS: ['#66B559', '#0C164F', '#DD924F', '#CCCCCC', '#59AFB5', '#8A8A8A']
};
export const LOAN_BREAKDOWN_CHART = {
    COLUMNS: [
        {
            name: '',
            key: '',
            isColorColumn: true,
            width: '1%'
        },
        {
            name: 'Asset',
            key: 'asset',
            align: 'left',
            wrap: true,
            width: '10%'
        },
        {
            name: 'Size',
            key: 'size',
            format: (size) => `${formatMoney(size)}`,
            align: 'center',
            wrap: true,
            width: '10%'
        },
        {
            name: '% Breakdown',
            key: 'breakdown',
            format: (breakdown) => `${formatPercent(breakdown)}`,
            align: 'center',
            wrap: true,
            width: '10%'
        }
    ],
    DATA: [
        {
            asset: 'USDC',
            size: '4360000',
            breakdown: 30.47
        },
        {
            asset: 'USDT',
            size: '2830000',
            breakdown: 19.77
        },
        {
            asset: 'DAI',
            size: '1840000',
            breakdown: 12.88
        },
        {
            asset: 'FRAX',
            size: '1320000',
            breakdown: 9.26
        }
    ],
    COLORS: ['#8A8A8A', '#59AFB5', '#66B559', '#0C164F', '#DD924F', '#CCCCCC']
};
export const RECENT_LOAN_REPAYMENTS_CHART = {
    DATA: [
        {
            value: 400,
            date: '2022-12-01'
        },
        {
            value: 430,
            date: '2022-12-02'
        },
        {
            value: 300,
            date: '2022-12-03'
        },
        {
            value: 280,
            date: '2022-12-04'
        },
        {
            value: 515,
            date: '2022-12-04'
        },
        {
            value: 115,
            date: '2022-12-05'
        },
        {
            value: 245,
            date: '2022-12-06'
        },
        {
            value: 729,
            date: '2022-12-07'
        },
        {
            value: 109,
            date: '2022-12-08'
        },
        {
            value: 622,
            date: '2022-12-09'
        }
    ],
    KEYS: ['value'],
    COLORS: ['#dd924f']
};
export const VAULT_APY_CHART = {
    DATA: [
        {
            MIM3CRV: 400,
            alETHCRV: 300,
            crvCVXETH: 500,
            crv3crypto: 100,
            FRAX3CRV: 60,
            date: '2022-12-01'
        },
        {
            MIM3CRV: 200,
            alETHCRV: 10,
            crvCVXETH: 430,
            crv3crypto: 440,
            FRAX3CRV: 400,
            date: '2022-12-02'
        },
        {
            MIM3CRV: 403,
            alETHCRV: 150,
            crvCVXETH: 377,
            crv3crypto: 450,
            FRAX3CRV: 230,
            date: '2022-12-03'
        },
        {
            MIM3CRV: 400,
            alETHCRV: 420,
            crvCVXETH: 456,
            crv3crypto: 454,
            FRAX3CRV: 430,
            date: '2022-12-04'
        },
        {
            MIM3CRV: 102,
            alETHCRV: 120,
            crvCVXETH: 200,
            crv3crypto: 456,
            FRAX3CRV: 300,
            date: '2022-12-04'
        },
        {
            MIM3CRV: 700,
            alETHCRV: 900,
            crvCVXETH: 700,
            crv3crypto: 800,
            FRAX3CRV: 600,
            date: '2022-12-05'
        },
        {
            MIM3CRV: 500,
            alETHCRV: 600,
            crvCVXETH: 800,
            crv3crypto: 700,
            FRAX3CRV: 600,
            date: '2022-12-06'
        },
        {
            MIM3CRV: 200,
            alETHCRV: 300,
            crvCVXETH: 500,
            crv3crypto: 300,
            FRAX3CRV: 400,
            date: '2022-12-07'
        },
        {
            MIM3CRV: 420,
            alETHCRV: 430,
            crvCVXETH: 410,
            crv3crypto: 420,
            FRAX3CRV: 405,
            date: '2022-12-08'
        },
        {
            MIM3CRV: 407,
            alETHCRV: 405,
            crvCVXETH: 404,
            crv3crypto: 430,
            FRAX3CRV: 420,
            date: '2022-12-09'
        }
    ],
    KEYS: ['MIM3CRV', 'alETHCRV', 'crvCVXETH', 'crv3crypto', 'FRAX3CRV'],
    COLORS: ['#F8CA02', '#00C8FF', '#8A8A8A', '#66B559', '#CCCCCC']
};
export const INTEREST_RATE_BREAKDOWN_CHART = {
    DATA: [
        {
            vault: 30,
            borrow: 20,
            date: '2022-12-01'
        },
        {
            vault: 40,
            borrow: 30,
            date: '2022-12-02'
        },
        {
            vault: 25,
            borrow: 10,
            date: '2022-12-03'
        },
        {
            vault: 50,
            borrow: 40,
            date: '2022-12-04'
        },
        {
            vault: 60,
            borrow: 10,
            date: '2022-12-04'
        },
        {
            vault: 90,
            borrow: 30,
            date: '2022-12-05'
        },
        {
            vault: 50,
            borrow: 15,
            date: '2022-12-06'
        },
        {
            vault: 30,
            borrow: 30,
            date: '2022-12-07'
        },
        {
            vault: 60,
            borrow: 10,
            date: '2022-12-08'
        },
        {
            vault: 100,
            borrow: 90,
            date: '2022-12-09'
        }
    ],
    KEYS: ['vault', 'borrow'],
    COLORS: ['#CCCCCC', '#FF00E5']
};
export const UTILISATION_CHART = {
    COLUMNS: [
        {
            name: '',
            key: '',
            isColorColumn: true,
            width: '1%'
        },
        {
            name: 'Category',
            key: 'category',
            align: 'left',
            wrap: true,
            width: '10%'
        },
        {
            name: 'Utilisation',
            key: 'utilisation',
            format: (size) => `${formatMoney(size)}`,
            align: 'center',
            wrap: true,
            width: '10%'
        }
    ],
    DATA: [
        {
            category: 'Total Liquidity',
            utilisation: 1000
        },
        {
            category: 'Total Borrowable Amount',
            utilisation: 900
        },
        {
            category: 'Total Borrowed Amount',
            utilisation: 500
        }
    ],
    COLORS: ['#FF00E5', '#59AFB5', '#8A8A8A']
};
