import React, { useState } from 'react';
import cx from 'clsx';
// Icons
import { LockIcon, UnlockIcon } from '../../assets/icons';
import { LockForm } from './components/LockForm';
import { UnlockForm } from './components/UnlockForm';
import { LOCKER_12_ADDRESS, LOCKER_3_ADDRESS, LOCKER_6_ADDRESS } from '../../constants';
const periods = [
    {
        period: 3,
        periodLabel: 'months',
        value: LOCKER_3_ADDRESS
    },
    {
        period: 6,
        periodLabel: 'months',
        value: LOCKER_6_ADDRESS
    },
    {
        period: 12,
        periodLabel: 'months',
        value: LOCKER_12_ADDRESS
    }
];
// TODO: in webflow tabs appeared with animation
export const LockerForm = () => {
    const [tab, setTab] = useState('LOCK');
    return (React.createElement("div", { className: "multifarm-hybrid-dashboard__invest-page-form" },
        React.createElement("div", { className: "tabs w-tabs locker-form-tabs" },
            React.createElement("div", { className: "tabs-menu-3 w-tab-menu" },
                React.createElement("button", { type: "button", className: cx('tab-link-lock w-inline-block w-tab-link', {
                        ['w--current']: tab === 'LOCK'
                    }), onClick: () => setTab('LOCK') },
                    React.createElement("div", { className: "card-item_icon-wrapper" },
                        React.createElement("div", { className: "icon_invest w-embed" },
                            React.createElement(LockIcon, { width: 32, height: 32 })),
                        React.createElement("div", { className: "text-block-5" }, "Lock"))),
                React.createElement("button", { type: "button", className: cx('tab-link-lock w-inline-block w-tab-link', {
                        ['w--current']: tab === 'UNLOCK'
                    }), onClick: () => setTab('UNLOCK') },
                    React.createElement("div", { className: "card-item_icon-wrapper" },
                        React.createElement("div", { className: "icon_invest w-embed" },
                            React.createElement(UnlockIcon, { width: 32, height: 32 })),
                        React.createElement("div", { className: "text-block-5" }, "Unlock"))))),
        tab === 'LOCK' && React.createElement(LockForm, { periods: periods }),
        tab === 'UNLOCK' && React.createElement(UnlockForm, { periods: periods })));
};
