import React, { useCallback, useState } from 'react';
import { Input } from '@multifarm/widget-components';
// Utils
import { formatNumber } from '../../../../../../../common/utils';
// Styles
import './withdraw.scss';
const AVAILABLE_WITHDRAW_AMOUNT = 120508;
export const WithdrawTab = () => {
    const [withdrawAmount, setWithdrawAmount] = useState('');
    /**
     * Handles withdraw amount change.
     * @param value - Withdraw amount value.
     */
    const handleWithdrawChange = useCallback((value) => {
        const numeric = +value;
        if (isNaN(numeric)) {
            return;
        }
        if (numeric === 0) {
            setWithdrawAmount('0');
            return;
        }
        if (numeric > AVAILABLE_WITHDRAW_AMOUNT) {
            setWithdrawAmount(AVAILABLE_WITHDRAW_AMOUNT + '');
            return;
        }
        setWithdrawAmount(value);
    }, []);
    /**
     * Handles clear action.
     */
    const handleClearAction = useCallback(() => {
        setWithdrawAmount('');
    }, []);
    /**
     * Handles clear action.
     */
    const handleMaxAction = useCallback(() => {
        setWithdrawAmount(AVAILABLE_WITHDRAW_AMOUNT + '');
    }, []);
    return (React.createElement("div", { className: "numisme-vault-tab-withdraw" },
        React.createElement("div", { className: "numisme-vault-tab-section numisme-vault-tab-section-highlight" },
            React.createElement("span", { className: "text-primary" },
                "Available withdrawal amount:",
                ' ',
                React.createElement("span", { className: "text-secondary text-bold" }, formatNumber(AVAILABLE_WITHDRAW_AMOUNT, 2, true))),
            React.createElement("div", { className: "withdraw-container" },
                React.createElement(Input, { onChange: handleWithdrawChange, value: withdrawAmount, placeholder: "0", title: "Withdraw:", className: "text-secondary text-bold" }),
                React.createElement("div", { className: "withdraw-actions" },
                    React.createElement("span", { className: "clickable", onClick: handleMaxAction }, "MAX"),
                    React.createElement("span", { className: "clickable", onClick: handleClearAction }, "CLEAR")))),
        React.createElement("div", { className: "numisme-vault-tab-action-button clickable text-primary" }, "Withdraw")));
};
