import React from 'react';
// Components
import { App } from './App';
// Context
import { SolanaRealmsContextProvider } from './context/solana-realms.context';
// Styles
import './assets/styles/main.scss';
export var MultifarmSolanaRealms = function (_a) {
    var wallets = _a.wallets;
    return (React.createElement(SolanaRealmsContextProvider, { wallets: wallets },
        React.createElement(App, null)));
};
