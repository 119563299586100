import React from 'react';
import './placeholders.css';
export const EmptyPlaceholder = ({ text, height }) => {
    return (React.createElement("div", { className: "multifarm-hybrid-finance__placeholder", style: { minHeight: height } },
        React.createElement("p", null, text)));
};
EmptyPlaceholder.defaultProps = {
    text: 'No Data',
    height: 300
};
