// Icons
import { AVAXIcon, PolygonIcon, BSCIcon } from '../../assets/icons';
export const NETWORK_ICONS = {
    56: BSCIcon,
    137: PolygonIcon,
    43114: AVAXIcon
};
export const NETWORK_NAMES = {
    56: 'Binance',
    137: 'Polygon',
    43114: 'Avalanche'
};
