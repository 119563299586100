import React, { useState } from 'react';
import { BarChart } from '@multifarm/widget-components';
// Components
import { ChardCard } from '../../../../../components/chart-card/ChardCard';
// Static
import { RangeTypeEnum } from '../../../../../common/static';
import { RECENT_LOAN_REPAYMENTS_CHART } from '../../../../../common/static/placeholder.static';
// Utils
import { capitalize, formatDate, formatMoney } from '../../../../../common/utils';
export const RecentLoanRepaymentsChart = () => {
    const [range, setRange] = useState(RangeTypeEnum['14D']);
    return (React.createElement(ChardCard, { title: "Recent Loan Repayments", rangeOptions: { range, onRange: setRange } },
        React.createElement(BarChart, { data: RECENT_LOAN_REPAYMENTS_CHART.DATA, dataKeys: RECENT_LOAN_REPAYMENTS_CHART.KEYS, colors: RECENT_LOAN_REPAYMENTS_CHART.COLORS, yTickFormatter: (tick) => formatMoney(tick, 2, true), xTickFormatter: (tick) => formatDate(tick), barOptions: { maxBarSize: 30, radius: 10 }, tooltipOptions: {
                formatTooltipKey: (key) => capitalize(key)
            } })));
};
