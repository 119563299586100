var _a;
import React, { useState } from 'react';
// Pages
import { CapsuleAnalytics, GameloopStats } from './pages';
// Components
import { Header } from './components/header/Header';
// Static
import { PageEnum } from './common/static/app.static';
export var PAGE_COMPONENTS = (_a = {},
    _a[PageEnum.CAPSULE_ANALYTICS] = CapsuleAnalytics,
    _a[PageEnum.GAMELOOP_STATS] = GameloopStats,
    _a);
export var App = function () {
    var _a = useState(PageEnum.CAPSULE_ANALYTICS), page = _a[0], setPage = _a[1];
    var Page = PAGE_COMPONENTS[page];
    return (React.createElement("div", { className: "multifarm-thor-finance" },
        React.createElement(Header, { page: page, setPage: setPage }),
        React.createElement("div", { className: "multifarm-thor-finance-page" },
            React.createElement(Page, null))));
};
