import { useState, useEffect } from 'react'

export default function useContentLoad(config = {}) {
  const [content, setContent] = useState('')
  const [error, setError] = useState('')

  useEffect(() => {
    if (config.content) {
      fetch(config.content)
        .then((res) => res.text())
        .then((res) => setContent(res))
        .catch(() => setError('Page not loaded'))
    }
  }, [config.content])

  return {
    content,
    error
  }
}
