import React from 'react';
import { ProgressBar } from '@multifarm/widget-components';
// Assets
import { NumismeIcon } from '../../../../../assets/icons';
export const VaultHeader = ({ open, setOpen, progress, blockchainIcon }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "clickable numisme-vault-toggle", onClick: () => setOpen(!open) }, open ? '-' : '+'),
        React.createElement("div", { className: "icons-section" },
            React.createElement(NumismeIcon, { width: 30, height: 30 }),
            blockchainIcon),
        React.createElement("div", { className: "numisme-vault-section" },
            React.createElement("div", { className: "text-secondary" }, "Deposit"),
            React.createElement("div", { className: "text-primary" }, "USD"),
            React.createElement("div", { className: "text-secondary" }, "Numisme + USDC")),
        React.createElement("div", { className: "numisme-vault-section" },
            React.createElement("div", { className: "text-secondary" }, "Deposit"),
            React.createElement("div", { className: "text-primary" }, "0.0 USDC"),
            React.createElement("div", { className: "text-secondary" }, "$0.00")),
        React.createElement("div", { className: "numisme-vault-section" },
            React.createElement("div", { className: "text-secondary" }, "TVL"),
            React.createElement("div", { className: "text-primary" }, "11.5k USDC"),
            React.createElement("div", { className: "text-secondary" }, "$114,675.67k")),
        React.createElement("div", { className: "numisme-vault-section" },
            React.createElement("div", { className: "text-secondary" }, "Utilization"),
            React.createElement("div", null,
                React.createElement(ProgressBar, { progress: progress })),
            React.createElement("div", { className: "text-secondary" }, "$114.67k")),
        React.createElement("div", { className: "numisme-vault-section" },
            React.createElement("div", { className: "text-secondary" }, "LTV"),
            React.createElement("div", { className: "text-primary" }, "50%"),
            React.createElement("div", { className: "text-secondary" }, "Deposit"))));
};
