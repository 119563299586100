import React from 'react';
import { Legends, Tabs } from '@multifarm/widget-components';
// Static
import { NODES_CHART_FILTER_TABS } from '../../../../static';
// Styles
import './nodes-chart-header.scss';
export var NodesChartHeader = function (_a) {
    var chartKeys = _a.chartKeys, chartColours = _a.chartColours, activeFilter = _a.activeFilter, setActiveFilter = _a.setActiveFilter;
    return (React.createElement("div", { className: "multifarm-thor-finance-nodes-chart-header" },
        React.createElement(Tabs, { tabs: NODES_CHART_FILTER_TABS, value: activeFilter, onChange: function (filter) {
                return setActiveFilter(filter);
            } }),
        React.createElement(Legends, { className: "multifarm-thor-finance-nodes-chart-header__legends", legends: chartKeys, colors: chartColours })));
};
