var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { fetchEventSource } from '@microsoft/fetch-event-source';
// Static
import { SourceEventTypeEnum } from '../../static/event.static';
// Utils
import { omitEmpty, stringifyURL } from '../../utils';
/**
 * Commonly used API fetcher.
 * @param url - Endpoint URL
 * @param params - Request parameters
 * @returns Response from the server
 */
export var fetcher = function (url, params) { return __awaiter(void 0, void 0, void 0, function () {
    var response, json, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                return [4 /*yield*/, fetch(process.env.REACT_APP_API_URL + url, __assign(__assign({}, params), { headers: {
                            'Content-Type': 'application/json'
                        } }))];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.json()];
            case 2:
                json = _a.sent();
                if ((json === null || json === void 0 ? void 0 : json.error) === 'Not Authorized!') {
                    throw new Error(json === null || json === void 0 ? void 0 : json.error);
                }
                return [2 /*return*/, json];
            case 3:
                e_1 = _a.sent();
                throw e_1;
            case 4: return [2 /*return*/];
        }
    });
}); };
/**
 * Stringifies the request params.
 * @param {string} route - Route endpoint.
 * @param {Record<string, any>} params - Data to add to query params.
 * @returns Stringified url.
 */
export var buildRoute = function (route, params) {
    return stringifyURL(route, omitEmpty(params));
};
/**
 * Commonly used API event streamer.
 * Initiates a stream and configures success and error callbacks.
 * @param url - Stream URL
 * @param handlers - Handler callbacks for success and error cases.
 */
export var fetchStreamData = function (url, handlers) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        if (url.charAt(0) === '/') {
            url = url.slice(1, url.length);
        }
        fetchEventSource("".concat(process.env.REACT_APP_API_URL, "/").concat(url), {
            method: 'GET',
            headers: {
                Accept: 'text/event-stream'
            },
            onopen: function (response) { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    if (!response.ok || response.status > 299) {
                        handlers.errorHandler(response.status);
                        return [2 /*return*/];
                    }
                    if (handlers.loadHandler) {
                        handlers.loadHandler();
                    }
                    return [2 /*return*/];
                });
            }); },
            onmessage: function (event) {
                if (event.event === SourceEventTypeEnum.END_EVENT) {
                    handlers.successHandler(JSON.parse(event.data));
                }
            },
            onerror: function (error) {
                console.log('Stream error ocurred', error);
            }
        });
        return [2 /*return*/];
    });
}); };
