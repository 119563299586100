import useSWR from 'swr';
export var useLiquidationsStats = function () {
    var _a = useSWR('/auth/liquidations/stats'), body = _a.data, error = _a.error;
    var isLoading = !body && !error;
    var _b = (body || {}).data, data = _b === void 0 ? [] : _b;
    var _c = data[0], stats = _c === void 0 ? null : _c;
    return {
        isLoading: isLoading,
        data: stats
    };
};
