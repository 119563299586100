// Icons
import { AVAXIcon, BSCIcon, ETHIcon, PolygonIcon } from '../../assets/icons';
export const BLOCKCHAIN_ICONS = {
    AVAX: AVAXIcon,
    BSC: BSCIcon,
    ETH: ETHIcon,
    POLYGON: PolygonIcon
};
export const BLOCKCHAIN_COLOURS = {
    AVAX: '#f24344',
    BSC: '#f6c603',
    ETH: '#78d6ff',
    POLYGON: '#8247e5'
};
