import React from 'react';
// Components
import { HeaderTabs } from './tabs/HeaderTabs';
import { WalletConnect } from '../wallet-connect/WalletConnect';
// Static
import { PageEnum } from '../../common/static/app.static';
// Icons
import { EFDIcon, ETHFilledIcon, NumismeIcon } from '../../assets/icons';
// Styles
import './header.scss';
export const Header = ({ page, setPage }) => {
    return (React.createElement("div", { className: "numisme-header" },
        React.createElement(NumismeIcon, { width: 173, height: 54, className: "logo clickable", onClick: () => setPage(PageEnum.GENERAL_OVERVIEW) }),
        React.createElement(HeaderTabs, { activePage: page, setPage: setPage }),
        React.createElement("div", { className: "token-symbols" },
            React.createElement(ETHFilledIcon, null),
            React.createElement(EFDIcon, null)),
        React.createElement(WalletConnect, null)));
};
