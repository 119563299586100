import V2Contract from './HybridV2.json';
import StableReserveContract from './StableContract.json';
import VeHybridContract from './veHybrid.json';
import InfinityPoolContract from './InfinityPool.json';
import LockerContract from './Locker.json';
import ERC20Contract from './ERC20.json';
export const HybridV2ContractInterface = V2Contract;
export const StableReserveContractInterface = StableReserveContract;
export const VeHybridContractInterface = VeHybridContract;
export const InfinityPoolContractInterface = InfinityPoolContract;
export const LockerContractInterface = LockerContract;
export const ERC20ContractInteface = ERC20Contract;
