import React from 'react';
import { Cell, Pie, PieChart as RePierChart, ResponsiveContainer } from 'recharts';
// Styles
import './PieChart.css';
export const PieChart = ({ data, dataKey = 'value', innerRadius = '60%', outerRadius = '100%' }) => {
    return (React.createElement("div", { className: "pie-chart" },
        React.createElement("div", { className: "pie-chart__chart" },
            React.createElement(ResponsiveContainer, { width: "100%", height: "100%" },
                React.createElement(RePierChart, null,
                    React.createElement(Pie, { data: data, dataKey: dataKey, outerRadius: outerRadius, innerRadius: innerRadius, paddingAngle: 0, stroke: '0' }, data.map((row, index) => (React.createElement(Cell, { key: index, fill: row.color })))))))));
};
