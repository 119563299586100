import React from 'react';
// Components
import { InfoCard } from '../../../../components/info-card/InfoCard';
// Utils
import { formatMoney } from '../../../../common/utils/format.utils';
// Styles
import './info-cards.scss';
export const InfoCards = () => {
    return (React.createElement("div", { className: "general-overview-page-info-cards" },
        React.createElement(InfoCard, { title: "Total TVL", body: formatMoney(6400000) }),
        React.createElement(InfoCard, { title: "Total Deposited", body: formatMoney(25000000) }),
        React.createElement(InfoCard, { title: "Total Collateral", body: formatMoney(928400) }),
        React.createElement(InfoCard, { title: "Total Borrow", body: formatMoney(-15810400) })));
};
