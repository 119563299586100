import React, { useMemo, useState } from 'react';
import cx from 'clsx';
// Components
import { LineChart } from '../lineChart';
import { Tabs } from '../tabs';
// Static
import { RANGES } from '../../common/static/formats.static';
// Utils
import { parseRangeChart } from '../../common/utils';
// Styles
import './HybridLineChart.css';
const renderGradients = () => {
    return (React.createElement("defs", null,
        React.createElement("linearGradient", { id: "gridGradient", x1: "0", y1: "0", x2: "0", y2: "100%" },
            React.createElement("stop", { offset: "0%", stopColor: "var(--grid-bg)", stopOpacity: 0 }),
            React.createElement("stop", { offset: "60%", stopColor: "var(--grid-bg)" }))));
};
const gridProps = {
    fill: 'url(#gridGradient)',
    vertical: false,
    stroke: 'var(--grid-stroke)'
};
const lineProps = {
    filter: 'drop-shadow(0px 0px 5px var(--chart-line-bg))',
    stroke: 'var(--chart-line-bg)',
    dot: false,
    strokeLinecap: 'round'
};
const containerProps = { width: '90%' };
export const HybridLineChart = ({ data, defaultRange = '4h', ranges = RANGES, isLoading, theme, yTickFormatter, tooltipFormatter, yAxisProps }) => {
    const [range, setRange] = useState(defaultRange);
    const chartData = useMemo(() => parseRangeChart(range, data), [data, range]);
    return (React.createElement("div", { className: cx('multifarm-hybrid-finance__line-chart', {
            ['multifarm-hybrid-finance__line-chart__light']: theme === 'light'
        }) },
        React.createElement(LineChart, { data: chartData, renderGradients: renderGradients, cartesianGridProps: gridProps, containerProps: containerProps, isLoading: isLoading, lineProps: lineProps, yAxisProps: Object.assign({ width: 100 }, yAxisProps), tooltipFormatter: tooltipFormatter, yTickFormatter: yTickFormatter }),
        React.createElement(Tabs, { tabs: ranges, selected: range, onChange: setRange })));
};
HybridLineChart.defaultProps = {
    isLoading: false,
    theme: 'dark'
};
