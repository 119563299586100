var _a;
export var DEFAULT_DATE_FORMAT = 'MMM dd';
export var DATE_FORMAT_SECONDARY = 'dd.MM.YYYY';
export var API_DATE_FORMAT_PRIMARY = 'yyyy.MM.dd';
export var API_DATE_FORMAT_SECONDARY = 'yyyy-MM-dd';
export var CHART_TOOLTIP_DATE_PRIMARY = 'MMMM dd, yyyy';
export var RangeTypeEnum;
(function (RangeTypeEnum) {
    RangeTypeEnum["7D"] = "7d";
    RangeTypeEnum["14D"] = "14d";
    RangeTypeEnum["30D"] = "30d";
    RangeTypeEnum["90D"] = "90d";
    RangeTypeEnum["180D"] = "180d";
    RangeTypeEnum["1Y"] = "1y";
    RangeTypeEnum["MAX"] = "max";
})(RangeTypeEnum || (RangeTypeEnum = {}));
export var RANGE_OPTION_NAMES = (_a = {},
    _a[RangeTypeEnum['7D']] = '7d',
    _a[RangeTypeEnum['14D']] = '14d',
    _a[RangeTypeEnum['30D']] = '30d',
    _a[RangeTypeEnum['90D']] = '90d',
    _a[RangeTypeEnum['180D']] = '180d',
    _a[RangeTypeEnum['1Y']] = '1y',
    _a[RangeTypeEnum.MAX] = 'Max',
    _a);
export var RANGES = [
    { name: RANGE_OPTION_NAMES[RangeTypeEnum['7D']], value: RangeTypeEnum['7D'] },
    {
        name: RANGE_OPTION_NAMES[RangeTypeEnum['14D']],
        value: RangeTypeEnum['14D']
    },
    {
        name: RANGE_OPTION_NAMES[RangeTypeEnum['30D']],
        value: RangeTypeEnum['30D']
    },
    {
        name: RANGE_OPTION_NAMES[RangeTypeEnum['90D']],
        value: RangeTypeEnum['90D']
    },
    {
        name: RANGE_OPTION_NAMES[RangeTypeEnum['180D']],
        value: RangeTypeEnum['180D']
    },
    { name: RANGE_OPTION_NAMES[RangeTypeEnum['1Y']], value: RangeTypeEnum['1Y'] },
    { name: RANGE_OPTION_NAMES[RangeTypeEnum['MAX']], value: RangeTypeEnum.MAX }
];
export var CurrencyEnum;
(function (CurrencyEnum) {
    CurrencyEnum["USD"] = "USD";
    CurrencyEnum["ETH"] = "ETH";
})(CurrencyEnum || (CurrencyEnum = {}));
export var CURRENCIES = [
    { name: 'USD', value: CurrencyEnum.USD },
    { name: 'ETH', value: CurrencyEnum.ETH }
];
