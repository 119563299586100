import useSWR from 'swr';
export var useLiquidationsTokens = function () {
    var _a = useSWR('/auth/liquidations/tokens'), body = _a.data, error = _a.error;
    var isLoading = !body && !error;
    var _b = (body || {}).data, data = _b === void 0 ? [] : _b;
    return {
        isLoading: isLoading,
        data: data
    };
};
