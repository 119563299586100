// Libs
import React from 'react';
import { BarChart } from '@multifarm/widget-components';
// Components
import { Card } from '../Card';
// Hooks
import { useChartColors } from '../../hooks/useChartColors';
// Utils
import { formatAxisDate, formatChartMoney, formatTooltipDate, formatTooltipMoney } from '../../utils/format';
var defaultTooltipOptions = {
    formatCategory: function (category) { return (React.createElement("span", { style: { fontSize: '1rem' } }, formatTooltipDate(category))); },
    formatTooltipValue: formatTooltipMoney
};
var defaultBarOptions = { maxBarSize: 40 };
var defaultChartOptions = {
    yAxis: {
        tick: { fill: 'var(--text-color-secondary)', fontSize: '0.625rem' },
        domain: [0, 'auto']
    },
    xAxis: { tick: { fill: 'var(--text-color-secondary)', fontSize: '0.625rem' } }
};
export var BarChartCard = function (_a) {
    var data = _a.data, dataKeys = _a.dataKeys, title = _a.title, headerComponent = _a.headerComponent, isLoading = _a.isLoading;
    var colors = useChartColors(data.length);
    return (React.createElement(Card, { title: title, headerComponent: headerComponent },
        React.createElement(BarChart, { data: data, dataKeys: dataKeys, colors: colors, barOptions: defaultBarOptions, xTickFormatter: formatAxisDate, tooltipOptions: defaultTooltipOptions, yTickFormatter: formatChartMoney, chartOptions: defaultChartOptions, isLoading: isLoading })));
};
