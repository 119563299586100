var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import React, { useCallback, useEffect, useMemo, useState } from 'react';
// Pages
import { Dashboard, NftView } from './pages';
// Components
import { Sidebar } from './components/sidebar';
import { Header } from './components/header/Header';
// Hooks - API
import { useWalletStats } from './common/hooks/api/useWalletStats';
// Context
import { useSolanaRealmsContext } from './context/solana-realms.context';
// Static
import { PageEnum } from './common/static/app.static';
import { PageTab } from './components/page-tab';
export var PAGE_COMPONENTS = (_a = {},
    _a[PageEnum.DASHBOARD] = Dashboard,
    _a[PageEnum.NFT_VIEW] = NftView,
    _a);
export var App = function () {
    var _a = useState(PageEnum.DASHBOARD), page = _a[0], setPage = _a[1];
    var _b = useSolanaRealmsContext(), walletAddresses = _b.walletAddresses, overviewState = _b.overviewState, setOverviewState = _b.setOverviewState, aggregatedNftState = _b.aggregatedNftState;
    useEffect(function () {
        if (overviewState.nftCollection ||
            (overviewState.nftCollections && overviewState.nftCollections.length > 0)) {
            setPage(PageEnum.NFT_VIEW);
            return;
        }
        setPage(PageEnum.DASHBOARD);
    }, [overviewState]);
    var changePage = useCallback(function (page) {
        if (page === PageEnum.DASHBOARD) {
            setOverviewState(__assign(__assign({}, overviewState), { nftCollection: undefined, nftCollections: undefined, walletAddress: undefined }));
        }
        setPage(page);
    }, [overviewState]);
    /**
     * Memorising the Page component.
     */
    var Page = useMemo(function () {
        return PAGE_COMPONENTS[page];
    }, [page]);
    var data = useWalletStats(walletAddresses).data;
    if (walletAddresses.length === 0) {
        return (React.createElement("main", { className: "multifarm-solana-realms" },
            React.createElement("div", { className: "multifarm-solana-realms__misconfiguration" }, "No wallets provided.")));
    }
    return (React.createElement("main", { className: "multifarm-solana-realms" },
        React.createElement(Header, { balance: (data === null || data === void 0 ? void 0 : data.totalHoldingsUsd) || 0 }),
        React.createElement("section", { className: "multifarm-solana-realms__content" },
            React.createElement(Sidebar, { walletAddresses: walletAddresses, className: "multifarm-solana-realms__aside" }),
            React.createElement("div", { className: "multifarm-solana-realms-page" },
                React.createElement("div", { className: "multifarm-solana-realms-page__actions" },
                    React.createElement("div", { className: "multifarm-solana-realms-page__actions-tabs" },
                        React.createElement(PageTab, { page: PageEnum.DASHBOARD, active: page === PageEnum.DASHBOARD, setPage: changePage }),
                        aggregatedNftState.aggregatedCollections.length > 0 && (React.createElement(PageTab, { page: PageEnum.NFT_VIEW, active: page === PageEnum.NFT_VIEW, setPage: changePage })))),
                React.createElement("div", { className: "multifarm-solana-realms-page__content" },
                    React.createElement(Page, null))))));
};
