var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { isAfter, subWeeks, subMonths, subYears, differenceInDays } from 'date-fns';
// Static
import { RangeTypeEnum } from '../static';
// Utils
import { createRangeOptions } from './global.utils';
/**
 * Determines if the given tick should be filtered using the provided callback function.
 * @param tick - Chart's data tick.
 * @param filter - A callback function to filter the tick by.
 * @returns Should tick be filtered boolean.
 */
var filterDate = function (tick, filter) {
    return filter ? filter(tick.date) : false;
};
/**
 * Checks if the given tick holds a value greater than 0 for at least one of the given keys.
 * @param tick
 * @param keys
 * @returns Should tick be filtered boolean.
 */
var filterKeys = function (tick, keys) {
    if (keys.length === 0)
        return true;
    for (var _i = 0, keys_1 = keys; _i < keys_1.length; _i++) {
        var key = keys_1[_i];
        if (tick[key] && tick[key] > 0) {
            return true;
        }
    }
    return false;
};
/**
 * A map of date filter utils for each used range.
 */
var DATE_RANGE_FILTERS = (_a = {},
    _a[RangeTypeEnum['7D']] = function (date) {
        return isAfter(new Date(date), subWeeks(new Date(), 1));
    },
    _a[RangeTypeEnum['14D']] = function (date) {
        return isAfter(new Date(date), subWeeks(new Date(), 2));
    },
    _a[RangeTypeEnum['30D']] = function (date) {
        return isAfter(new Date(date), subMonths(new Date(), 1));
    },
    _a[RangeTypeEnum['90D']] = function (date) {
        return isAfter(new Date(date), subMonths(new Date(), 3));
    },
    _a[RangeTypeEnum['180D']] = function (date) {
        return isAfter(new Date(date), subMonths(new Date(), 6));
    },
    _a[RangeTypeEnum['1Y']] = function (date) {
        return isAfter(new Date(date), subYears(new Date(), 1));
    },
    _a[RangeTypeEnum['MAX']] = undefined,
    _a);
/**
 * Filters the given tick by the date range and the provided keys.
 * @param tick - Chart's data tick.
 * @param range - Chart's given range.
 * @param keys - An optional parameter used to additionally filter the data by the keys that hold the value. If passed, the function will check if the row has a value greated than 0 for at least one of the given keys.
 * @returns Should tick be filtered boolean.
 */
var filterTick = function (tick, range, keys) {
    if (keys === void 0) { keys = []; }
    return filterDate(tick, DATE_RANGE_FILTERS[range]) && filterKeys(tick, keys);
};
/**
 * A util function used to parse the given data by range.
 * @param data - Array of data that should be parsed by range.
 * @param range - Chart's given range.
 * @param keys - An optional parameter used to additionally filter the data by the keys that hold the value. If passed, the function will check if the row has a value greated than 0 for at least one of the given keys.
 * @returns Parsed chart data.
 */
export var parseRangeChartData = function (data, range, keys) {
    if (keys === void 0) { keys = []; }
    switch (range) {
        case RangeTypeEnum['7D']:
        case RangeTypeEnum['14D']:
        case RangeTypeEnum['30D']:
        case RangeTypeEnum['90D']:
        case RangeTypeEnum['180D']:
        case RangeTypeEnum['1Y']:
            return data.filter(function (tick) { return filterTick(tick, range, keys); });
        case RangeTypeEnum['MAX']:
            if (keys.length !== 0) {
                return data.filter(function (row) { return filterKeys(row, keys); });
            }
            return data;
        default:
            return data;
    }
};
/**
 * Determines only range options that are applicable to the given historical data.
 * @param data - Array containing hitorical data.
 * @returns Range options array based on the provided historical data.
 */
export var getRangeOptions = function (data) {
    if (data.length === 0) {
        return [];
    }
    var firstElement = data[0];
    var lastElement = data[data.length - 1];
    var firstDate = new Date(firstElement.date);
    var lastDate = new Date(lastElement.date);
    var minDate = isAfter(firstDate, lastDate) ? lastDate : firstDate;
    var daysDifference = differenceInDays(new Date(), minDate);
    var ranges = [RangeTypeEnum['7D']];
    if (daysDifference > 7) {
        ranges = __spreadArray(__spreadArray([], ranges, true), [RangeTypeEnum['14D']], false);
        if (daysDifference > 14) {
            ranges = __spreadArray(__spreadArray([], ranges, true), [RangeTypeEnum['30D']], false);
            if (daysDifference > 30) {
                ranges = __spreadArray(__spreadArray([], ranges, true), [RangeTypeEnum['90D']], false);
                if (daysDifference > 90) {
                    ranges = __spreadArray(__spreadArray([], ranges, true), [RangeTypeEnum['180D']], false);
                    if (daysDifference > 180) {
                        ranges = __spreadArray(__spreadArray([], ranges, true), [RangeTypeEnum['1Y']], false);
                    }
                }
            }
        }
    }
    ranges = __spreadArray(__spreadArray([], ranges, true), [RangeTypeEnum['MAX']], false);
    return createRangeOptions(ranges);
};
