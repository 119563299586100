import React from 'react';
import clsx from 'clsx';
// Styles
import './vault-tab.scss';
export const VaultTab = ({ title, onClick, active = false }) => {
    return (React.createElement("div", { className: clsx('numisme-vault-tab', {
            'numisme-vault-tab-active': active,
            clickable: !!onClick
        }), onClick: onClick },
        React.createElement("span", null, title)));
};
