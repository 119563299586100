var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMemo } from 'react';
// Static
import { NFT_TYPES } from '../static/chart.static';
// Utils
import { reverse } from '../utils';
export var useCapsuleAnalytics = function (metrics) {
    return useMemo(function () {
        if (!metrics) {
            return {
                listedNfts: [],
                openBidsNfts: [],
                totalSold: []
            };
        }
        var listedNfts = metrics.listedByTypeHistorical.map(function (historicalValue) {
            return __assign({ date: historicalValue.date }, historicalValue.value);
        });
        var openBidsNfts = metrics.bidsByTypeHistorical.map(function (historicalValue) {
            return __assign({ date: historicalValue.date }, historicalValue.value);
        });
        var totalSold = [
            {
                category: NFT_TYPES.ORIGIN,
                value: metrics.soldByType.ORIGIN,
                percentage: metrics.soldByTypePercentage.ORIGIN
            },
            {
                category: NFT_TYPES.DRIFT,
                value: metrics.soldByType.DRIFT,
                percentage: metrics.soldByTypePercentage.DRIFT
            },
            {
                category: NFT_TYPES.KEY,
                value: metrics.soldByType.KEY,
                percentage: metrics.soldByTypePercentage.KEY
            },
            {
                category: NFT_TYPES.CAPSULE,
                value: metrics.soldByType.CAPSULE,
                percentage: metrics.soldByTypePercentage.CAPSULE
            },
            {
                category: NFT_TYPES.PERK,
                value: metrics.soldByType.PERK,
                percentage: metrics.soldByTypePercentage.PERK
            },
            {
                category: NFT_TYPES.ARTWORK,
                value: metrics.soldByType.ARTWORK,
                percentage: metrics.soldByTypePercentage.ARTWORK
            }
        ];
        return {
            listedNfts: reverse(listedNfts),
            openBidsNfts: reverse(openBidsNfts),
            totalSold: totalSold
        };
    }, [metrics]);
};
