var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ethers } from 'ethers';
import { useCallback } from 'react';
import { useWeb3Context } from '../contexts/web3.context';
import { approveTransaction, getUserLockInfo, increaseLockAmount, lockTokens } from '../utils/helpers';
import { V2_ADDRESS } from '../../constants';
export const useTokenLock = () => {
    const { provider } = useWeb3Context();
    const lock = useCallback((lockerAddress, amount) => __awaiter(void 0, void 0, void 0, function* () {
        if (!provider) {
            return;
        }
        try {
            const signer = yield provider.getSigner();
            const address = yield signer.getAddress();
            const weiAmount = ethers.utils.parseEther(amount);
            yield approveTransaction(signer, weiAmount, lockerAddress, V2_ADDRESS);
            const { amount: lockedAmount } = yield getUserLockInfo(provider, address, lockerAddress);
            if (lockedAmount.isZero()) {
                yield lockTokens(signer, weiAmount, lockerAddress);
                return;
            }
            yield increaseLockAmount(signer, weiAmount, lockerAddress);
        }
        catch (error) {
            throw error;
        }
    }), [provider]);
    return {
        lock
    };
};
