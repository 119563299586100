import { getDateDiff, isBetweenPeriod, isSameOfAfter, isToday, subDate } from './time';
/**
 * A map of date filter utils for each used range.
 */
var DATE_RANGE_FILTERS = {
    max: function () { return true; },
    daily: function (date) { return isBetweenPeriod(new Date(date), 'day'); },
    monthly: function (date) { return isBetweenPeriod(new Date(date), 'month'); },
    weekly: function (date) { return isBetweenPeriod(new Date(date), 'week'); },
    '24h': function (date) { return isToday(new Date(date)); },
    '7d': function (date) { return isSameOfAfter(new Date(date), subDate(new Date(), 1, 'week')); },
    '14d': function (date) {
        return isSameOfAfter(new Date(date), subDate(new Date(), 2, 'week'));
    },
    '30d': function (date) {
        return isSameOfAfter(new Date(date), subDate(new Date(), 1, 'month'));
    },
    '90d': function (date) {
        return isSameOfAfter(new Date(date), subDate(new Date(), 3, 'month'));
    },
    '180d': function (date) {
        return isSameOfAfter(new Date(date), subDate(new Date(), 6, 'month'));
    },
    '1y': function (date) { return isSameOfAfter(new Date(date), subDate(new Date(), 1, 'year')); }
};
/**
 * Filters the given row using the provided callback function.
 * @param {any} row
 * @param {function} fn - A callback function to filter the row by.
 * @returns Should row be filtered boolean.
 */
var filterDate = function (row, fn) {
    return row.date && fn ? fn(row.date) : false;
};
/**
 * Filters the given row by the date range and the provided keys.
 * @param {any} row
 * @param {string} range - A given range value that can be found in src/utils/filters.js
 * @param {string[]} keys - An optional parameter used to additionally filter the data by the keys that hold the value. If passed, the function will check if the row has a value greated than 0 for at least one of the given keys.
 * @returns Should row be filtered boolean.
 */
var filterRow = function (row, range) {
    return filterDate(row, DATE_RANGE_FILTERS[range]);
};
/**
 * A util function used to parse the given data by range.
 * @param {string} range - A given range value that can be found in src/utils/filters.js
 * @param {any[]} data - Array of data that should be parsed by range.
 * @returns Parsed chart data.
 */
export function parseRangeChart(range, data) {
    if (data === void 0) { data = []; }
    switch (range) {
        case '24h':
        case '7d':
        case '14d':
        case '30d':
        case '90d':
        case '1y':
        case 'daily':
        case 'weekly':
        case 'monthly':
            return data.filter(function (row) { return filterRow(row, range); });
        case 'max':
        default:
            return data;
    }
}
/**
 * Merges Liquidations Tokens data to an array consistent to the pie chart component
 * @param input - data to merge
 */
export var getDepositBorrowCharts = function (input) {
    var depositChart = [];
    var borrowChart = [];
    var keys = [];
    var earliestDateUpdated = null;
    input.forEach(function (_a) {
        var distribution_deposits = _a.distribution_deposits, distribution_borrows = _a.distribution_borrows, borrows = _a.borrows, deposits = _a.deposits, name = _a.name, dateUpdated = _a.dateUpdated;
        depositChart.push({
            key: name,
            weight: distribution_deposits,
            value: deposits
        });
        borrowChart.push({
            key: name,
            weight: distribution_borrows,
            value: borrows
        });
        keys.push(name);
        if (!earliestDateUpdated ||
            getDateDiff(earliestDateUpdated, dateUpdated, 'milliseconds') < 0) {
            earliestDateUpdated = dateUpdated;
        }
    });
    return { depositChart: depositChart, borrowChart: borrowChart, keys: keys, updated: earliestDateUpdated };
};
