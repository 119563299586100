import { FiChevronDown } from 'react-icons/fi'
import { useState } from 'react'

export default function CodeAccordion(props) {
  const [open, onOpen] = useState(props.defaultOpen || false)
  return (
    <div className="accordion">
      <div className="accordion__summary" onClick={() => onOpen(!open)}>
        <span>{props.title || 'View Code'}</span>
        <FiChevronDown />
      </div>

      {open && <div className="accordion__content">{props.code}</div>}
    </div>
  )
}
