// Libs
import React, { useMemo } from 'react';
import { Legends, PieChart } from '@multifarm/widget-components';
// Components
import { Card } from '../Card';
// Hooks
import { useChartColors } from '../../hooks/useChartColors';
// Utils
import { getRalativeFormat } from '../../utils/time';
// Styles
import s from './PieChartCard.module.css';
var CardHeader = function (_a) {
    var title = _a.title;
    return React.createElement("div", { className: s.headerTitle }, title);
};
var ChartDetails = function (_a) {
    var details = _a.details, className = _a.className;
    return React.createElement("div", { className: className }, details);
};
export var PieChartCard = function (_a) {
    var title = _a.title, data = _a.data, details = _a.details, updatedAt = _a.updatedAt, detailsClassName = _a.detailsClassName, legends = _a.legends, legendsClassName = _a.legendsClassName, categoryKey = _a.categoryKey, formatCategory = _a.formatCategory, formatTooltipValue = _a.formatTooltipValue, formatTooltipKey = _a.formatTooltipKey, tooltipKeys = _a.tooltipKeys, _b = _a.dataKey, dataKey = _b === void 0 ? 'value' : _b, _c = _a.isLoading, isLoading = _c === void 0 ? false : _c;
    var colors = useChartColors(data.length);
    var dateDiff = useMemo(function () {
        if (!updatedAt) {
            return null;
        }
        return getRalativeFormat(new Date(), updatedAt);
    }, [updatedAt]);
    var tooltipOptions = useMemo(function () { return ({
        categoryKey: categoryKey,
        tooltipKeys: tooltipKeys,
        formatTooltipValue: formatTooltipValue,
        formatTooltipKey: formatTooltipKey,
        formatCategory: formatCategory
    }); }, [
        categoryKey,
        tooltipKeys,
        formatTooltipValue,
        formatTooltipKey,
        formatCategory
    ]);
    return (React.createElement(Card, { titleComponent: React.createElement(CardHeader, { title: title }), footerComponent: updatedAt && "Last updated ".concat(dateDiff) },
        React.createElement(PieChart, { className: s.chart, colors: colors, data: data, dataKey: dataKey, isLoading: isLoading, tooltipOptions: tooltipOptions, stroke: 0, details: details && (React.createElement(ChartDetails, { className: detailsClassName, details: details })) }),
        !!(legends === null || legends === void 0 ? void 0 : legends.length) && (React.createElement(Legends, { className: legendsClassName, legends: legends, colors: colors }))));
};
