import React from 'react';
import cx from 'clsx';
import { Card, InfoTooltip } from '@multifarm/widget-components';
// Styles
import './info-card.scss';
export var InfoCard = function (_a) {
    var title = _a.title, titleTooltip = _a.titleTooltip, body = _a.body, footer = _a.footer, className = _a.className;
    var titleComponent;
    if (titleTooltip) {
        titleComponent = React.createElement(InfoTooltip, { content: titleTooltip });
    }
    return (React.createElement(Card, { title: title, titleComponent: titleComponent, className: cx('info-card', className) },
        body && React.createElement("div", { className: "info-card-body" }, body),
        footer && React.createElement("div", { className: "info-card-footer" }, footer)));
};
