import { useMemo } from 'react';
// Hooks
import { useUserLockInfo } from './useUserLockInfo';
// Static
import { LOCKER_12_ADDRESS, LOCKER_3_ADDRESS, LOCKER_6_ADDRESS } from '../../constants';
/**
 * Gets user's info for every lock time and retruns the max.
 * @returns
 */
export const useMaxUserLockInfo = () => {
    const { userLockInfo: threeMLockInfo } = useUserLockInfo(LOCKER_3_ADDRESS);
    const { userLockInfo: sixMLockInfo } = useUserLockInfo(LOCKER_6_ADDRESS);
    const { userLockInfo: twelveMLockInfo } = useUserLockInfo(LOCKER_12_ADDRESS);
    const maxLockInfo = useMemo(() => {
        const threeMTime = (threeMLockInfo === null || threeMLockInfo === void 0 ? void 0 : threeMLockInfo.unlockTime.toNumber()) || Number.MIN_VALUE;
        const sixMTime = (sixMLockInfo === null || sixMLockInfo === void 0 ? void 0 : sixMLockInfo.unlockTime.toNumber()) || Number.MIN_VALUE;
        const twelveMTime = (twelveMLockInfo === null || twelveMLockInfo === void 0 ? void 0 : twelveMLockInfo.unlockTime.toNumber()) || Number.MIN_VALUE;
        if (threeMTime > sixMTime && threeMTime > twelveMTime) {
            return threeMLockInfo;
        }
        if (sixMTime > threeMTime && sixMTime > twelveMTime) {
            return sixMLockInfo;
        }
        if (twelveMTime > threeMTime && twelveMTime > sixMTime) {
            return twelveMLockInfo;
        }
        return twelveMLockInfo;
    }, [threeMLockInfo, sixMLockInfo, twelveMLockInfo]);
    return { maxLockInfo };
};
