import React from 'react';
import { PieChart, DataTable, LoadingPlaceholder, NoDataPlaceholder, InfoTooltip } from '@multifarm/widget-components';
// Styles
import './pie-chart-table.scss';
export var PieChartTable = function (_a) {
    var data = _a.data, chartDataKey = _a.chartDataKey, noDataPlaceholder = _a.noDataPlaceholder, innerRadius = _a.innerRadius, outerRadius = _a.outerRadius, tableColumns = _a.tableColumns, colors = _a.colors, isLoading = _a.isLoading, _b = _a.initialHeight, initialHeight = _b === void 0 ? 200 : _b, tooltipOptions = _a.tooltipOptions, title = _a.title, hint = _a.hint;
    if (isLoading) {
        return React.createElement(LoadingPlaceholder, { minHeight: initialHeight });
    }
    if (!data.length) {
        return (React.createElement(NoDataPlaceholder, { minHeight: initialHeight, text: noDataPlaceholder }));
    }
    return (React.createElement("div", { className: "pie-chart-table" },
        title && (React.createElement("div", { className: "pie-chart-table__title" },
            title,
            hint && (React.createElement(InfoTooltip, { triggerClassName: "pie-chart-table__info-trigger", content: hint })))),
        React.createElement("div", { className: "pie-chart-table__content" },
            React.createElement(PieChart, { data: data, dataKey: chartDataKey, colors: colors, initialHeight: initialHeight, innerRadius: innerRadius, outerRadius: outerRadius, tooltipOptions: tooltipOptions }),
            React.createElement(DataTable, { data: data, columns: tableColumns, colors: colors }))));
};
