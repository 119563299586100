// Static
import { APP_COLOURS } from '../static/app.static';
/**
 * Checks is value empty, null or undefined.
 * If value is array, checks if the length of the array is greater than zero.
 * @param value - Value to be checked.
 * @returns Is value empty boolean.
 */
export const isEmpty = (value) => {
    if (value === null || value === undefined) {
        return true;
    }
    // Validate the lenght of array.
    if (Array.isArray(value)) {
        return value.length === 0;
    }
    // Check if object has any key defined
    if (typeof value === 'object') {
        const keys = Object.keys(value);
        if (keys.length === 0)
            return true;
    }
    return false;
};
/**
 * @param array - Array to reverse.
 * @returns Reversed array.
 */
export const reverse = (array) => {
    return array.slice().reverse();
};
/**
 * @param numbers
 * @returns The max number from the given array of numbers.
 */
export const getMaxNumber = (numbers) => {
    let max = Number.MIN_VALUE;
    for (const number of numbers) {
        if (!max || max < number) {
            max = number;
        }
    }
    return max;
};
/**
 * @returns A random application's colour.
 */
export const getRandomColour = () => {
    const coloursCount = APP_COLOURS.length;
    return APP_COLOURS[Math.floor(Math.random() * (coloursCount - 1))];
};
