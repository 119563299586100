import React, { useState, useMemo } from 'react';
// Components
import { TreasuryTabs } from '../components/treasury/TreasuryTabs';
import { BlockchainDistribution } from '../components/treasury/BlockchainDistribution';
import { HybridLineChart } from '../components/hybridLineChart';
// Hooks - API
import { useInvestmentBalance, useBlockchainDistribution, usePoolsByField } from '../hooks/api';
// Utils
import { isEmpty, reverse } from '../common/utils/global.utils';
// Static
import { DAY_RANGES } from '../common/static';
// Assets TODO: Remove images from the package.
import { LogoHorzIcon } from '../assets/icons';
export const Treasury = () => {
    const [selectedTab, setSelectedTab] = useState('Total');
    // Data fetching
    const { data: investmentBalance } = useInvestmentBalance();
    const { data: blockchainDistribution } = useBlockchainDistribution({
        show_usd: true
    });
    const { data: pools, isLoading } = usePoolsByField({
        field: 'blockchain',
        value: Object.keys(blockchainDistribution),
        per_page: 1000
    });
    /**
     * A memo to cache the chart data.
     */
    const chartData = useMemo(() => investmentBalance.portfolio
        ? reverse(investmentBalance.portfolio.net_worth_historical)
        : [], [investmentBalance]);
    const poolsByBlockchain = useMemo(() => {
        const poolsMap = {};
        if (isEmpty(pools)) {
            return {};
        }
        for (const pool of pools) {
            const initialValue = poolsMap[pool.blockchain] || [];
            poolsMap[pool.blockchain] = [...initialValue, pool];
        }
        return poolsMap;
    }, [pools]);
    const renderSelectedTab = () => {
        if (selectedTab === 'Total') {
            return (React.createElement("div", { className: "treasury-tabs_tab-pane w-tab-pane w--tab-active" },
                React.createElement(HybridLineChart, { isLoading: isLoading, data: chartData, defaultRange: "30d", ranges: DAY_RANGES })));
        }
        return (React.createElement(BlockchainDistribution, { key: `distro-${selectedTab}`, blockchain: selectedTab, blockchainShare: blockchainDistribution[selectedTab], pools: poolsByBlockchain[selectedTab] || [] }));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("header", { className: "section_header darkest" },
            React.createElement("div", { className: "page-padding" },
                React.createElement("div", { className: "container-small" },
                    React.createElement("div", { className: "padding-vertical padding-huge" },
                        React.createElement("div", { "data-w-id": "b697fa6f-e63f-c47c-6eb6-8487e684c3ee", className: "text-align-center" },
                            React.createElement("div", { className: "margin-bottom margin-small" },
                                React.createElement(LogoHorzIcon, { width: 28, height: 38 }),
                                React.createElement("h1", null, "Treasury")),
                            React.createElement("div", { className: "divider light" }),
                            React.createElement("p", { className: "text-size-medium" }, "The treasury\u2019s purpose is providing growth for our investors. Here you can monitor treasury health and view our active deployments."),
                            React.createElement("div", { className: "divider light" })))))),
        React.createElement("section", { className: "section_treasury-content" },
            React.createElement("div", { className: "page-padding" },
                React.createElement("div", { className: "container-large padding-bottom padding-xxhuge" },
                    React.createElement("div", { className: "treasury-tabs w-tabs" },
                        React.createElement(TreasuryTabs, { selectedTab: selectedTab, blockchainDistribution: blockchainDistribution, onChange: setSelectedTab }),
                        React.createElement("div", { className: "treasure-tabs_content w-tab-content" }, renderSelectedTab())))))));
};
