import { MultifarmProvider, Proteus } from '@multifarm/widget'
import { useThemeContext } from '../providers/ThemeProvider'
import { TOKEN } from '../config'
import Content from '../components/Content'
import ProteusContent from '../content/proteus.md'

export default function ProteusPage() {
  const { theme, themeColors } = useThemeContext()
  return (
    <div className="flex flex-col w-full">
      <Content content={ProteusContent} />

      <MultifarmProvider
        token={TOKEN}
        theme={theme}
        provider={theme}
        themeColors={themeColors}
        key={theme}
        badgePlacement="bottom"
      >
        <Proteus />
      </MultifarmProvider>
    </div>
  )
}
