var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
// Context
import { useWeb3Context } from '../../web3/contexts/web3.context';
// Utils
import { getNetworkIcon, getNetworkName, shortenAddress } from '../../common/utils';
// Static
import { hybridPages, PageEnum } from '../../common/static/app.static';
// Icons
import { HybridFinanceIcon } from '../../assets/icons';
// Styles
import './HybridHeader.css';
export const HybridHeader = ({ currentPage, onChange }) => {
    const { connect, connected, provider, chain, disconnect } = useWeb3Context();
    const [connectionDetails, setConnectionDetails] = useState(null);
    const [disconnectOption, showDisconnectOption] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    /**
     * Hook for updating connection details.
     */
    useEffect(() => {
        /**
         * Retrieves connection details from the provider.
         */
        const getConnectionDetails = () => __awaiter(void 0, void 0, void 0, function* () {
            if (!provider || !connected)
                return;
            const [address, network] = yield Promise.all([
                provider.getSigner().getAddress(),
                provider.getNetwork()
            ]);
            setConnectionDetails({
                address,
                network
            });
        });
        getConnectionDetails();
    }, [provider, connected, chain]);
    const [NetworkIcon, networkInfo] = useMemo(() => {
        if (!connectionDetails)
            return [];
        return [
            getNetworkIcon(connectionDetails.network.chainId),
            `${getNetworkName(connectionDetails.network)}  ${shortenAddress(connectionDetails.address)}`
        ];
    }, [connectionDetails]);
    return (React.createElement("div", { "data-collapse": "medium", "data-animation": "default", "data-duration": "400", "data-w-id": "6b78ba3f-f5ea-b049-7adb-a6bbcc0135ed", "data-easing": "ease", "data-easing2": "ease", role: "banner", className: "navbar1_component shadow-xlarge w-nav" },
        React.createElement("div", { className: "navbar1_container" },
            React.createElement("button", { onClick: () => onChange(PageEnum.DASHBOARD), "aria-current": "page", className: "navbar1_logo-link w-nav-brand" },
                React.createElement(HybridFinanceIcon, { width: 173, height: 54, className: "navbar1_logo" })),
            React.createElement("nav", { role: "navigation", className: clsx('navbar1_menu w-nav-menu', {
                    ['w-nav-menu--open']: isOpen
                }) },
                hybridPages.map((page, index) => (React.createElement("button", { key: index, onClick: () => {
                        onChange(page);
                        setIsOpen(false);
                    }, className: `navbar1_link w-nav-link ${currentPage == page && 'active-link'} ` }, page.toString()))),
                React.createElement("div", { onClick: () => {
                        if (connected) {
                            showDisconnectOption(!disconnectOption);
                            return;
                        }
                        connect();
                    }, className: 'wallet-connect-button navbar1_link w-nav-link' }, !connected || !connectionDetails ? ('Connect Wallet') : (React.createElement(React.Fragment, null,
                    React.createElement(NetworkIcon, null),
                    networkInfo,
                    React.createElement("div", { className: clsx('wallet-disconnect-option', {
                            active: disconnectOption
                        }), onClick: () => disconnect() }, "Disconnect"))))),
            React.createElement("button", { type: "button", className: clsx('navbar1_menu-button w-nav-button', {
                    ['w--open']: isOpen
                }), onClick: () => setIsOpen((prev) => !prev) },
                React.createElement("div", { className: "menu-icon1" },
                    React.createElement("div", { className: "menu-icon1_line-top" }),
                    React.createElement("div", { className: "menu-icon1_line-middle" },
                        React.createElement("div", { className: "menu-icon_line-middle-inner" })),
                    React.createElement("div", { className: "menu-icon1_line-bottom" }))))));
};
