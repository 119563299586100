import Content from '../components/Content'
import ThemesContent from '../content/themes.md'
import CodeAccordion from '../components/CodeAccordion'
import Code from '../components/Code'
import {
  DEFAULT,
  OLYMPUS,
  VESQ,
  REFI,
  EXPO,
  FOOD_FARMER,
  DIGITS,
  PARAGONS,
  BALANCER,
  SPHERE,
  OSMOSIS
} from './themes/code'

export default function Themes() {
  return (
    <div className="flex flex-col w-full">
      <Content content={ThemesContent} />

      <CodeAccordion title="Default Theme" code={<Code>{DEFAULT}</Code>} />
      <CodeAccordion
        title="Olympus Theme (light & dark)"
        code={<Code>{OLYMPUS}</Code>}
      />
      <CodeAccordion title="Vesq Theme" code={<Code>{VESQ}</Code>} />
      <CodeAccordion
        title="ReFi Theme (light & dark)"
        code={<Code>{REFI}</Code>}
      />
      <CodeAccordion title="Expo Theme" code={<Code>{EXPO}</Code>} />
      <CodeAccordion
        title="Food-Farmer Theme"
        code={<Code>{FOOD_FARMER}</Code>}
      />
      <CodeAccordion title="Digits Theme" code={<Code>{DIGITS}</Code>} />
      <CodeAccordion title="Paragons Theme" code={<Code>{PARAGONS}</Code>} />
      <CodeAccordion title="Balancer Theme" code={<Code>{BALANCER}</Code>} />
      <CodeAccordion title="Sphere Theme" code={<Code>{SPHERE}</Code>} />
      <CodeAccordion title="Osmosis Theme" code={<Code>{OSMOSIS}</Code>} />
    </div>
  )
}
