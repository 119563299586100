export var PageEnum;
(function (PageEnum) {
    PageEnum["CAPSULE_ANALYTICS"] = "Capsule Analytics";
    PageEnum["GAMELOOP_STATS"] = "Gameloop Stats";
})(PageEnum || (PageEnum = {}));
export var SortOrderEnum;
(function (SortOrderEnum) {
    SortOrderEnum["ASC"] = "asc";
    SortOrderEnum["DESC"] = "desc";
})(SortOrderEnum || (SortOrderEnum = {}));
