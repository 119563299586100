export var HybridMetricsEnum;
(function (HybridMetricsEnum) {
    HybridMetricsEnum["CURRENT_PRICE"] = "cp";
    HybridMetricsEnum["INFINITY_POOL_VALUE"] = "ipv";
    HybridMetricsEnum["TOKEN_HOLDERS"] = "th";
    HybridMetricsEnum["TREASURY_VALUE"] = "tv";
})(HybridMetricsEnum || (HybridMetricsEnum = {}));
export const HybridMetricsChartMap = {
    [HybridMetricsEnum.CURRENT_PRICE]: 'price_usd_historical',
    [HybridMetricsEnum.INFINITY_POOL_VALUE]: 'infinity_pool_value_historical',
    [HybridMetricsEnum.TOKEN_HOLDERS]: 'total_holders_historical',
    [HybridMetricsEnum.TREASURY_VALUE]: 'treasury_value_historical'
};
