import React, { useState } from 'react';
import { Select } from '@multifarm/widget-components';
// Components
import { AssetAllocationOverview } from './components/AssetAllocationOverview';
import { DepositBorrowCharts } from './components/DepositBorrowCharts';
import { CollateralDebtStatistics } from './components/CollateralDebtStatistics';
import { LiquidationVolumeChart } from './components/LiquidationVolumeChart';
import { LiquidationOverviewChart } from './components/LiquidationOverviewChart';
// Styles
import s from './overview.module.css';
var OPTIONS = [
    { label: 'Realm', value: 'Realm' },
    { label: 'Nether', value: 'Nether' }
];
export var Overview = function () {
    var _a = useState('Realm'), selectedValue = _a[0], setSelectedValue = _a[1];
    return (React.createElement("div", { className: s.overview },
        React.createElement("div", { className: s.overviewHeader },
            React.createElement("h2", { className: s.overviewHeaderTitle }, "Overview"),
            React.createElement("p", { className: s.overviewHeaderSubtitle }, "State of the platform overview")),
        React.createElement("div", null,
            React.createElement(Select, { className: s.bastionSelect, options: OPTIONS, value: selectedValue, onChange: setSelectedValue }),
            React.createElement(DepositBorrowCharts, null),
            React.createElement(CollateralDebtStatistics, null),
            React.createElement(LiquidationOverviewChart, null),
            React.createElement(LiquidationVolumeChart, null),
            React.createElement(AssetAllocationOverview, null))));
};
