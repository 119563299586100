import React, { useMemo } from 'react';
import { getDifferenceInStringFormat } from '../../common/utils';
import { useUserLockInfo } from '../../web3/hooks/useUserLockInfo';
import { InvestmentMetric } from '../InvestmentMetric/InvestmentMetric';
export const LockerStats = ({ lockerAddress, period }) => {
    const { isLoading, userLockInfo } = useUserLockInfo(lockerAddress);
    const dateDifferenceString = useMemo(() => {
        if (!(userLockInfo === null || userLockInfo === void 0 ? void 0 : userLockInfo.unlockTime)) {
            return null;
        }
        return getDifferenceInStringFormat(new Date(userLockInfo.unlockTime.toNumber() * 1000));
    }, [userLockInfo === null || userLockInfo === void 0 ? void 0 : userLockInfo.unlockTime]);
    if (isLoading || !userLockInfo) {
        return null;
    }
    if (userLockInfo.amount.isZero()) {
        return null;
    }
    return (React.createElement("tr", null,
        React.createElement("td", { className: "metric dark" }, period),
        React.createElement("td", null,
            React.createElement(InvestmentMetric, { className: "metric dark", value: userLockInfo.amount })),
        React.createElement("td", { className: "metric dark" }, dateDifferenceString)));
};
