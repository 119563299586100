// Model
import { NODE_NAME_ANALYTICS_MAP } from '../../static';
var ThorAnalytics = /** @class */ (function () {
    function ThorAnalytics(nodes) {
        this.originNodes = {};
        this.driftNodes = {};
        this.keycardNodes = {};
        this.capsuleNodes = {};
        this.perkNodes = {};
        this.totalNodes = {};
        for (var i = 0; i < nodes.length; i++) {
            var node = nodes[i];
            var analyticsPath = NODE_NAME_ANALYTICS_MAP.get(node.name);
            if (!analyticsPath) {
                continue;
            }
            this[analyticsPath.base][analyticsPath.category] = node;
        }
    }
    return ThorAnalytics;
}());
export { ThorAnalytics };
