import React from 'react';
import { App } from './App';
import { Web3ContextProvider } from './web3/contexts/web3.context';
const defaultConfig = {
    locker: {
        unlocked: null,
        message: 'No Locking Period Open'
    }
};
export const HybridApp = ({ config = defaultConfig }) => (React.createElement(Web3ContextProvider, { config: config },
    React.createElement(App, null)));
