import React, { useState } from 'react';
import { VaultTabEnum } from '../../../../../common/static';
// Components
import { BorrowTab, DepositTab, RepayTab, VaultTabs, WithdrawTab } from './tabs';
// Styles
import './vault-body.scss';
export const TAB_COMPONENTS = {
    [VaultTabEnum.DEPOSIT]: DepositTab,
    [VaultTabEnum.WITHDRAW]: WithdrawTab,
    [VaultTabEnum.BORROW]: BorrowTab,
    [VaultTabEnum.REPAY]: RepayTab
};
export const VaultBody = () => {
    const [tab, setTab] = useState(VaultTabEnum.DEPOSIT);
    const Tab = TAB_COMPONENTS[tab];
    return (React.createElement("div", { className: "numisme-vault-body" },
        React.createElement(VaultTabs, { activeTab: tab, setTab: setTab }),
        React.createElement(Tab, null)));
};
