var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useMemo } from 'react';
// Fragments
import { NftCards } from './fragments';
// Context
import { useSolanaRealmsContext } from '../../context/solana-realms.context';
// Icons
import { ChevronRightIcon } from '../../assets/icons';
// Styles
import './nft-view.scss';
// Utils
import { formatMoney, getShortWalletAddress } from '../../common/utils';
export var NftView = function () {
    var _a = useSolanaRealmsContext(), overviewState = _a.overviewState, setOverviewState = _a.setOverviewState, aggregatedNftState = _a.aggregatedNftState;
    var goBack = useCallback(function () {
        if (overviewState.nftCollection) {
            setOverviewState(__assign(__assign({}, overviewState), { nftCollection: undefined, walletAddress: overviewState.nftCollections
                    ? overviewState.walletAddress
                    : undefined }));
            return;
        }
        setOverviewState(__assign(__assign({}, overviewState), { nftCollections: undefined, walletAddress: undefined }));
    }, [overviewState]);
    /**
     * Memorising NFT cards to render.
     */
    var nftCards = useMemo(function () {
        if (!overviewState.nftCollection) {
            return [];
        }
        return overviewState.nftCollection.nfts.map(function (nft) { return ({
            name: nft.asset,
            url: nft.imageUrl,
            collectionUrl: nft.collectionImageUrl,
            details: formatMoney(nft.positionSizeUsd)
        }); });
    }, [overviewState.nftCollection]);
    /**
     * Memorising NFT collection cards to render.
     */
    var nftCollectionCards = useMemo(function () {
        var collections = [];
        if (overviewState.nftCollections &&
            overviewState.nftCollections.length > 0) {
            collections = overviewState.nftCollections;
        }
        if (!overviewState.nftCollections) {
            collections = aggregatedNftState.aggregatedCollections;
        }
        return collections.map(function (collection) { return ({
            name: collection.name,
            url: collection.imageUrl,
            details: "".concat(collection.nftsCount, " NFT").concat(collection.nftsCount > 0 && 's'),
            onClick: function () {
                return setOverviewState(__assign(__assign({}, overviewState), { nftCollection: collection }));
            }
        }); });
    }, [overviewState]);
    /**
     * Memorising the cards to render based on the loaded state.
     */
    var cards = useMemo(function () {
        if (overviewState.nftCollection) {
            return nftCards;
        }
        return nftCollectionCards;
    }, [overviewState, nftCards, nftCollectionCards]);
    return (React.createElement("div", { className: "multifarm-nft-view-page" },
        React.createElement("div", { className: "multifarm-nft-view-page__header" },
            (overviewState.nftCollections ||
                overviewState.nftCollection ||
                overviewState.walletAddress) && (React.createElement("div", { className: "multifarm-nft-view-page__header-back-action clickable", onClick: goBack },
                React.createElement(ChevronRightIcon, { className: "multifarm-nft-view-page__header-back-action-icon" }),
                ' ',
                "Back")),
            React.createElement("div", { className: "multifarm-nft-view-page__header-collection" },
                overviewState.nftCollection ? (React.createElement("span", null,
                    overviewState.nftCollection.name,
                    " NFT Collection")) : (React.createElement("span", null,
                    !overviewState.walletAddress && 'Agregated',
                    " NFT Collections")),
                overviewState.walletAddress && (React.createElement("span", null,
                    " - ",
                    getShortWalletAddress(overviewState.walletAddress))))),
        React.createElement(NftCards, { cards: cards })));
};
