import React, { useMemo } from 'react';
import { Card, MultipleDomainChart } from '@multifarm/widget-components';
// Fragments
import { PerksChartHeader } from './header';
// Static
import { PERKS_CHART } from '../../../../../common/static/chart.static';
// Utils
import { formatDate, formatNumber, mergeHistoricalChartData, reverse } from '../../../../../common/utils';
// Static
import { CHART_TOOLTIP_DATE_PRIMARY } from '../../../../../common/static';
// Styles
import './perks-chart.scss';
export var PerksChart = function (_a) {
    var analytics = _a.analytics;
    var chart = useMemo(function () {
        var _a, _b, _c, _d;
        return mergeHistoricalChartData({
            charts: [
                {
                    name: 'Total Perks Applied',
                    data: reverse(((_a = analytics.totalNodes.total) === null || _a === void 0 ? void 0 : _a.perksAppliedTotalHistorical) || [])
                },
                {
                    name: 'Perks Applied Delta',
                    data: reverse(((_b = analytics.totalNodes.total) === null || _b === void 0 ? void 0 : _b.perksAppliedDeltaHistorical) || [])
                },
                {
                    name: 'Perks Applied Sigma',
                    data: reverse(((_c = analytics.totalNodes.total) === null || _c === void 0 ? void 0 : _c.perksAppliedSigmaHistorical) || [])
                },
                {
                    name: 'Perks Applied Gamma',
                    data: reverse(((_d = analytics.totalNodes.total) === null || _d === void 0 ? void 0 : _d.perksAppliedGammaHistorical) || [])
                }
            ]
        });
    }, [analytics]);
    return (React.createElement(Card, { className: "multifarm-thor-finance-card multifarm-thor-finance-perks-chart-card borderless-grid", title: "TOTAL PERKS APPLIED TO NODES", headerComponent: React.createElement(PerksChartHeader, { chartKeys: chart.chartKeys, chartColours: PERKS_CHART.COLORS }) },
        React.createElement(MultipleDomainChart, { data: chart.chartData, chartOptions: {
                grid: { enable: true, opacity: 0.3 },
                leftYAxis: {
                    width: 80,
                    label: {
                        value: 'Daily',
                        position: 'insideLeft',
                        angle: -90,
                        styles: { fontSize: '20px' }
                    }
                },
                rightYAxis: {
                    width: 80,
                    label: {
                        value: 'Cumulative',
                        position: 'insideRight',
                        angle: 90,
                        styles: { fontSize: '20px' }
                    }
                }
            }, leftYTickFormatter: function (tick) { return formatNumber(tick, 2, true); }, rightYTickFormatter: function (tick) { return formatNumber(tick, 2, true); }, dataOptions: {
                line: {
                    keys: chart.chartKeys.length > 0 ? ['Total Perks Applied'] : [],
                    colors: ['#f3523f'],
                    yAxisId: 'right'
                },
                bar: {
                    keys: chart.chartKeys.length > 0
                        ? [
                            'Perks Applied Delta',
                            'Perks Applied Sigma',
                            'Perks Applied Gamma'
                        ]
                        : [],
                    colors: ['#F99D2A', '#e2e729', '#5e5e5e'],
                    yAxisId: 'left',
                    stack: 1
                }
            }, xTickFormatter: function (tick) { return formatDate(tick); }, tooltipOptions: {
                formatTooltipValue: function (tick) {
                    return tick.toLocaleString('en', {
                        maximumFractionDigits: 2
                    });
                },
                formatCategory: function (tick) { return formatDate(tick, CHART_TOOLTIP_DATE_PRIMARY); }
            }, barOptions: { maxBarSize: 24 } })));
};
