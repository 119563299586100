import React, { useMemo } from 'react';
// Hooks - API
import { useThorNodes } from '../../common/hooks/api';
// Models
import { ThorAnalytics } from '../../common/models/domain';
// Fragments
import { StatisticsCards, NodesChart, DistributionCharts, PerksChart } from './fragments';
export var GameloopStats = function () {
    var nodes = useThorNodes().data;
    /**
     * Memorising the analytics based on nodes.
     */
    var thorAnalytics = useMemo(function () {
        return new ThorAnalytics(nodes);
    }, [nodes]);
    return (React.createElement("div", null,
        React.createElement(StatisticsCards, { analytics: thorAnalytics }),
        React.createElement(NodesChart, { analytics: thorAnalytics }),
        React.createElement(DistributionCharts, { analytics: thorAnalytics }),
        React.createElement(PerksChart, { analytics: thorAnalytics })));
};
