import React from 'react';
export const DepositBackCard = () => (React.createElement(React.Fragment, null,
    React.createElement("p", null, "Deposit USD+ or USDC to mint HFI. There is a 15% deposit fee after which your HFI tokens will be deposited into your wallet. HFI is built to never depreciate as it is backed by USDC and other blue-chip stable coins. As long as these stable assets hold value, so does HFI. HFI will increase in value the longer you hold it."),
    React.createElement("br", null),
    React.createElement("div", { className: "text-align-left" },
        React.createElement("p", null,
            React.createElement("b", null, "Deposit Stats:")),
        React.createElement("p", null, "Deposit fee 15%"),
        React.createElement("ul", null,
            React.createElement("li", null, "- 10% Treasury"),
            React.createElement("li", null, "- 5% HFI Investors")))));
export const LockingBackCard = () => (React.createElement(React.Fragment, null,
    React.createElement("p", null, "Locking HFI mints veHFI. veHFI provides direct treasury exposure which can effect your holdings both positively and negatively. Potentially higher returns coupled with higher risk. veHFI also comes with voting power over certain treasury deployments meaning you will be able to vote on some of the investments our treasury allocates funds into. Profit and Loss is distributed through a veHFI to HFI ratio. The maximum ratio possible is dictated by the locking period.")));
export const WithdrawalBackCard = () => (React.createElement(React.Fragment, null,
    React.createElement("p", null, "To withdraw your HFI simply select how much HFI you wish to sell and approve the transaction. There is no sell fee as we encourage healthy profit taking. We hope you enjoy your profits and we look forward to seeing you again soon."),
    React.createElement("br", null),
    React.createElement("p", null, "Best regards,"),
    React.createElement("p", null,
        React.createElement("b", null, "The Hybrid Team"),
        ".")));
