var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
var SolanaRealmsContext = React.createContext(null);
export var useSolanaRealmsContext = function () {
    var context = useContext(SolanaRealmsContext);
    if (!context) {
        throw new Error('Solana realms context must be used inside of the context provider.');
    }
    return context;
};
export var SolanaRealmsContextProvider = function (_a) {
    var children = _a.children, wallets = _a.wallets;
    // Piece of state to handle the NFT collections overview.
    var _b = useState({}), overviewState = _b[0], setOverviewState = _b[1];
    // Piece of state to remember NFT collections for different wallets.
    var _c = useState({}), walletNftCollections = _c[0], setWalletNftCollections = _c[1];
    // Piece of state to remeber aggregated nft collections for all of the wallets.
    var _d = useState({
        aggregatedCollections: [],
        aggregatedWallets: []
    }), aggregatedNftState = _d[0], setAggregatedNftState = _d[1];
    /**
     * Cached function for adding new set of wallet nft collections to the state.
     */
    var addWalletNftCollection = useCallback(function (walletAddress, nftCollections) {
        var _a;
        // Collections have already been set for the wallet.
        if (walletNftCollections[walletAddress]) {
            return;
        }
        setWalletNftCollections(__assign(__assign({}, walletNftCollections), (_a = {}, _a[walletAddress] = nftCollections, _a)));
    }, [walletNftCollections, setWalletNftCollections]);
    /**
     * Cached function for aggregating a set of the nft collections.
     */
    var aggregateNftCollection = useCallback(function (walletAddress, nftCollections) {
        // Collections have already been aggregated.
        if (aggregatedNftState.aggregatedWallets.includes(walletAddress)) {
            return;
        }
        if (nftCollections.length === 0) {
            setAggregatedNftState(__assign(__assign({}, aggregatedNftState), { aggregatedWallets: __spreadArray(__spreadArray([], aggregatedNftState.aggregatedWallets, true), [
                    walletAddress
                ], false) }));
        }
        // Create a map for a optimised merge.
        var nftCollectionsMap = {};
        for (var _i = 0, _a = aggregatedNftState.aggregatedCollections; _i < _a.length; _i++) {
            var nftCollection = _a[_i];
            nftCollectionsMap[nftCollection.name] = nftCollection;
        }
        for (var _b = 0, nftCollections_1 = nftCollections; _b < nftCollections_1.length; _b++) {
            var nftCollection = nftCollections_1[_b];
            var aggregatedCollection = nftCollectionsMap[nftCollection.name];
            if (aggregatedCollection) {
                aggregatedCollection.nfts = __spreadArray(__spreadArray([], aggregatedCollection.nfts, true), nftCollection.nfts, true);
                aggregatedCollection.nftsCount = aggregatedCollection.nfts.length;
                nftCollectionsMap[nftCollection.name] = aggregatedCollection;
                continue;
            }
            nftCollectionsMap[nftCollection.name] = nftCollection;
        }
        setAggregatedNftState({
            aggregatedCollections: Object.values(nftCollectionsMap),
            aggregatedWallets: __spreadArray(__spreadArray([], aggregatedNftState.aggregatedWallets, true), [
                walletAddress
            ], false)
        });
    }, [aggregatedNftState, setAggregatedNftState]);
    /**
     * A hook for agregating all of the NFT collections
     */
    useEffect(function () {
        var nonAggregatedWallets = Object.keys(walletNftCollections).filter(function (walletAddress) {
            return !aggregatedNftState.aggregatedWallets.includes(walletAddress);
        });
        for (var _i = 0, nonAggregatedWallets_1 = nonAggregatedWallets; _i < nonAggregatedWallets_1.length; _i++) {
            var walletAddress = nonAggregatedWallets_1[_i];
            aggregateNftCollection(walletAddress, walletNftCollections[walletAddress]);
        }
    }, [walletNftCollections, aggregateNftCollection]);
    var context = useMemo(function () { return ({
        walletAddresses: wallets || [],
        overviewState: overviewState,
        setOverviewState: setOverviewState,
        walletNftCollections: walletNftCollections,
        addWalletNftCollection: addWalletNftCollection,
        aggregatedNftState: aggregatedNftState
    }); }, [
        overviewState,
        setOverviewState,
        wallets,
        walletNftCollections,
        addWalletNftCollection,
        aggregatedNftState
    ]);
    return (React.createElement(SolanaRealmsContext.Provider, { value: context }, children));
};
