import { useMemo } from 'react';
// Hooks
import { useBalance } from './useBalance';
import { useTokenPrice } from './useTokenPrice';
// Static
import { V2_ADDRESS } from '../../constants';
// Utils
import { getTokensPriceUSD } from '../utils/helpers';
export const useHFIBalance = () => {
    const balanceHFI = useBalance(V2_ADDRESS);
    const { price } = useTokenPrice();
    const usdAmount = useMemo(() => getTokensPriceUSD(balanceHFI.balance, price), [balanceHFI.balance, price]);
    return {
        balance: balanceHFI.balance,
        price,
        usdAmount
    };
};
