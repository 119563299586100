import React, { useMemo } from 'react';
export var AssetsIcons = function (_a) {
    var icons = _a.icons, maxAssets = _a.maxAssets;
    var _b = useMemo(function () {
        var count = Math.max(icons.length - maxAssets, 0);
        return [icons.slice(0, maxAssets), count];
    }, [icons]), displayedIcons = _b[0], moreCount = _b[1];
    return (React.createElement("div", { className: "multifarm-wallet-info__assets" },
        displayedIcons.map(function (icon, index) { return (React.createElement("img", { className: "multifarm-wallet-info__assets-icon", key: index, src: icon, alt: "Icon" })); }),
        moreCount > 0 && (React.createElement("span", { className: "multifarm-wallet-info__assets-count" },
            "+ ",
            moreCount))));
};
