export const EXPO_CODE = `
    import { MultifarmProvider, Dashboard, DashboardTabs, DASHBOARD_TABS_VARIANTS } from "@multifarm/widget";
    import { useState } from "react";
    
    // Use API keys generated in Multifarm Dashboard
    const EXPO_TOKEN = {
      Farming: "{FARMING_TOKEN}",
      Degen: "{DEGEN_TOKEN}",
      GameFi: "{GAMEFI_TOKEN}"
    };
    
    export default function App() {
      const [expoAccount, onExpoAccount] = useState("Farming");
      return (
        <MultifarmProvider
          // Here the account data will be loaded based on selected account token
          token={EXPO_TOKEN[expoAccount]}
          theme="expo"
          provider="expo"
          themeColors="light"
          technologiesUsedPlacement="none"
          {...(expoAccount === 'Degen' && {
              dashboardTabs: [DASHBOARD_TABS_VARIANTS.PORTFOLIO_ALLOCATION]
          })}
        >
          {/* The example above uses additional Tabs component to add Farming & Degen toggle */}
          {/* use="expo-accounts" - only for CSS spacing, you can provide className if required. */}
          <DashboardTabs
            use="expo-accounts"
            tabs={["Farming", "Degen", "GameFi"]}
            value={expoAccount}
            onChange={onExpoAccount}
          />
          
          <Dashboard />
        </MultifarmProvider>
      );
    }
`

export const DASHBOARD_CODE = `
    import { MultifarmProvider, Dashboard } from "@multifarm/widget";
    import "@multifarm/widget/dist/<provider>.css";
    
    export default function App() {
      return (
        <MultifarmProvider
          token="<TOKEN>"
          theme="<provider>"
          provider="<provider>"
          themeColors="light"
        >
          <Dashboard />
        </MultifarmProvider>
      );
    }   
`
