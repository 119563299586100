var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { useWeb3Context } from '../contexts/web3.context';
export const useAddress = () => {
    const { provider } = useWeb3Context();
    const [address, setAddress] = useState(null);
    useEffect(() => {
        if (!provider) {
            return;
        }
        const init = () => __awaiter(void 0, void 0, void 0, function* () {
            const signer = provider.getSigner();
            const address = yield signer.getAddress();
            setAddress(address);
            provider.on('accountsChanged', ([newAddress]) => {
                if (newAddress) {
                    setAddress(newAddress);
                }
            });
        });
        init();
    }, [provider]);
    return { address };
};
