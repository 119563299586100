import useSwr from 'swr';
// API - Service
import { fetcher } from '../../services/api';
// Utils
import { stringifyURL } from '../../common/utils/api.utils';
export const useProtocolMetrics = (params) => {
    let { data, error } = useSwr(() => stringifyURL('/auth/protocols/get_protocol_metrics', params), fetcher);
    const isLoading = !data && !error;
    data = (data === null || data === void 0 ? void 0 : data.data) || [];
    return {
        isLoading,
        data,
        primaryMetric: data[0]
    };
};
