import React, { useMemo } from 'react';
import { Card } from '@multifarm/widget-components';
// Components
import { PieChartTable } from '../../../../../components/charts/pie-chart-table/PieChartTable';
// Static
import { CAPSULE_ANALYTICS_CHARTS, SOLD_NFTS_CHART } from '../../../../../common/static/chart.static';
// Utils
import { formatNumber, formatPercent } from '../../../../../common/utils';
// Styles
import './sold-nfts-chart.scss';
var tooltipOptions = {
    formatTooltipKey: function () { return 'Total Amount Sold'; },
    formatTooltipValue: function (value) { return formatNumber(value); },
    categoryKey: 'percentage',
    formatCategory: function (value) { return "% Distribution: ".concat(formatPercent(value)); }
};
export var SoldNftsChart = function (_a) {
    var data = _a.data;
    /**
     * Sorting the data in descending order by ther percentage value.
     */
    var sortedData = useMemo(function () {
        return data.sort(function (first, second) {
            if (first.percentage > second.percentage)
                return -1;
            if (first.percentage < second.percentage)
                return 1;
            return 0;
        });
    }, [data]);
    return (React.createElement(Card, { className: "multifarm-thor-finance-card multifarm-thor-finance-sold-nfts-chart-card", title: "TOTAL NFTs SOLD" },
        React.createElement(PieChartTable, { data: sortedData, chartDataKey: "value", tableColumns: SOLD_NFTS_CHART.COLUMNS, colors: CAPSULE_ANALYTICS_CHARTS.COLORS, tooltipOptions: tooltipOptions })));
};
