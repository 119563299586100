import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
// Components
import { AssetItem } from '../asset-item';
// Icons
import { ChevronRightIcon, PlusOutlinedIcon } from '../../../../assets/icons';
// Styles
import './assets-info.scss';
export var AssetsInfo = function (_a) {
    var _b, _c;
    var title = _a.title, assets = _a.assets, Icon = _a.icon, _d = _a.shouldOpenDefault, shouldOpenDefault = _d === void 0 ? true : _d, _e = _a.maxAssets, maxAssets = _e === void 0 ? 3 : _e, globalCTA = _a.globalCTA, globalAction = _a.globalAction;
    var _f = useState(shouldOpenDefault), isOpen = _f[0], setIsOpen = _f[1];
    var _g = useState(false), isExpanded = _g[0], setIsExpanded = _g[1];
    var visibleAssets = useMemo(function () {
        if (isExpanded) {
            return assets;
        }
        return assets.slice(0, maxAssets);
    }, [assets, maxAssets, isExpanded]);
    return (React.createElement("div", { className: "multifarm-assets-info" },
        React.createElement("div", { className: clsx('multifarm-assets-info-header', (_b = {},
                _b['multifarm-assets-info-header__collapsed'] = !isOpen,
                _b)) },
            Icon && (React.createElement("div", { className: "multifarm-assets-info-header-icon-wrapper" }, React.createElement(Icon, { className: "multifarm-assets-info-header-icon" }))),
            React.createElement("div", { className: "multifarm-assets-info-header-title" },
                React.createElement("span", null, title),
                React.createElement("span", { className: "multifarm-assets-info-header-badge" }, assets.length),
                globalCTA && (React.createElement("span", { className: clsx('multifarm-assets-info-header-global-action', {
                        clickable: globalAction !== undefined
                    }), onClick: globalAction }, globalCTA))),
            React.createElement("button", { className: "multifarm-assets-info-header-button", type: "button", onClick: function () { return setIsOpen(function (prev) { return !prev; }); } },
                React.createElement(ChevronRightIcon, { className: clsx('multifarm-assets-info-header-arrow', (_c = {},
                        _c['multifarm-assets-info-header-arrow__active'] = isOpen,
                        _c)) }))),
        isOpen && (React.createElement("div", { className: "multifarm-assets-info-content" },
            React.createElement("div", { className: "multifarm-assets-info-list" }, visibleAssets.map(function (asset) { return (React.createElement(AssetItem, { key: asset.id, data: asset })); })),
            assets.length > maxAssets && (React.createElement("button", { type: "button", className: "multifarm-assets-info-button", onClick: function () { return setIsExpanded(function (prev) { return !prev; }); } },
                React.createElement(PlusOutlinedIcon, { className: "multifarm-assets-info-button__icon" }),
                isExpanded ? 'View Less' : 'View All'))))));
};
