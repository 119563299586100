import React from 'react';
import clsx from 'clsx';
// Styles
import './page-tab.scss';
export var PageTab = function (_a) {
    var page = _a.page, active = _a.active, setPage = _a.setPage;
    return (React.createElement("div", { className: clsx('multifarm-solana-realms-page-tab', {
            'multifarm-solana-realms-page-tab-active': active
        }), onClick: function () { return setPage(page); } }, page));
};
