var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
// Libs
import isAfter from 'date-fns/isAfter';
import subHours from 'date-fns/subHours';
import subDays from 'date-fns/subDays';
/**
 * @param tick - Axis tick label
 * @param formatter - Axis tick formatter function
 * @returns Tick's width.
 */
export const calcTickWidth = (tick, formatter) => {
    return `${formatter(tick)}`.length * 10;
};
/**
 * @param data - Chart data array
 * @param formatter - Axis tick formatter function
 * @returns Axis width
 */
export const calcAxisWidth = (data, formatter) => {
    return Math.max(calcTickWidth(Math.max(...data.map((_a) => {
        var { date: _date } = _a, row = __rest(_a, ["date"]);
        return Math.max(...Object.values(row));
    })), formatter), 60);
};
export function parseRangeChart(range, data = []) {
    switch (range) {
        case '1h':
            return data.filter(({ date }) => isAfter(new Date(date), subHours(new Date(), 1)));
        case '4h':
            return data.filter(({ date }) => isAfter(new Date(date), subHours(new Date(), 4)));
        case '24h':
            return data.filter(({ date }) => isAfter(new Date(date), subHours(new Date(), 24)));
        case '3d':
            return data.filter(({ date }) => isAfter(new Date(date), subDays(new Date(), 3)));
        case '7d':
            return data.filter(({ date }) => isAfter(new Date(date), subDays(new Date(), 7)));
        case '30d':
            return data.filter(({ date }) => isAfter(new Date(date), subDays(new Date(), 30)));
        default:
            return data;
    }
}
