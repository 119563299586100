// Libs
import React, { useMemo } from 'react';
// Components
import { StatTableCard } from '../../../components/StatTableCard';
// Hooks API
import { useLiquidationsStats } from '../../../hooks/api/useLiquidationsStats';
// Utils
import { formatChartMoney } from '../../../utils/format';
// Styles
import s from '../overview.module.css';
export var CollateralDebtStatistics = function () {
    var _a = useLiquidationsStats(), isLoading = _a.isLoading, data = _a.data;
    var _b = useMemo(function () {
        if (!data) {
            return [[], []];
        }
        return [
            [
                {
                    title: 'Total Collateral',
                    value: formatChartMoney(data.total_collateral),
                    className: s.statBlockHeader
                },
                {
                    title: 'Collateral of Top 1 User',
                    value: formatChartMoney(data.highest_collateral)
                },
                {
                    title: 'Collateral of Top 10 Users',
                    value: formatChartMoney(data.top_highest_collaterals)
                }
            ],
            [
                {
                    title: 'Total Debt',
                    value: formatChartMoney(data.total_borrowed),
                    className: s.statBlockHeader
                },
                {
                    title: 'Collateral of Top 1 User',
                    value: formatChartMoney(data.highest_borrowed)
                },
                {
                    title: 'Collateral of Top 10 Users',
                    value: formatChartMoney(data.top_highest_borrowed)
                }
            ]
        ];
    }, [data]), collateral = _b[0], debt = _b[1];
    return (React.createElement("div", { className: s.bastionCollateralBlock },
        React.createElement(StatTableCard, { stats: collateral, updatedAt: data === null || data === void 0 ? void 0 : data.dateUpdated, isLoading: isLoading }),
        React.createElement(StatTableCard, { stats: debt, updatedAt: data === null || data === void 0 ? void 0 : data.dateUpdated, isLoading: isLoading })));
};
