import React, { useState } from 'react';
import { Accordion } from '@multifarm/widget-components';
// Components
import { VaultHeader } from './header/VaultHeader';
import { VaultBody } from './body/VaultBody';
// Styles
import './vault.scss';
export const Vault = ({ progress, blockchainIcon }) => {
    const [open, setOpen] = useState(false);
    return (React.createElement("div", { className: "numisme-vault" },
        React.createElement(Accordion, { isOpen: open, onClick: () => setOpen(!open), headerComponent: React.createElement(VaultHeader, { open: open, setOpen: setOpen, progress: progress, blockchainIcon: blockchainIcon }) },
            React.createElement(VaultBody, null))));
};
