import React, { useMemo, useState } from 'react';
// Components
import { HybridLineChart } from '../components/hybridLineChart';
// Hooks - API
import { useProtocolMetrics } from '../hooks/api/useProtocolMetrics';
// Static
import { HybridMetricsEnum, HybridMetricsChartMap, DAY_RANGES } from '../common/static';
// Utils
import { formatMoney, formatNumber, isEmpty, reverse } from '../common/utils';
// Icons
import { LogoHorzIcon } from '../assets/icons';
export function Dashboard() {
    const [activeMetric, setActiveMetric] = useState(HybridMetricsEnum.CURRENT_PRICE);
    // Data fetching
    const { primaryMetric: protocolMetrics, isLoading } = useProtocolMetrics({
        token: 'HYBRID'
    });
    /**
     * A memo to cache the chart data.
     */
    const chartData = useMemo(() => {
        let chartData = [];
        if (!isEmpty(protocolMetrics)) {
            const data = protocolMetrics[HybridMetricsChartMap[activeMetric]];
            if (!isEmpty(data)) {
                chartData = reverse(data);
            }
        }
        return chartData;
    }, [protocolMetrics, activeMetric]);
    return (React.createElement(React.Fragment, null,
        React.createElement("header", { className: "section_header" },
            React.createElement("div", { className: "page-padding" },
                React.createElement("div", { className: "container-small" },
                    React.createElement("div", { className: "padding-vertical padding-huge" },
                        React.createElement("div", { "data-w-id": "b697fa6f-e63f-c47c-6eb6-8487e684c3ee", className: "text-align-center" },
                            React.createElement("div", { className: "margin-bottom margin-small" },
                                React.createElement(LogoHorzIcon, { width: 28, height: 38 }),
                                React.createElement("h1", null, "Dashboard")),
                            React.createElement("div", { className: "divider light" }),
                            React.createElement("p", { className: "text-size-medium" }, "Welcome to Hybrid Finance's Dashboard. Here you will get an overview of the protocol's health and growth. Clicking a value below will provide a chart for that selected metric."),
                            React.createElement("div", { className: "divider light" })))))),
        React.createElement("section", { className: "section_stats" },
            React.createElement("div", { className: "page-padding" },
                React.createElement("div", { className: "container-medium" },
                    React.createElement("div", { className: "padding-bottom padding-xxhuge" },
                        React.createElement("div", { "data-w-id": "b8a1c0ad-f900-49a9-0316-7fefca482051", "data-current": "Tab 1", "data-easing": "ease", "data-duration-in": "300", "data-duration-out": "100", className: "tabs-2 w-tabs" },
                            React.createElement("div", { className: "tabs-menu-2 w-tab-menu" },
                                React.createElement("a", { "data-w-tab": "Tab 1", className: `tab-link w-inline-block w-tab-link ${activeMetric == HybridMetricsEnum.CURRENT_PRICE &&
                                        'w--current'}`, onClick: () => setActiveMetric(HybridMetricsEnum.CURRENT_PRICE) },
                                    React.createElement("div", { className: "metric-wrapper" },
                                        React.createElement("div", { className: "metric-label" }, "Current Price"),
                                        React.createElement("div", { className: "metric" }, formatMoney(protocolMetrics === null || protocolMetrics === void 0 ? void 0 : protocolMetrics.price_usd, 2, true))),
                                    React.createElement("div", { className: "vertical-divider" })),
                                React.createElement("a", { "data-w-tab": "Tab 2", className: `tab-link w-inline-block w-tab-link ${activeMetric == HybridMetricsEnum.INFINITY_POOL_VALUE &&
                                        'w--current'}`, onClick: () => setActiveMetric(HybridMetricsEnum.INFINITY_POOL_VALUE) },
                                    React.createElement("div", { className: "metric-wrapper" },
                                        React.createElement("div", { className: "metric-label" }, "Infinity Pool Value"),
                                        React.createElement("div", { className: "metric" }, formatMoney(protocolMetrics === null || protocolMetrics === void 0 ? void 0 : protocolMetrics.infinity_pool_value))),
                                    React.createElement("div", { className: "vertical-divider" })),
                                React.createElement("a", { "data-w-tab": "Tab 3", className: `tab-link w-inline-block w-tab-link ${activeMetric == HybridMetricsEnum.TOKEN_HOLDERS &&
                                        'w--current'}`, onClick: () => setActiveMetric(HybridMetricsEnum.TOKEN_HOLDERS) },
                                    React.createElement("div", { className: "metric-wrapper" },
                                        React.createElement("div", { className: "metric-label" }, "Token Holders"),
                                        React.createElement("div", { className: "metric" }, formatNumber(protocolMetrics === null || protocolMetrics === void 0 ? void 0 : protocolMetrics.total_holders))),
                                    React.createElement("div", { className: "vertical-divider" })),
                                React.createElement("a", { "data-w-tab": "Tab 4", className: `tab-link w-inline-block w-tab-link ${activeMetric == HybridMetricsEnum.TREASURY_VALUE &&
                                        'w--current'}`, onClick: () => setActiveMetric(HybridMetricsEnum.TREASURY_VALUE) },
                                    React.createElement("div", { className: "metric-wrapper" },
                                        React.createElement("div", { className: "metric-label" }, "Treasury Value"),
                                        React.createElement("div", { className: "metric" }, formatMoney(protocolMetrics === null || protocolMetrics === void 0 ? void 0 : protocolMetrics.treasury_value))))),
                            React.createElement("div", { className: "tabs-content w-tab-content" },
                                React.createElement("div", { "data-w-tab": "Tab 1", className: "w-tab-pane w--tab-active" },
                                    React.createElement(HybridLineChart, { isLoading: isLoading, data: chartData, defaultRange: "30d", ranges: DAY_RANGES, yAxisProps: activeMetric === HybridMetricsEnum.TOKEN_HOLDERS
                                            ? { allowDecimals: false }
                                            : {}, yTickFormatter: activeMetric === HybridMetricsEnum.TOKEN_HOLDERS
                                            ? (value) => formatNumber(value)
                                            : undefined }))))))))));
}
