import {
  MultifarmProvider,
  Dashboard,
  DashboardTabs,
  DASHBOARD_TABS_VARIANTS
} from '@multifarm/widget'
import { HybridApp } from 'multifarm-hybrid-finance'
import { BastionDashboard } from '@multifarm/bastion'
import { NumismeApp } from '@multifarm/numisme'
import { ThorFinance } from '@multifarm/thor-finance'
import { MultifarmSolanaRealms } from '@multifarm/solana-realms'
import { useThemeContext } from '../providers/ThemeProvider'
import {
  DIGITS_TOKEN,
  EXPO_DEGEN_TOKEN,
  EXPO_FARMING_TOKEN,
  FF_TOKEN,
  REFI_TOKEN,
  SPHERE_TOKEN,
  MAIA_TOKEN,
  UMAMI_TOKEN,
  PARAGON_TOKEN,
  REDACTED_TOKEN,
  ALCHEMIX_TREASURY_TOKEN,
  ALCHEMIX_PRODUCTS_TOKEN,
  TOKEN,
  EXPO_GAMEFI_TOKEN,
  OSMOSIS_TOKEN,
  BALANCER_TOKEN,
  LAYER2DAO_TOKEN,
  METAVAULT_TOKEN,
  VIRAL_COIN_TOKEN,
  BASTION_TOKEN,
  THOR_FINANCE_TOKEN
} from '../config'
import DashboardContent from '../content/dashboard.md'
import Content from '../components/Content'
import CodeAccordion from '../components/CodeAccordion'
import { DASHBOARD_CODE, EXPO_CODE } from './dashboard/code'
import Code from '../components/Code'

const hybridConfig = {
  locker: {
    unlocked: '0x0bb72890B4F5477c13c4cB8EeFF1DE277b67FcF8', // Available contract address
    message: '12 Month Locking Open' // Banner message
  }
}

const strategyTypesConfig = {
  wallet: {
    tooltip: false,
    cols: {
      apr_y: false,
      progress: false,
      farm: false,
      month_earnings: false
    }
  },
  collateral: {
    active: false
  },
  claimable: {
    cols: {
      apr_y: false,
      month_earnings: false,
      progress: false
    }
  },
  debt: {
    active: false
  },
  nft: {
    active: false
  }
}

const redactedStrategyTypesConfig = {
  wallet: {
    cols: {
      apr_y: false,
      month_earnings: false
    }
  },
  claimable: {
    cols: {
      apr_y: false,
      month_earnings: false
    }
  }
}

const categoryTypesConfig = {
  'Reserve Assets': {
    cols: {
      farm: false,
      apr_y: false,
      month_earnings: false,
      tooltip: false,
      progress: false
    }
  },
  Other: {
    cols: {
      farm: false,
      apr_y: false,
      month_earnings: false,
      tooltip: false,
      progress: false
    }
  },
  'Protocol-Owned Liquidity': {
    cols: {
      progress: false
    },
    colsNames: {
      farm: 'DEX'
    }
  },
  'Olympus Pro': {
    cols: {
      farm: false,
      apr_y: false,
      month_earnings: false,
      progress: false
    }
  },
  'Strategic Assets': {
    cols: {
      progress: false,
      farm: false,
      apr_y: false,
      month_earnings: false
    }
  },
  'Olympus Branch': {
    cols: {
      progress: false
    },
    colsNames: {
      farm: 'Protocol'
    }
  },
  Incubator: {
    cols: {
      farm: false,
      progress: false
    }
  }
}

export default function DashboardPage() {
  const {
    theme,
    themeColors,
    lng,
    expoProvider,
    onExpoProvider,
    alchemixProvider,
    onAlchemixProvider,
    layer2DaoProvider,
    onLayer2DaoProvider
  } = useThemeContext()

  const Props = {
    token: TOKEN,
    theme: theme,
    themeColors: themeColors,
    provider: theme,
    lng: lng,
    key: theme,

    ...(theme === 'olympus' && {
      strategyTypesConfig: strategyTypesConfig,
      categoryTypesConfig: categoryTypesConfig,
      badgePlacement: 'bottom'
    }),

    ...(theme === 'expo' && {
      token:
        expoProvider === 'Farming'
          ? EXPO_FARMING_TOKEN
          : expoProvider === 'GameFi'
          ? EXPO_GAMEFI_TOKEN
          : EXPO_DEGEN_TOKEN,
      technologiesUsedPlacement: 'none',
      strategyTypesConfig: {
        nft: {
          active: false
        }
      },

      ...(expoProvider === 'Degen' && {
        dashboardTabs: [DASHBOARD_TABS_VARIANTS.PORTFOLIO_ALLOCATION]
      })
    }),

    ...(theme === 'refi' && {
      token: REFI_TOKEN
    }),

    ...(theme === 'food-farmer' && {
      token: FF_TOKEN
    }),

    ...(theme === 'digits' && {
      token: DIGITS_TOKEN
    }),
    ...(theme === 'alchemix' && {
      token:
        alchemixProvider === 'Treasury'
          ? ALCHEMIX_TREASURY_TOKEN
          : ALCHEMIX_PRODUCTS_TOKEN,
      badgePlacement: 'none',
      ...(alchemixProvider === 'Product' && {
        dashboardTabs: [
          DASHBOARD_TABS_VARIANTS.PORTFOLIO_ALLOCATION,
          DASHBOARD_TABS_VARIANTS.STRATEGIES_PERFORMANCE
        ]
      })
    }),
    ...(theme === 'sphere' && {
      token: SPHERE_TOKEN
    }),
    ...(theme === 'maia' && {
      token: MAIA_TOKEN
    }),
    ...(theme === 'paragons' && {
      token: PARAGON_TOKEN
    }),
    ...(theme === 'redacted' && {
      token: REDACTED_TOKEN,
      strategyTypesConfig: redactedStrategyTypesConfig
    }),
    ...(theme === 'umami' && {
      token: UMAMI_TOKEN
    }),
    ...(theme === 'osmosis' && {
      token: OSMOSIS_TOKEN
    }),
    ...(theme === 'balancer' && {
      token: BALANCER_TOKEN
    }),
    ...(theme === 'layer2-dao' && {
      token: LAYER2DAO_TOKEN,
      dashboardTabs:
        layer2DaoProvider === 'Treasury'
          ? [DASHBOARD_TABS_VARIANTS.TREASURY]
          : [DASHBOARD_TABS_VARIANTS.TOKENOMICS]
    }),
    ...(theme === 'metavault' && {
      token: METAVAULT_TOKEN
    }),
    ...(theme === 'viral-coin' && {
      token: VIRAL_COIN_TOKEN
    }),
    ...(theme === 'bastion' && {
      token: BASTION_TOKEN
    })
  }

  const renderDashboard = () => {
    if (theme === 'hybrid') {
      return <HybridApp config={hybridConfig} />
    }

    if (theme === 'numisme') {
      return <NumismeApp config={{}} />
    }

    if (theme === 'bastion') {
      return <BastionDashboard token={Props.token} />
    }

    if (theme === 'thor-financial') {
      return <ThorFinance token={THOR_FINANCE_TOKEN} />
    }

    if (theme === 'solana-realms') {
      return (
        <MultifarmSolanaRealms
          wallets={[
            'BHkk3RTd4Ue6JnqXpa9QHTXbn575ycR8hxVmYx4E254k',
            'FrkLPsCadx4tE4qDobbu2GTD5ffjWBpormHbLLy35PUS'
          ]}
        />
      )
    }

    return (
      <MultifarmProvider {...Props}>
        {theme === 'expo' && (
          <DashboardTabs
            use="expo-accounts"
            tabs={['Farming', 'Degen', 'GameFi']}
            value={expoProvider}
            onChange={(e) => onExpoProvider(e)}
          />
        )}

        {theme === 'alchemix' && (
          <>
            <DashboardTabs
              use="alchemix-accounts"
              tabs={['Treasury', 'Product']}
              value={alchemixProvider}
              onChange={onAlchemixProvider}
            />
          </>
        )}

        {theme === 'layer2-dao' && (
          <>
            <DashboardTabs
              tabs={['Tokenomics', 'Treasury']}
              value={layer2DaoProvider}
              onChange={onLayer2DaoProvider}
            />
          </>
        )}
        <Dashboard key={Props.token} />
      </MultifarmProvider>
    )
  }

  return (
    <div className="flex flex-col w-full">
      <Content content={DashboardContent} />

      <div>{renderDashboard()}</div>

      <div className="py-5">
        <CodeAccordion
          code={<Code>{theme === 'expo' ? EXPO_CODE : DASHBOARD_CODE}</Code>}
        />
      </div>
    </div>
  )
}
