export var PageEnum;
(function (PageEnum) {
    PageEnum["DASHBOARD"] = "Dashboard";
    PageEnum["NFT_VIEW"] = "NFT View";
})(PageEnum || (PageEnum = {}));
export var SortOrderEnum;
(function (SortOrderEnum) {
    SortOrderEnum["ASC"] = "asc";
    SortOrderEnum["DESC"] = "desc";
})(SortOrderEnum || (SortOrderEnum = {}));
