import React from 'react';
import { Card, InfoTooltip } from '@multifarm/widget-components';
// Styles
import './info-card.scss';
export const InfoCard = ({ title, titleTooltip, body, footer }) => {
    let titleComponent;
    if (titleTooltip) {
        titleComponent = React.createElement(InfoTooltip, { content: titleTooltip });
    }
    return (React.createElement(Card, { title: title, titleComponent: titleComponent, className: "info-card" },
        React.createElement("div", { className: "info-card-body" }, body),
        React.createElement("div", { className: "info-card-footer" }, footer)));
};
