import React, { useMemo } from 'react';
import cx from 'clsx';
// Hooks API
import { useInvestmentBalance } from '../../../hooks/api';
// Components
import { EmptyPlaceholder } from '../../placeholders';
// Static
import { HYBRID_LOGO_LINK } from '../../../common/static/app.static';
// Utils
import { formatMoney } from '../../../common/utils/format.utils';
// Styles
import './TotalTreasuryShare.css';
import { PieChart } from '../../PieChart/PieChart';
import { getBlockchainColour } from '../../../common/utils';
export const TotalTreasuryShare = ({ className, onClick = () => { }, blockchainDistribution }) => {
    var _a;
    const { data: investmentBalance, isLoading } = useInvestmentBalance();
    const pieData = useMemo(() => {
        return Object.keys(blockchainDistribution).map((blockchain) => ({
            value: blockchainDistribution[blockchain],
            color: getBlockchainColour(blockchain)
        }));
    }, [blockchainDistribution]);
    return (React.createElement("button", { onClick: () => onClick('Total'), className: cx('treasury_stat-block w-tab-link', className) }, !isLoading ? (React.createElement("div", { className: "metrics-container" },
        React.createElement(PieChart, { data: pieData, innerRadius: "95%" }),
        React.createElement("div", { className: "metrics-data" },
            React.createElement("img", { className: "metric-logo", src: HYBRID_LOGO_LINK }),
            React.createElement("div", { className: "metric-label" }, "Total Treasury"),
            React.createElement("div", { className: "metric" }, formatMoney((_a = investmentBalance.portfolio) === null || _a === void 0 ? void 0 : _a.net_worth))))) : (React.createElement(EmptyPlaceholder, { text: "Loading..." }))));
};
