import { Dashboard, MultifarmProvider } from '@multifarm/widget'
import { useThemeContext } from '../providers/ThemeProvider'
import { TOKEN } from '../config'

export default function IframeContent() {
  const { theme, themeColors } = useThemeContext()
  return (
    <MultifarmProvider
      token={TOKEN}
      theme={theme}
      provider={theme}
      themeColors={themeColors}
      key={theme}
    >
      <Dashboard />
    </MultifarmProvider>
  )
}
