// Libs
import React from 'react';
// Utils
import { formatWalletMoney } from '../../common/utils';
// Styles
import './header.scss';
export var Header = function (_a) {
    var balance = _a.balance;
    return (React.createElement("header", { className: "multifarm-solana-realms__header" },
        React.createElement("h1", { className: "multifarm-solana-realms__header-title" },
            "Assets & Wallets ",
            formatWalletMoney(balance))));
};
