import React from 'react';
// Components
import { ChardCard } from '../../../../../components/chart-card/ChardCard';
import { PieChartTable } from '../../../../../components/charts/pie-chart-table/PieChartTable';
// Static
import { ASSET_BREAKDOWN_CHART } from '../../../../../common/static/placeholder.static';
// Utils
import { capitalize, formatPercent } from '../../../../../common/utils';
export const AssetBreakdownChart = () => {
    return (React.createElement(ChardCard, { title: "Asset Breakdown" },
        React.createElement(PieChartTable, { data: ASSET_BREAKDOWN_CHART.DATA, chartDataKey: "breakdown", tableColumns: ASSET_BREAKDOWN_CHART.COLUMNS, colors: ASSET_BREAKDOWN_CHART.COLORS, tooltipOptions: {
                formatTooltipValue: (tick) => formatPercent(tick),
                formatTooltipKey: (key) => capitalize(key)
            } })));
};
