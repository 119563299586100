var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
// Components
import { AssetsInfo, AssetsIcons } from './fragments';
// Hooks - API
import { useBalance, useWalletStats, useNFTs } from '../../common/hooks/api';
// Context
import { useSolanaRealmsContext } from '../../context/solana-realms.context';
// Utils
import { formatWalletMoney, getShortWalletAddress } from '../../common/utils';
// Icons
import { ChevronRightIcon, TokensIcon, WalletIcon } from '../../assets/icons';
// Styles
import './wallet-info.scss';
export var WalletInfo = function (_a) {
    var walletAddress = _a.walletAddress, _b = _a.shouldBeOpened, shouldBeOpened = _b === void 0 ? false : _b;
    var _c = useState(false), isOpen = _c[0], setIsOpen = _c[1];
    var _d = useSolanaRealmsContext(), overviewState = _d.overviewState, setOverviewState = _d.setOverviewState, addWalletNftCollection = _d.addWalletNftCollection;
    var walletStats = useWalletStats([walletAddress]).data;
    var tokenBalance = useBalance(walletAddress);
    var nftBalance = useNFTs(walletAddress);
    /**
     * Memorising the information if any of the balances has loaded.
     */
    var anyLoaded = useMemo(function () {
        return tokenBalance.data || nftBalance.data;
    }, [tokenBalance, nftBalance]);
    /**
     * Memorising the information if wallet has any tokens or nfts.
     */
    var isEmpty = useMemo(function () {
        return ((!tokenBalance.data ||
            (tokenBalance.data && tokenBalance.data.length === 0)) &&
            (!nftBalance.data || (nftBalance.data && nftBalance.data.length === 0)));
    }, [tokenBalance, nftBalance]);
    /**
     * A hook for opening the wallet info.
     */
    useEffect(function () {
        if (shouldBeOpened && !isEmpty) {
            setIsOpen(true);
        }
    }, [isEmpty, shouldBeOpened]);
    /**
     * Hook for storing the wallet's nft collections to the context.
     */
    useEffect(function () {
        if (nftBalance.loading || !nftBalance.data) {
            return;
        }
        addWalletNftCollection(walletAddress, nftBalance.data);
    }, [nftBalance]);
    /**
     * Memorising NFT collections to render.
     */
    var nftCollections = useMemo(function () {
        if (!nftBalance.data) {
            return [];
        }
        return nftBalance.data.map(function (collection) {
            var _a;
            var assetItem = collection.buildAssetItem();
            assetItem.active = ((_a = overviewState.nftCollection) === null || _a === void 0 ? void 0 : _a.id) === collection.id;
            assetItem.onClick = function () {
                if (assetItem.active) {
                    setOverviewState(__assign(__assign({}, overviewState), { nftCollection: undefined, walletAddress: undefined }));
                    return;
                }
                setOverviewState(__assign(__assign({}, overviewState), { nftCollection: collection, walletAddress: walletAddress }));
            };
            return assetItem;
        });
    }, [overviewState, nftBalance]);
    /**
     * Memorising tokens and tokens icons to render.
     */
    var _e = useMemo(function () {
        if (!tokenBalance.data) {
            return [[], []];
        }
        // Asset items to render as a part of the wallet section.
        var assetItems = [];
        // Icons of the tokens that the wallet consists of.
        var tokenIcons = [];
        for (var _i = 0, _a = tokenBalance.data; _i < _a.length; _i++) {
            var asset = _a[_i];
            var assetItem = asset.buildAssetItem();
            assetItems.push(assetItem);
            // Take up to three top token icons for the wallet.
            if (assetItem.iconUrl) {
                tokenIcons.push(assetItem.iconUrl);
            }
        }
        return [assetItems, tokenIcons];
    }, [tokenBalance]), tokens = _e[0], tokenIcons = _e[1];
    /**
     * Memorised callback to load the NFT collections for the overview.
     */
    var loadOverviewCollections = useCallback(function () {
        var collections = nftBalance.data || [];
        setOverviewState(__assign(__assign({}, overviewState), { nftCollection: undefined, nftCollections: collections, walletAddress: walletAddress }));
    }, [nftBalance]);
    return (React.createElement("section", { className: "multifarm-wallet-info multifarm-wallet-info__card" },
        React.createElement("header", { className: clsx('multifarm-wallet-info__header', {
                'multifarm-wallet-info__header__active': isOpen
            }), onClick: function () { return setIsOpen(function (prev) { return !prev; }); } },
            React.createElement("div", { className: "multifarm-wallet-info__header-info" },
                React.createElement(WalletIcon, { className: "multifarm-wallet-info__header-icon" }),
                React.createElement("div", { className: "multifarm-wallet-info__header-address" },
                    React.createElement("span", null, getShortWalletAddress(walletAddress)),
                    React.createElement(AssetsIcons, { icons: tokenIcons, maxAssets: 3 }))),
            React.createElement("div", { className: "multifarm-wallet-info__header-balance" },
                formatWalletMoney((walletStats === null || walletStats === void 0 ? void 0 : walletStats.totalHoldingsUsd) || 0),
                React.createElement(ChevronRightIcon, { className: "multifarm-wallet-info__header-balance-icon" }))),
        isOpen && (React.createElement("div", { className: "multifarm-wallet-info__content" }, anyLoaded ? (React.createElement(React.Fragment, null,
            tokens.length > 0 && (React.createElement(AssetsInfo, { title: "Tokens", assets: tokens, icon: TokensIcon })),
            nftCollections.length > 0 && (React.createElement(AssetsInfo, { title: "NFTs", assets: nftCollections, globalCTA: "Overview", globalAction: loadOverviewCollections })))) : ('Loading...')))));
};
