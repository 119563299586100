import useSwr from 'swr';
// API - Service
import { fetcher } from '../../services/api';
export const useInvestmentBalance = () => {
    const { data, error } = useSwr('/auth/get_investment_balance', fetcher);
    return {
        isLoading: !data && !error,
        data: data || {}
    };
};
