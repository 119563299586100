import React from 'react';
import { AreaChart, Card } from '@multifarm/widget-components';
// Fragments
import { ListedNftsChartHeader } from './header';
// Static
import { CAPSULE_ANALYTICS_CHARTS, NFT_TYPES } from '../../../../../common/static/chart.static';
// Utils
import { formatDate } from '../../../../../common/utils';
// Styles
import './listed-nfts-chart.scss';
var tooltipOptions = {
    formatCategory: function (value) { return formatDate(value); },
    formatTooltipKey: function (key) { var _a; return (_a = NFT_TYPES[key]) !== null && _a !== void 0 ? _a : key; }
};
export var ListedNftsChart = function (_a) {
    var keys = _a.keys, data = _a.data, _b = _a.loading, loading = _b === void 0 ? false : _b;
    return (React.createElement(Card, { className: "multifarm-thor-finance-card multifarm-thor-finance-listed-nfts-chart-card borderless-grid", title: "NUMBER OF NFTS LISTED", headerComponent: React.createElement(ListedNftsChartHeader, { chartKeys: keys.map(function (key) { var _a; return (_a = NFT_TYPES[key]) !== null && _a !== void 0 ? _a : key; }), chartColours: CAPSULE_ANALYTICS_CHARTS.COLORS }) },
        React.createElement(AreaChart, { className: "borderless-area", data: data, dataKeys: keys, colors: CAPSULE_ANALYTICS_CHARTS.COLORS, chartOptions: CAPSULE_ANALYTICS_CHARTS.OPTIONS, stack: 1, isLoading: loading, tooltipOptions: tooltipOptions, xTickFormatter: function (tick) { return formatDate(tick); } })));
};
