import React, { useMemo } from 'react';
import { getDifferenceInStringFormat } from '../../../../common/utils';
export const LockedBanner = ({ loading, nextTimestamp, locked, children, className, hideChildren, unlockedClassName }) => {
    if (loading) {
        return (React.createElement("div", { className: className },
            React.createElement("div", { className: "text-block-3" }, "Loading...")));
    }
    if (typeof locked !== 'boolean' || !locked) {
        return React.createElement("div", { className: unlockedClassName }, children);
    }
    const dateDifferenceString = useMemo(() => {
        const nextDate = new Date(nextTimestamp);
        return getDifferenceInStringFormat(nextDate);
    }, [nextTimestamp]);
    return (React.createElement("div", { className: className },
        !hideChildren && children,
        React.createElement("div", { className: "text-block-3" },
            "Time left to unlock: ",
            dateDifferenceString)));
};
