import { useMemo } from 'react';
import { secondsToMilliseconds } from 'date-fns';
/**
 * @param data - User's and system's lock information.
 * @returns The next unlock time based on the given userLock information and the application wide lock information. The maximum value between the user's and system's unlock time will be taken.
 */
export const useNextUnlockTime = (data) => {
    const nextUnlockTime = useMemo(() => {
        var _a;
        const { userLockInfo, nextTime } = data;
        if (!userLockInfo && !nextTime) {
            return 0;
        }
        let nextUnlockTimestamp = 0;
        // If the system is in locked state, then we can use the "nextTime" as the next unlock time.
        if (nextTime && nextTime.isLocked) {
            const systemUnlockTime = secondsToMilliseconds((_a = nextTime.nextTimestamp) === null || _a === void 0 ? void 0 : _a.toNumber());
            nextUnlockTimestamp = systemUnlockTime;
        }
        // User lock time consideration.
        if (userLockInfo) {
            const userUnlockTime = secondsToMilliseconds(userLockInfo.unlockTime.toNumber());
            if (nextUnlockTimestamp < userUnlockTime) {
                nextUnlockTimestamp = userUnlockTime;
            }
        }
        return nextUnlockTimestamp;
    }, [data]);
    return { nextUnlockTime };
};
