import React from 'react';
import cx from 'clsx';
import { Select as SelectComponent } from '@multifarm/widget-components';
import s from './Select.module.css';
import { getAssetIcon } from '../../utils/icons';
export var Select = function (_a) {
    var options = _a.options, value = _a.value, placeholder = _a.placeholder, onChange = _a.onChange, className = _a.className, iconClassName = _a.iconClassName, _b = _a.withIcon, withIcon = _b === void 0 ? true : _b, _c = _a.getIcon, getIcon = _c === void 0 ? function (asset) { return getAssetIcon(asset); } : _c;
    return (React.createElement("div", { className: s.selectWrapper },
        withIcon && (React.createElement("div", { className: s.iconWrapper },
            React.createElement("img", { className: cx(s.icon, iconClassName), src: getIcon(value) }))),
        React.createElement(SelectComponent, { className: cx(className, s.select), placeholder: placeholder, options: options, value: value, onChange: onChange })));
};
