var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback } from 'react';
import { useWeb3Context } from '../contexts/web3.context';
import { approveTransaction, getUserLockInfo, unlockTokens } from '../utils/helpers';
import { VEHYBRID_ADDRESS } from '../../constants';
export const useTokenUnlock = () => {
    const { provider } = useWeb3Context();
    const unlock = useCallback((lockerAddress) => __awaiter(void 0, void 0, void 0, function* () {
        if (!provider) {
            return;
        }
        try {
            const signer = yield provider.getSigner();
            const address = yield signer.getAddress();
            const { amount: lockedAmount } = yield getUserLockInfo(provider, address, lockerAddress);
            yield approveTransaction(signer, lockedAmount, lockerAddress, VEHYBRID_ADDRESS);
            yield unlockTokens(signer, lockerAddress);
        }
        catch (error) {
            throw error;
        }
    }), [provider]);
    return {
        unlock
    };
};
