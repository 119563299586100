export const DEFAULT = `
    export const chartColors = [
      '#F4BE37',
      '#FF9F40',
      '#D733FF',
      '#66C8FF',
      '#7DFF5A',
      '#FF5A5A',
      '#f72585',
      '#3f37c9',
      '#90e0ef',
      '#06d6a0',
      '#b5e48c',
      '#38b000',
      '#fee440'
    ]
    
    export const defaultTheme = {
      text: {
        primary: '#253449',
        secondary: '#768299',
        highlight: '#7F7FD5'
      },
      checkbox: {
        background: {
          primary: '#7F7FD5'
        },
        text: {
          primary: '#FFFFFF'
        }
      },
      border: {
        primary: '#F3F3F3',
        secondary: undefined
      },
      card: {
        background: {
          primary: '#F3F3F3'
        }
      },
      tabs: {
        background: {
          primary: '#7F7FD5',
          secondary: '#FFFFFF'
        },
        text: {
          primary: '#FAFAFA',
          secondary: '#768299'
        },
        border: {
          primary: undefined
        }
      },
      table: {
        background: {
          primary: '#F3F3F3'
        }
      },
      list: {
        background: {
          primary: '#FFF'
        },
        text: {
          primary: '#243449',
          secondary: '#243449',
          highlight: '#7F7FD5'
        }
      },
      progress: {
        background: {
          primary: '#7F7FD5',
          secondary: 'rgba(70, 79, 129, 0.15)'
        }
      },
      form: {
        background: {
          primary: '#F3F3F3'
        },
        text: {
          primary: '#243449'
        }
      },
      badge: {
        background: {
          primary: '#edf1f2'
        },
        text: {
          primary: '#000'
        }
      },
      tooltip: {
        background: {
          primary: 'rgba(255, 255, 255, 0.85)'
        },
        border: {
          primary: 'rgba(118, 130, 153, 0.2)'
        },
        text: {
          primary: '#000'
        }
      },
      chartColors: chartColors,
      chartWatermark: null
    }
`

export const OLYMPUS = `
    import { defaultTheme, extendTheme } from '@multifarm/widget'
    
    export const olympusChartColors = [
      '#9B7731',
      '#6C788F',
      '#F9C47D',
      '#18202A',
      '#2292F3',
      '#A26769',
      '#582C4D',
      '#ff3864',
      '#c0bda5',
      '#35605a'
    ]
    
    export const olympusDarkChartColors = [
      '#f8a650',
      '#f9c47d',
      '#ffcf3f',
      '#faefc9',
      '#8fb6cf',
      '#ffadbf',
      '#ff3864',
      '#c0bda5',
      '#99c24d',
      '#77ba99'
    ]
    
    export const olympusTheme = extendTheme(defaultTheme, {
      text: {
        primary: '#18202A',
        secondary: '#6C788F',
        highlight: '#18202A'
      },
      checkbox: {
        background: {
          primary: '#18202A'
        },
        text: {
          primary: '#FFFFFF'
        }
      },
      border: {
        primary: 'rgba(255, 255, 255, 0.6)'
      },
      card: {
        background: {
          primary: 'rgba(255, 255, 255, 0.6)'
        }
      },
      tabs: {
        background: {
          primary: '#8993A4',
          secondary: '#FFFFFF'
        },
        text: {
          primary: '#FAFAFA',
          secondary: '#6C788F'
        }
      },
      table: {
        background: {
          primary: 'rgba(255, 255, 255, 0.6)'
        }
      },
      list: {
        background: {
          primary: '#FFFFFF'
        },
        text: {
          primary: '#8993A4',
          secondary: '#18202A',
          highlight: '#87C3F5'
        }
      },
      progress: {
        background: {
          primary: '#2292F3',
          secondary: 'rgba(108, 120, 143, 0.4)'
        }
      },
      form: {
        background: {
          primary: '#8993A4'
        },
        text: {
          primary: '#FFFFFF'
        }
      },
      chartColors: olympusChartColors,
      chartWatermark: OlympusLogo
    })
    
    export const olympusDarkTheme = extendTheme(defaultTheme, {
      text: {
        primary: '#FCFCFC',
        secondary: '#A3A3A3',
        highlight: '#F8CC82'
      },
      checkbox: {
        background: {
          primary: '#FCFCFC'
        },
        text: {
          primary: '#333333'
        }
      },
      border: {
        primary: 'rgba(54, 56, 64, 0.6)'
      },
      card: {
        background: {
          primary: 'rgba(54, 56, 64, 0.6)'
        }
      },
      tabs: {
        background: {
          primary: '#F8CC82',
          secondary: '#1d2026'
        },
        text: {
          primary: '#333333',
          secondary: '#FCFCFC'
        }
      },
      table: {
        background: {
          primary: 'rgba(54, 56, 64, 0.6)'
        }
      },
      list: {
        background: {
          primary: 'rgba(54, 56, 64, 0.6)'
        },
        text: {
          primary: '#FCFCFC',
          secondary: '#A3A3A3',
          highlight: '#F8CC82'
        }
      },
      progress: {
        background: {
          primary: '#F8CC82',
          secondary: 'rgba(54, 56, 64, 0.6)'
        }
      },
      form: {
        background: {
          primary: 'rgba(54, 56, 64, 0.6)'
        },
        text: {
          primary: '#FCFCFC'
        }
      },
      badge: {
        background: {
          primary: 'rgba(54, 56, 64, 0.6)'
        },
        text: {
          primary: '#fff'
        }
      },
      tooltip: {
        background: {
          primary: 'rgba(54, 56, 64, 0.85)'
        },
        border: {
          primary: 'rgba(118, 130, 153, 0.2)'
        },
        text: {
          primary: '#fff'
        }
      },
      chartColors: olympusDarkChartColors,
      chartWatermark: OlympusLogo
    })
`

export const VESQ = `
    import { defaultTheme, extendTheme } from '@multifarm/widget'
    
    export const vesqChartColors = [
      '#218062',
      '#32C396',
      '#218080',
      '#c0bda5',
      '#35605a'
    ]
    
    export const vesqTheme = extendTheme(defaultTheme, {
      text: {
        primary: '#FFFFFF',
        secondary: 'rgba(255, 255, 255, 0.7)',
        highlight: '#32C396'
      },
      checkbox: {
        background: {
          primary: '#32C396'
        },
        text: {
          primary: '#FFFFFF'
        }
      },
      border: {
        primary: '#32C396',
      },
      card: {
        background: {
          primary: '#1E1E1E'
        }
      },
      tabs: {
        background: {
          primary: '#32C396',
          secondary: '#424242'
        },
        text: {
          primary: '#FFFFFF',
          secondary: '#D6E4E7'
        }
      },
      table: {
        background: {
          primary: '#1E1E1E'
        }
      },
      list: {
        background: {
          primary: '#424242'
        },
        text: {
          primary: '#D6E4E7',
          secondary: '#FFFFFF',
          highlight: '#32C396'
        }
      },
      progress: {
        background: {
          primary: '#32C396',
          secondary: '#424242'
        }
      },
      form: {
        background: {
          primary: '#32C396'
        },
        text: {
          primary: '#FFFFFF'
        }
      },
      chartColors: vesqChartColors
    })
`

export const REFI = `
    import { defaultTheme, extendTheme } from '@multifarm/widget'
    
    export const refiChartColors = [
      '#FD536F',
      '#DADADA',
      '#FE8C9B',
      '#F4BE37',
      '#66C8FF',
      '#D733FF',
      '#FF9F40'
    ]
    
    export const refiDarkChartColors = [
      '#bf0603',
      '#f3484e',
      '#541b1f',
      '#773728'
    ]
    
    export const refiTheme = extendTheme(defaultTheme, {
      text: {
        primary: '#161616',
        secondary: '#6F6F6F',
        highlight: '#161616'
      },
      checkbox: {
        background: {
          primary: '#FD5873'
        },
        text: {
          primary: '#FFFFFF'
        }
      },
      border: {
        primary: '#E5E4E5',
        secondary: '#E3353F'
      },
      card: {
        background: {
          primary: '#F7F7F7'
        }
      },
      tabs: {
        background: {
          primary: 'linear-gradient(180deg, #FFB3B7 0%, #E65C63 100%)',
          secondary: '#FFECED'
        },
        text: {
          primary: '#FAFAFA',
          secondary: '#768299'
        },
        border: {
          primary: '#FCD2D3'
        }
      },
      table: {
        background: {
          primary: '#F7F7F7'
        }
      },
      list: {
        background: {
          primary: '#F7F7F7'
        },
        text: {
          primary: '#161616',
          secondary: '#6F6F6F',
          highlight: '#FD5876'
        }
      },
      progress: {
        background: {
          primary: '#FD5873',
          secondary: '#B9B9B9'
        }
      },
      form: {
        background: {
          primary: '#F7F7F7'
        },
        text: {
          primary: '#161616'
        }
      },
      badge: {
        background: {
          primary: '#fff'
        },
        text: {
          primary: '#000'
        }
      },
      chartColors: refiChartColors
    })
    
    export const refiDarkTheme = extendTheme(defaultTheme, {
      text: {
        primary: '#fff',
        secondary: '#a0a0a0',
        highlight: '#fff'
      },
      checkbox: {
        background: {
          primary: '#f3484e'
        },
        text: {
          primary: '#FFFFFF'
        }
      },
      border: {
        primary: 'rgba(255, 255, 255, 0.16)',
        secondary: '#f3484e'
      },
      card: {
        background: {
          primary: '#1c1c1c'
        }
      },
      tabs: {
        background: {
          primary: 'linear-gradient(180deg, #F3484F 0%, #C51E25 100%)',
          secondary: '#541b1f'
        },
        text: {
          primary: '#FFFFFF',
          secondary: '#FFFFFF'
        },
        border: {
          primary: '#541b1f'
        }
      },
      table: {
        background: {
          primary: '#1c1c1c'
        }
      },
      list: {
        background: {
          primary: '#1c1c1c'
        },
        text: {
          primary: '#fff',
          secondary: '#a0a0a0',
          highlight: '#f3484e'
        }
      },
      progress: {
        background: {
          primary: '#f3484e',
          secondary: '#B9B9B9'
        }
      },
      form: {
        background: {
          primary: '#f3484e'
        },
        text: {
          primary: '#fff'
        }
      },
      badge: {
        background: {
          primary: 'rgba(54, 56, 64, 0.6)'
        },
        text: {
          primary: '#fff'
        }
      },
      tooltip: {
        background: {
          primary: 'rgba(54, 56, 64, 0.85)'
        },
        border: {
          primary: 'rgba(118, 130, 153, 0.2)'
        },
        text: {
          primary: '#fff'
        }
      },
      chartColors: refiDarkChartColors
    })
`

export const EXPO = `
    import { defaultTheme, extendTheme } from '@multifarm/widget'
    
    export const expoChartColors = ['#FFB4D7', '#F56FAE', '#DE2179']
    
    export const expoTheme = extendTheme(defaultTheme, {
      text: {
        primary: '#FFFFFF',
        secondary: '#E5E5E5',
        highlight: '#DE2179'
      },
      checkbox: {
        background: {
          primary: '#DE2179'
        },
        text: {
          primary: '#FFFFFF'
        }
      },
      border: {
        primary: '#DE2179'
      },
      card: {
        background: {
          primary: '#161121'
        }
      },
      tabs: {
        background: {
          primary: '#DE2179',
          secondary: '#161121'
        },
        text: {
          primary: '#FFFFFF',
          secondary: '#FFFFFF'
        },
        border: {
          primary: '#DE2179'
        }
      },
      table: {
        background: {
          primary: '#161121'
        }
      },
      list: {
        background: {
          primary: '#161121'
        },
        text: {
          primary: '#FFFFFF',
          secondary: '#FFFFFF',
          highlight: '#DE2179'
        }
      },
      progress: {
        background: {
          primary: '#DE2179',
          secondary: 'rgba(255, 255, 255, 0.15)'
        }
      },
      form: {
        background: {
          primary: '#DE2179'
        },
        text: {
          primary: '#FFFFFF'
        }
      },
      badge: {
        background: {
          primary: '#18202A'
        },
        text: {
          primary: '#FFFFFF'
        }
      },
      tooltip: {
        background: {
          primary: '#161121'
        },
        border: {
          primary: '#DE2179'
        },
        text: {
          primary: '#FFFFFF'
        }
      },
      chartColors: expoChartColors
    })
`

export const FOOD_FARMER = `
    import { defaultTheme, extendTheme } from '@multifarm/widget'
    
    export const foodFarmerChartColors = [
      '#fe815f',
      '#f4a261',
      '#e9c46a',
      '#faa275',
      '#ff8c61'
    ]
    
    export const foodFarmerTheme = extendTheme(defaultTheme, {
      text: {
        primary: '#FFF',
        secondary: '#FFF',
        highlight: '#fe815f'
      },
      checkbox: {
        background: {
          primary: '#fe815f'
        },
        text: {
          primary: '#FFFFFF'
        }
      },
      border: {
        primary: '#20153e',
        secondary: 'rgba(255, 255, 255, 0.15)'
      },
      card: {
        background: {
          primary: '#20153e'
        }
      },
      tabs: {
        background: {
          primary: '#fe815f',
          secondary: 'rgba(255, 255, 255, 0.15)'
        },
        text: {
          primary: '#FFF',
          secondary: '#FFF'
        },
        border: {
          primary: undefined
        }
      },
      table: {
        background: {
          primary: '#20153e'
        }
      },
      list: {
        background: {
          primary: '#20153e'
        },
        text: {
          primary: '#FFF',
          secondary: '#FFF',
          highlight: '#fe815f'
        }
      },
      progress: {
        background: {
          primary: '#fe815f',
          secondary: 'rgba(255, 255, 255, 0.15)'
        }
      },
      form: {
        background: {
          primary: '#20153e'
        },
        text: {
          primary: '#FFF'
        }
      },
      tooltip: {
        background: {
          primary: '#20153e'
        },
        border: {
          primary: '#fe815f'
        },
        text: {
          primary: '#fff'
        }
      },
      chartColors: foodFarmerChartColors
    })
`

export const DIGITS = `
    import { defaultTheme, extendTheme } from '@multifarm/widget'
    
    export const digitsChartColors = ['#83AEFF', '#1244FB', '#092D6A', '#2166E8']
    
    export const digitsTheme = extendTheme(defaultTheme, {
      text: {
        primary: '#040319',
        secondary: '#040319',
        third: '#FFFFFF',
        highlight: '#2166E8'
      },
      checkbox: {
        background: {
          primary: '#2166E8'
        },
        text: {
          primary: '#FFFFFF'
        }
      },
      border: {
        primary: '#04031920',
        secondary: undefined
      },
      card: {
        background: {
          primary: '#FFFFFF'
        }
      },
      tabs: {
        background: {
          primary: '#2166E8',
          secondary: '#1D296A'
        },
        text: {
          primary: '#FFFFFF',
          secondary: '#FFFFFF'
        },
        border: {
          primary: undefined
        }
      },
      table: {
        background: {
          primary:
            'radial-gradient(50% 255.75% at 50% 50%, #00198F 0%, #000002 100%)'
        }
      },
      list: {
        background: {
          primary: '#FFFFFF'
        },
        text: {
          primary: '#040319',
          secondary: '#040319',
          highlight: '#2166E8'
        }
      },
      progress: {
        background: {
          primary: '#2166E8',
          secondary: '#FFFFFF40'
        }
      },
      form: {
        background: {
          primary: '#2166E8'
        },
        text: {
          primary: '#FFFFFF'
        }
      },
      tooltip: {
        background: {
          primary: '#040319'
        },
        border: {
          primary: '#2166E8'
        },
        text: {
          primary: '#FFFFFF'
        }
      },
      badge: {
        background: {
          primary: '#18202A'
        },
        text: {
          primary: '#FFFFFF'
        }
      },
      chartColors: digitsChartColors
    })
`

export const PARAGONS = `
    import { defaultTheme, extendTheme } from '@multifarm/widget'
    
    export const paragonsChartColors = [
      '#999999',
      '#4C4C4C',
      '#5F992E',
      '#78B247',
      '#334D1F'
    ]
    
    export const paragonsTheme = extendTheme(defaultTheme, {
      text: {
        primary: '#FFFFFF',
        secondary: '#FFFFFF70',
        highlight: '#8CC55C'
      },
      checkbox: {
        background: {
          primary: '#8CC55C'
        },
        text: {
          primary: '#141414'
        }
      },
      border: {
        primary: '#292929',
        secondary: undefined
      },
      card: {
        background: {
          primary: '#141414'
        }
      },
      tabs: {
        background: {
          primary: '#8CC55C',
          secondary: '#141414'
        },
        text: {
          primary: '#141414',
          secondary: '#FFFFFF'
        },
        border: {
          primary: '#292929'
        }
      },
      table: {
        background: {
          primary: '#141414',
          secondary: 'rgba(255, 255, 255, 0.1);'
        }
      },
      list: {
        background: {
          primary: '#141414',
          secondary: 'rgba(255, 255, 255, 0.1);'
        },
        text: {
          primary: '#FFFFFF',
          secondary: '#FFFFFF',
          highlight: '#5CB2C5'
        }
      },
      progress: {
        background: {
          primary: '#8CC55C',
          secondary: '#4D4D4D'
        }
      },
      form: {
        background: {
          primary: '#8CC55C'
        },
        text: {
          primary: '#000000'
        }
      },
      tooltip: {
        background: {
          primary: '#141414'
        },
        border: {
          primary: '#8CC55C'
        },
        text: {
          primary: '#F3F3F3'
        }
      },
      chartColors: paragonsChartColors,
      chartWatermark: ParagonsIcon
    }) 
`

export const BALANCER = `
    import { defaultTheme, extendTheme } from '@multifarm/widget'
    
    export const balancerChartColors = [
      '#66C8FF',
      '#E389AB',
      '#D733FF',
      '#F4BE37',
      '#7DFF5A',
      '#FDFF8E',
      '#E4D354',
      '#2B908F',
      '#F45B5B',
      '#91E8E1',
      '#8085E9',
      '#F0B2FF'
    ]
    export const balancerThemeLight = extendTheme(defaultTheme, {
      text: {
        primary: '#0F172A',
        secondary: '#0F172A',
        highlight: '#384AFF'
      },
      checkbox: {
        background: {
          primary: '#384AFF'
        },
        text: {
          primary: '#0F172A'
        }
      },
      border: {
        primary: undefined,
        secondary: '#EAF0F6'
      },
      card: {
        background: {
          primary: '#FFFFFF',
          secondary: '#FFFFFF'
        }
      },
      tabs: {
        background: {
          primary: '#384AFF',
          secondary: '#F8FAFC'
        },
        text: {
          primary: '#FFF',
          secondary: '#0F172A'
        },
        border: {
          primary: undefined
        }
      },
      table: {
        background: {
          primary: '#FFFFFF'
        }
      },
      list: {
        background: {
          primary: '#FFFFFF'
        },
        text: {
          primary: '#0F172A',
          secondary: '#0F172A',
          highlight: '#FED533'
        }
      },
      progress: {
        background: {
          primary: '#FED533',
          secondary: '#1E293B'
        }
      },
      form: {
        background: {
          primary: '#F8FAFC'
        },
        border: {
          primary: '#878B94'
        },
        text: {
          primary: '#0F172A',
          secondary: '#878B94'
        }
      },
      tooltip: {
        background: {
          primary: '#FFFFFF'
        },
        border: {
          primary: '#384AFF'
        },
        text: {
          primary: '#0F172A'
        }
      },
      chartColors: balancerChartColors
    })
    export const balancerTheme = extendTheme(defaultTheme, {
      text: {
        primary: '#FFF',
        secondary: '#FFF',
        highlight: '#FED533'
      },
      checkbox: {
        background: {
          primary: '#FED533'
        },
        text: {
          primary: '#FFFFFF'
        }
      },
      border: {
        primary: undefined,
        secondary: undefined
      },
      card: {
        background: {
          primary: '#162031',
          secondary: '#1E293B'
        }
      },
      tabs: {
        background: {
          primary: '#384AFF',
          secondary: '#1E293B'
        },
        text: {
          primary: '#FFF',
          secondary: '#FFF'
        },
        border: {
          primary: undefined
        }
      },
      table: {
        background: {
          primary: '#162031'
        }
      },
      list: {
        background: {
          primary: '#162031'
        },
        text: {
          primary: '#FFF',
          secondary: '#FFF',
          highlight: '#FED533'
        }
      },
      progress: {
        background: {
          primary: '#FED533',
          secondary: '#1E293B'
        }
      },
      form: {
        background: {
          primary: '#1E293B'
        },
        border: {
          primary: '#8a8f98'
        },
        text: {
          primary: '#FFF',
          secondary: '#8a8f98'
        }
      },
      tooltip: {
        background: {
          primary: '#162031'
        },
        border: {
          primary: '#FED533'
        },
        text: {
          primary: '#fff'
        }
      },
      chartColors: balancerChartColors
    })
`

export const SPHERE = `
    import { defaultTheme, extendTheme } from '@multifarm/widget'
    
    export const sphereChartColors = ['#C215E5', '#06BDEF', '#F47A41', '#D61382']
    
    export const sphereTheme = extendTheme(defaultTheme, {
      text: {
        primary: '#FFFFFF',
        secondary: '#FFFFFF',
        third: '#FFFFFF',
        highlight: '#D51381'
      },
      checkbox: {
        background: {
          primary: '#D51381'
        },
        text: {
          primary: '#FFFFFF'
        }
      },
      border: {
        primary: 'rgba(255, 255, 255, 0.1)',
        secondary: undefined
      },
      card: {
        background: {
          primary: 'rgba(4, 7, 30, 0.5)'
        },
        text: {
          highlight: '#A7C9EE'
        }
      },
      tabs: {
        background: {
          primary: 'linear-gradient(180deg, #2D3EA8 0%, #635EFA 100%)',
          secondary: 'linear-gradient(180deg, #2A286C 0%, #1B1D54 100%)'
        },
        text: {
          primary: '#FFFFFF',
          secondary: '#FFFFFF'
        },
        border: {
          primary: undefined
        }
      },
      table: {
        background: {
          primary: 'linear-gradient(180deg, #2D3EA8 0%, #635EFA 100%)'
        }
      },
      list: {
        background: {
          primary: 'transparent'
        },
        text: {
          primary: '#FFFFFF',
          secondary: '#FFFFFF',
          highlight: '#06BDEF'
        }
      },
      progress: {
        background: {
          primary: '#2D3EA8',
          secondary: '#FFFFFF40'
        }
      },
      form: {
        background: {
          primary: '#2D3EA8'
        },
        text: {
          primary: '#FFFFFF'
        }
      },
      tooltip: {
        background: {
          primary: '#040319'
        },
        border: {
          primary: '#D51381'
        },
        text: {
          primary: '#FFFFFF'
        }
      },
      badge: {
        background: {
          primary: '#18202A'
        },
        text: {
          primary: '#FFFFFF'
        }
      },
      chartColors: sphereChartColors
    })
`

export const OSMOSIS = `
    import { defaultTheme, extendTheme } from '@multifarm/widget'
    export const osmosisChartColors = [
      '#5287D7',
      '#B7AEC3',
      '#38CFFF',
      '#F5CA4C',
      '#26549A',
      '#EF4F8E'
    ]
    export const osmosisTheme = extendTheme(defaultTheme, {
      text: {
        primary: '#FFFFFF',
        secondary: '#8B83AA',
        highlight: '#FFFFFF'
      },
      border: {
        primary: undefined,
        secondary: undefined
      },
      checkbox: {
        background: {
          primary: '#80EAFF'
        },
        text: {
          primary: '#FFFFFF'
        }
      },
      card: {
        background: {
          primary: '#3C356D',
          secondary: '#2D2755',
        }
      },
      tabs: {
        background: {
          primary: '#322DC2',
          secondary: 'rgba(255, 255, 255, 0.2)'
        },
        text: {
          primary: '#FFFFFF',
          secondary: 'rgba(255, 255, 255, 0.5)'
        },
        border: {
          primary: undefined
        }
      },
      table: {
        background: {
          primary: '#2D2755'
        }
      },
      list: {
        background: {
          primary: '#667BCC'
        },
        text: {
          primary: '#FFFFFF',
          secondary: 'rgba(255, 255, 255, 0.5)',
          highlight: '#FFFFFF'
        }
      },
      progress: {
        background: {
          primary: '#5287D7',
          secondary: 'rgba(255, 255, 255, 0.3)'
        }
      },
      form: {
        background: {
          primary: '#667BCC'
        },
        text: {
          primary: '#FFFFFF'
        }
      },
      tooltip: {
        background: {
          primary: '#667BCC'
        },
        border: {
          primary: '#667BCC'
        },
        text: {
          primary: '#FFFFFF'
        }
      },
      chartColors: osmosisChartColors,
      chart: {
        background: {
          primary: '#2D2755'
        }
      },
    })
`
