import React from 'react';
// Components
import { FlippingCard } from '../components/FlippingCard';
import { WithdrawalForm } from '../components/WithdrawalForm';
import { DepositForm } from '../components/DepositForm';
import { LockerForm } from '../components/LockerForm';
import { DepositBackCard, LockingBackCard, WithdrawalBackCard } from '../components/invest/backCards';
// Hooks - API
import { useProtocolMetrics } from '../hooks/api/useProtocolMetrics';
// Icons
import { LogoHorzDarkIcon } from '../assets/icons';
export function Invest() {
    const { primaryMetric: protocolMetrics, isLoading } = useProtocolMetrics({
        token: 'HYBRID'
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("header", { className: "section_header light" },
            React.createElement("div", { className: "page-padding" },
                React.createElement("div", { className: "container-small" },
                    React.createElement("div", { className: "padding-vertical padding-huge" },
                        React.createElement("div", { "data-w-id": "2e2fba83-9934-614f-75ec-1e071b9c89df", className: "text-align-center" },
                            React.createElement(LogoHorzDarkIcon, { width: 27, height: 38 }),
                            React.createElement("div", { className: "margin-bottom margin-small" },
                                React.createElement("h1", { className: "text-color-black" }, "Invest")),
                            React.createElement("div", { className: "divider" }),
                            React.createElement("p", { className: "text-size-medium text-color-black" }, "Welcome to the Invest section. Here you can buy / sell HFI or lock it to receive veHFI. Please click the see more button on each card to learn more."),
                            React.createElement("div", { className: "divider" })))))),
        React.createElement("section", { className: "section_invest" },
            React.createElement("div", { className: "page-padding" },
                React.createElement("div", { className: "container-large" },
                    React.createElement("div", { className: "padding-bottom padding-xxhuge" },
                        React.createElement("div", { className: "w-layout-grid _3-cards_component" },
                            React.createElement(FlippingCard, { backChildren: React.createElement(DepositBackCard, null), sequenceNumber: 1 },
                                React.createElement(DepositForm, { hfiPrice: protocolMetrics === null || protocolMetrics === void 0 ? void 0 : protocolMetrics.price_usd })),
                            React.createElement(FlippingCard, { backChildren: React.createElement(LockingBackCard, null), sequenceNumber: 2 },
                                React.createElement(LockerForm, null)),
                            React.createElement(FlippingCard, { backChildren: React.createElement(WithdrawalBackCard, null), sequenceNumber: 3 },
                                React.createElement(WithdrawalForm, null)))))))));
}
