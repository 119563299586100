import v4 from 'uuid4';
var Nft = /** @class */ (function () {
    function Nft(data) {
        this.id = data.id;
        this.asset = data.asset;
        this.assetAddress = data.assetAddress;
        this.blockchain = data.blockchain;
        this.collection = data.collection;
        this.collectionImageUrl = data.collectionImageUrl;
        this.customAprYearly = data.customAprYearly;
        this.imageUrl = data.imageUrl;
        this.size = data.size;
        this.positionSizeUsd = data.positionSizeUsd;
    }
    return Nft;
}());
export { Nft };
var NftCollection = /** @class */ (function () {
    function NftCollection(data) {
        this.id = v4();
        this.name = data.name || 'Unkown';
        this.imageUrl = data.image_url || undefined;
        this.nfts = data.nfts.map(function (nft) { return new Nft(nft); });
        this.nftsCount = this.nfts.length;
    }
    NftCollection.prototype.buildAssetItem = function () {
        return {
            id: this.id,
            name: this.name,
            balance: this.nfts.length,
            symbol: this.nftsCount > 1 ? 'NFTs' : 'NFT',
            iconUrl: this.imageUrl,
            iconShape: 'square'
        };
    };
    return NftCollection;
}());
export { NftCollection };
