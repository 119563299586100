// Utils
import { formatWalletMoney, getAssetIcon } from '../../utils';
var Asset = /** @class */ (function () {
    function Asset(data) {
        this.id = data.id;
        this.asset = data.asset;
        this.assetAddress = data.assetAddress;
        this.blockchain = data.blockchain;
        this.customAprYearly = data.customAprYearly;
        this.imageUrl = data.imageUrl;
        this.size = data.size;
        this.positionSizeUsd = data.positionSizeUsd;
    }
    Asset.prototype.buildAssetItem = function () {
        return {
            id: this.id,
            name: this.asset,
            balance: this.size,
            symbol: this.asset,
            balanceDetails: formatWalletMoney(this.positionSizeUsd),
            iconUrl: this.imageUrl || getAssetIcon(this.asset)
        };
    };
    return Asset;
}());
export { Asset };
