export const DEFAULT_DATE_FORMAT = 'MMM dd';
export const DATE_FORMAT_SECONDARY = 'dd.MM.YYYY';
export const API_DATE_FORMAT_PRIMARY = 'yyyy.MM.dd';
export const API_DATE_FORMAT_SECONDARY = 'yyyy-MM-dd';
export const CHART_TOOLTIP_DATE_PRIMARY = 'MMMM dd, yyyy';
export const RANGES = [
    { name: '1h', value: '1h' },
    { name: '4h', value: '4h' },
    { name: '24h', value: '24h' },
    { name: '3d', value: '3d' },
    { name: '7d', value: '7d' },
    { name: '30d', value: '30d' }
];
export const DAY_RANGES = [
    { name: '3d', value: '3d' },
    { name: '7d', value: '7d' },
    { name: '30d', value: '30d' }
];
