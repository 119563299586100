import React from 'react';
// Components
import { VaultTab } from './VaultTab';
// Static
import { VaultTabEnum } from '../../../../../../common/static';
// Styles
import './vault-tabs.scss';
export const VaultTabs = ({ activeTab, setTab }) => {
    return (React.createElement("div", { className: "numisme-vault-tabs" },
        React.createElement(VaultTab, { title: VaultTabEnum.DEPOSIT, active: activeTab === VaultTabEnum.DEPOSIT, onClick: () => setTab(VaultTabEnum.DEPOSIT) }),
        React.createElement(VaultTab, { title: VaultTabEnum.WITHDRAW, active: activeTab === VaultTabEnum.WITHDRAW, onClick: () => setTab(VaultTabEnum.WITHDRAW) }),
        React.createElement(VaultTab, { title: VaultTabEnum.BORROW, active: activeTab === VaultTabEnum.BORROW, onClick: () => setTab(VaultTabEnum.BORROW) }),
        React.createElement(VaultTab, { title: VaultTabEnum.REPAY, active: activeTab === VaultTabEnum.REPAY, onClick: () => setTab(VaultTabEnum.REPAY) })));
};
