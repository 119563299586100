import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import useContentLoad from '../hooks/useContentLoad'
import Code from './Code'
import { Link } from 'react-router-dom'

export default function Content({ content }) {
  const contentLoad = useContentLoad({ content })
  return (
    <ReactMarkdown
      rehypePlugins={[rehypeRaw]}
      className="markdown"
      components={{
        code: (props) => {
          if (isCodeJsOrReact(props)) {
            return <Code {...props} />
          }
          if (isBreak(props)) {
            return <br />
          }
          return <code {...excludeProps(props)} />
        },
        a: (props) => {
          if (isRouterLink(props)) {
            return (
              <Link to={props.href} data-router={1}>
                {props.children}
              </Link>
            )
          }
          return (
            <a
              target="_blank"
              rel="noreferrer noopener"
              {...excludeProps(props)}
            />
          )
        }
      }}
    >
      {contentLoad.error || contentLoad.content}
    </ReactMarkdown>
  )
}

function isRouterLink(props) {
  return props.href?.startsWith('/')
}

function isCodeJsOrReact(props) {
  return props.className?.includes('js') || props.className?.includes('react')
}

function isBreak(props) {
  return props.className?.includes('break')
}

function excludeProps({ node: _node, inline: _inline, ...props }) {
  return props
}
