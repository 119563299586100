import { useThemeContext } from '../providers/ThemeProvider'
import { useCallback, useEffect } from 'react'
import Content from '../components/Content'

import ResponsiveContent from '../content/responsive.md'

export default function Responsive() {
  const { theme, themeColors } = useThemeContext()

  const updateTheme = useCallback(() => {
    document
      .getElementById('iframe-1')
      .contentWindow.postMessage({ theme, themeColors }, '*')
    document
      .getElementById('iframe-2')
      .contentWindow.postMessage({ theme, themeColors }, '*')
  }, [theme, themeColors])

  useEffect(() => {
    updateTheme()
  }, [updateTheme])

  return (
    <div className="flex flex-col w-full">
      <Content content={ResponsiveContent} />

      <div className="flex flex-col items-center">
        <div
          style={{ width: 375, height: 700 }}
          className="border-8 border-gray-900 rounded-3xl relative mb-10"
        >
          <iframe
            title="iframe-1"
            id="iframe-1"
            width="100%"
            height="100%"
            src={`${window.location.origin}/iframe-content`}
            onLoad={updateTheme}
          />
        </div>

        <div
          style={{ width: 900, height: 600 }}
          className="border-8 border-gray-900 rounded-3xl relative mb-10"
        >
          <iframe
            title="iframe-2"
            id="iframe-2"
            width="100%"
            height="100%"
            src={`${window.location.origin}/iframe-content`}
            onLoad={updateTheme}
          />
        </div>
      </div>
    </div>
  )
}
