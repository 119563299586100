import { MultifarmProvider, DashboardPro } from '@multifarm/widget'
import { useThemeContext } from '../providers/ThemeProvider'
import { TOKEN } from '../config'
import Content from '../components/Content'
import OlympusContent from '../content/olympus.md'

export default function OlympusProPage() {
  const { theme, themeColors } = useThemeContext()
  return (
    <div className="flex flex-col w-full">
      <Content content={OlympusContent} />

      <MultifarmProvider
        token={TOKEN}
        theme={theme}
        themeColors={themeColors}
        provider={theme}
        key={theme}
        badgePlacement="bottom"
      >
        <DashboardPro />
      </MultifarmProvider>
    </div>
  )
}
