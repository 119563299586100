/**
 * A callback that can be used to sort the historical data by dates (most recent first)
 * @param first - First historical tick.
 * @param second - Second historical tick.
 * @returns Condition indicating if the first element is before, equal or after the second element.
 */
export var sortHistoricalByDate = function (first, second) {
    if (first.date > second.date)
        return -1;
    if (first.date < second.date)
        return 1;
    return 0;
};
