import React, { useState } from 'react';
import { BarChart } from '@multifarm/widget-components';
// Components
import { ChardCard } from '../../../../../components/chart-card/ChardCard';
// Static
import { RangeTypeEnum } from '../../../../../common/static';
import { INTEREST_RATE_BREAKDOWN_CHART } from '../../../../../common/static/placeholder.static';
// Utils
import { formatDate, formatPercent } from '../../../../../common/utils';
export const InterestRateBreakdownChart = () => {
    const [range, setRange] = useState(RangeTypeEnum['14D']);
    return (React.createElement(ChardCard, { title: "Interest Rate Breakdown", rangeOptions: { range, onRange: setRange } },
        React.createElement(BarChart, { data: INTEREST_RATE_BREAKDOWN_CHART.DATA, dataKeys: INTEREST_RATE_BREAKDOWN_CHART.KEYS, colors: INTEREST_RATE_BREAKDOWN_CHART.COLORS, yTickFormatter: (tick) => formatPercent(tick, 0), xTickFormatter: (tick) => formatDate(tick), barOptions: { maxBarSize: 15, radius: 10 }, tooltipOptions: {
                formatTooltipKey: (key) => {
                    if (key == 'vault') {
                        return 'USD/MIM Vault APR';
                    }
                    return 'Borrow APR';
                }
            } })));
};
