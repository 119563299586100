import React from 'react';
import cx from 'clsx';
import s from './PeriodTabs.module.css';
import { useLockerRatio } from '../../../../web3/hooks/useLockerRatio';
const formatMultiplier = (multiplier) => multiplier.toLocaleString(navigator.language, { minimumFractionDigits: 1 });
const PeriodMultiplier = ({ lockerAddress }) => {
    const { maxRatio } = useLockerRatio(lockerAddress);
    return React.createElement("div", { className: s.periodMultiplier },
        formatMultiplier(maxRatio),
        "x");
};
export const PeriodTabs = ({ label, onSelect, selectedPeriod, items, hideMultiplier = false }) => {
    return (React.createElement("div", { className: s.periodTabsWrapper },
        React.createElement("div", { className: s.label }, label),
        React.createElement("div", { className: s.periodTabs }, items.map(({ period, periodLabel, value }) => (React.createElement("button", { key: value, type: "button", className: cx(s.periodTabsButton, {
                [s.periodTabsButtonSelected]: value === selectedPeriod,
                [s.hideMultiplier]: hideMultiplier
            }), onClick: () => onSelect(value) },
            React.createElement("div", { className: "lock-period_month-wrapper" },
                React.createElement("div", { className: "lock-period_month-count" }, period),
                React.createElement("div", { className: s.periodLabel }, periodLabel)),
            !hideMultiplier && React.createElement(PeriodMultiplier, { lockerAddress: value })))))));
};
