import React from 'react';
import { PieChart, DataTable, LoadingPlaceholder, NoDataPlaceholder } from '@multifarm/widget-components';
// Styles
import './pie-chart-table.scss';
export const PieChartTable = ({ data, chartDataKey, tableColumns, colors, isLoading, initialHeight = 200, tooltipOptions }) => {
    if (isLoading) {
        return React.createElement(LoadingPlaceholder, { minHeight: initialHeight });
    }
    if (!data.length) {
        return React.createElement(NoDataPlaceholder, { minHeight: initialHeight });
    }
    return (React.createElement("div", { className: "pie-chart-table" },
        React.createElement(PieChart, { data: data, dataKey: chartDataKey, colors: colors, initialHeight: initialHeight, innerRadius: "80%", tooltipOptions: tooltipOptions }),
        React.createElement(DataTable, { data: data, columns: tableColumns, colors: colors })));
};
