import { ethers } from 'ethers';
import React, { useMemo } from 'react';
import { formatNumber } from '../../common/utils';
export const InvestmentMetric = ({ value, className, unitName = 'ether', decimals = 5, prefix = '' }) => {
    const [num, decimal] = useMemo(() => {
        const formattedValue = typeof value === 'number'
            ? formatNumber(value, decimals, true)
            : ethers.utils.formatUnits(value, unitName);
        return formattedValue.split('.');
    }, [value, unitName, decimals]);
    return (React.createElement("div", { className: className },
        prefix,
        num,
        decimal && React.createElement("sup", null,
            ".",
            decimal.slice(0, decimals))));
};
