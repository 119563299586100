import React, { useState } from 'react';
import cx from 'clsx';
// Icons
import { LessIcon, MoreIcon } from '../../assets/icons';
import './FlippingCard.css';
export const FlippingCard = ({ children, backChildren, sequenceNumber }) => {
    const [isFlipped, setFlipped] = useState(false);
    return (React.createElement("div", { className: cx('flip-card', { 'flip-card__flipped': isFlipped }) },
        React.createElement("div", { className: "flip-card-inner" },
            React.createElement("div", { className: `flip-card__side flip-card-front invest-card-${sequenceNumber}` },
                children,
                React.createElement("button", { className: "cardlip-more w-inline-block", type: "button", onClick: () => setFlipped(true) },
                    React.createElement("div", { className: "text-block-6" }, "Learn More"),
                    React.createElement(MoreIcon, { width: 28, height: 28, className: "cardflip-more" }))),
            React.createElement("div", { className: `flip-card__side flip-card-back invest-card-${sequenceNumber}` },
                React.createElement("div", { className: "card-back_content-container" }, backChildren),
                React.createElement("button", { type: "button", className: "cardflip-less-button", onClick: () => setFlipped(false) },
                    React.createElement(LessIcon, { width: 28, height: 28, className: "cardflip-less" }))))));
};
