import clsx from 'clsx';
import React, { useState } from 'react';
import './nft-card.scss';
export var NftCard = function (_a) {
    var _b;
    var name = _a.name, url = _a.url, details = _a.details, collectionUrl = _a.collectionUrl, onClick = _a.onClick;
    var _c = useState(false), isLoaded = _c[0], setIsLoaded = _c[1];
    return (React.createElement("div", { className: clsx('multifarm-solana-nft-card', {
            clickable: onClick !== undefined
        }), onClick: onClick },
        React.createElement("div", { className: clsx('multifarm-solana-nft-card__image-wrapper', {
                bordered: !url
            }) },
            !url && React.createElement("div", null, "Unknown"),
            url && (React.createElement(React.Fragment, null,
                !isLoaded ? React.createElement("div", null, "Loading...") : null,
                React.createElement("img", { className: clsx('multifarm-solana-nft-card__image', (_b = {},
                        _b['multifarm-solana-nft-card__image_hidden'] = !isLoaded,
                        _b)), src: url, alt: name, onLoad: function () { return setIsLoaded(true); } })))),
        React.createElement("div", { className: "multifarm-solana-nft-card__content" },
            React.createElement("div", { className: "multifarm-solana-nft-card__info" },
                React.createElement("div", { className: "multifarm-solana-nft-card__info-title" }, name),
                details && (React.createElement("div", { className: "multifarm-solana-nft-card__info-subtitle" }, details))),
            collectionUrl && (React.createElement("img", { className: "multifarm-solana-nft-card__collection-image", src: collectionUrl, alt: "collection icon" })))));
};
