import useSwr from 'swr';
import { useMemo } from 'react';
// API - Service
import { buildRoute, fetcher } from '../../services';
// Models
import { WalletStats } from '../../models/domain';
export var useWalletStats = function (addresses) {
    var route = null;
    if (addresses.length > 0) {
        route = buildRoute('/stats/', { addresses: addresses });
    }
    var _a = useSwr(route, fetcher), data = _a.data, error = _a.error;
    var isLoading = !data && !error;
    return useMemo(function () { return ({
        isLoading: isLoading,
        data: data ? new WalletStats(data) : null
    }); }, [isLoading, data]);
};
