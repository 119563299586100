import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
// Utils
import { formatMoney } from '../../../../../../../common/utils';
// Styles
import './repay.scss';
import { Input } from '@multifarm/widget-components';
const REMAINING_LOAN_AMOUNT = 47859;
const REPAY_OPTIONS = [10, 25, 50, 100];
export const RepayTab = () => {
    const [repayAmount, setRepayAmount] = useState('');
    const [percentageOption, setPercentageOption] = useState(undefined);
    /**
     * Handles percentage option select.
     * @param percentage - Selected percentage option.
     */
    const handleOptionSelect = (percentage) => {
        if (percentageOption === percentage) {
            setPercentageOption(undefined);
            setRepayAmount('');
            return;
        }
        setPercentageOption(percentage);
        setRepayAmount((REMAINING_LOAN_AMOUNT * percentage) / 100 + '');
    };
    /**
     * Handles the numeric change by calculating.
     * @param value - Repay numeric value.
     */
    const handleRepayChange = useCallback((value) => {
        const numeric = +value;
        if (isNaN(numeric)) {
            return;
        }
        setPercentageOption(undefined);
        if (numeric === 0) {
            setRepayAmount('0');
            return;
        }
        if (numeric > REMAINING_LOAN_AMOUNT) {
            setRepayAmount(REMAINING_LOAN_AMOUNT + '');
            return;
        }
        setRepayAmount(value);
    }, []);
    return (React.createElement("div", { className: "numisme-vault-tab-repay" },
        React.createElement("div", { className: "numisme-vault-tab-section" },
            React.createElement("span", { className: "text-primary" },
                "Remaining loan amount:",
                ' ',
                React.createElement("span", { className: "text-secondary text-bold" }, formatMoney(REMAINING_LOAN_AMOUNT, 2, true)))),
        React.createElement("div", { className: "numisme-vault-tab-section numisme-vault-tab-section-highlight numisme-vault-tab-repay-options" }, REPAY_OPTIONS.map((repayOption) => {
            return (React.createElement("div", { className: clsx('numisme-vault-tab-repay-option', 'clickable', {
                    'numisme-vault-tab-repay-option-active': percentageOption === repayOption
                }), onClick: () => handleOptionSelect(repayOption) },
                React.createElement("span", null,
                    repayOption,
                    "%")));
        })),
        React.createElement("div", { className: "numisme-vault-tab-section" },
            React.createElement(Input, { onChange: handleRepayChange, value: repayAmount, placeholder: "0", title: "Repay:", className: "text-secondary text-bold" }),
            React.createElement("div", { className: "numisme-vault-tab-action-button clickable text-primary" }, "Repay"))));
};
