// Utils
import { formatMoney, formatPercent } from '../utils';
export var BASE_CHART_KEYS = ['value'];
export var DASHBOARD_CHARTS = {
    COLORS: ['#63C49D', '#79C8E7', '#F2D76B', '#BFACD0', '#D982F8'],
    TOKEN_BREAKDOWN: {
        NO_PLACEHOLDER_DATA: 'No tokens have been found in the wallets.',
        TABLE_COLUMNS: [
            {
                name: '',
                key: '',
                isColorColumn: true,
                width: '1%'
            },
            {
                name: 'Asset',
                key: 'name',
                align: 'left',
                wrap: true,
                width: '10%'
            },
            {
                name: 'Value',
                key: 'holdingsUsd',
                align: 'right',
                wrap: true,
                width: '10%',
                format: function (value) { return formatMoney(value, 2, true); }
            },
            {
                name: 'Distribution',
                key: 'distribution',
                align: 'right',
                wrap: true,
                width: '10%',
                format: function (value) { return formatPercent(value); }
            }
        ],
        TOOLTIP_OPTIONS: {
            categoryKey: 'name',
            formatTooltipKey: function () { return 'Distribution'; },
            formatTooltipValue: function (value) { return formatPercent(value); }
        }
    },
    NFT_BREAKDOWN: {
        NO_PLACEHOLDER_DATA: 'No NFTs have been found in the wallets.',
        TABLE_COLUMNS: [
            {
                name: '',
                key: '',
                isColorColumn: true,
                width: '1%'
            },
            {
                name: 'Asset',
                key: 'name',
                align: 'left',
                wrap: true,
                width: '15%'
            },
            {
                name: 'Value',
                key: 'holdingsUsd',
                align: 'right',
                wrap: true,
                width: '10%',
                format: function (value) { return formatMoney(value, 2, true); }
            }
        ],
        TOOLTIP_OPTIONS: {
            categoryKey: 'name',
            formatTooltipKey: function () { return 'Value'; },
            formatTooltipValue: function (value) { return formatMoney(value, 2, true); }
        }
    }
};
