import React, { useMemo } from 'react';
// Static
import { HYBRID_LOGO_LINK } from '../../common/static/app.static';
// Utils
import { formatMoney, formatPercent } from '../../common/utils/format.utils';
import { getBlockchainIcon } from '../../common/utils/icon.utils';
export const BlockchainDistribution = ({ blockchainShare, pools, blockchain }) => {
    const BlockChainIcon = useMemo(() => {
        let icon = getBlockchainIcon(blockchain);
        if (!icon) {
            icon = () => React.createElement("img", { className: "unkown-metric-logo", src: HYBRID_LOGO_LINK });
        }
        return icon;
    }, [blockchain]);
    return (React.createElement("div", { "data-w-tab": "Tab", className: "treasury-tabs_tab-pane w-tab-pane" },
        React.createElement("div", { className: "treasury_tab-content-wrapper" },
            React.createElement(BlockChainIcon, null),
            React.createElement("div", { className: "metric" }, formatMoney(blockchainShare)),
            React.createElement("div", { className: "treasury_chain-table-info" },
                React.createElement("div", { className: "w-layout-grid treasury-table" },
                    React.createElement("div", { id: "w-node-_1927f4ca-3de1-dc62-45f4-3ce19715bbf6-f3cdef16", className: "table-row header" },
                        React.createElement("div", null, "Asset"),
                        React.createElement("div", { className: "text-align-center" }, "Value"),
                        React.createElement("div", { className: "text-align-center" }, "APY")),
                    pools.map((pool, index) => (React.createElement("div", { id: "w-node-_1927f4ca-3de1-dc62-45f4-3ce19715bbff-f3cdef16", className: "table-row", key: `pool-${index}` },
                        React.createElement("div", null, pool.asset),
                        React.createElement("div", { className: "text-align-center" }, formatMoney(pool.positionSizeUsd)),
                        React.createElement("div", { className: "text-align-center" }, formatPercent(pool.customAprYearly))))))))));
};
