import React from 'react';
// Components
import { HeaderTabs } from './tabs/HeaderTabs';
// Styles
import './header.scss';
export var Header = function (_a) {
    var page = _a.page, setPage = _a.setPage;
    return (React.createElement("div", { className: "multifarm-thor-finance-header" },
        React.createElement(HeaderTabs, { activePage: page, setPage: setPage })));
};
