import React, { useMemo } from 'react';
import { Card, MultipleDomainChart } from '@multifarm/widget-components';
// Utils
import { formatDate, formatNumber, mergeHistoricalChartData, reverse } from '../../../../../common/utils';
// Static
import { CHART_TOOLTIP_DATE_PRIMARY } from '../../../../../common/static';
// Styles
import './total-volume-chart.scss';
export var TotalVolumeChart = function (_a) {
    var metrics = _a.metrics;
    var chart = useMemo(function () {
        if (!metrics) {
            return mergeHistoricalChartData({ charts: [] });
        }
        return mergeHistoricalChartData({
            charts: [
                {
                    name: 'Cumulative',
                    data: reverse(metrics.totalVolumeAvaxCumulativeHistorical)
                },
                {
                    name: 'Daily',
                    data: reverse(metrics.totalVolumeAvaxDailyHistorical)
                }
            ]
        });
    }, [metrics]);
    return (React.createElement(Card, { className: "multifarm-thor-finance-card multifarm-thor-finance-trades-chart-card borderless-grid", title: "TOTAL VOLUME OVER TIME" },
        React.createElement(MultipleDomainChart, { data: chart.chartData, chartOptions: {
                grid: { enable: true, opacity: 0.3 },
                leftYAxis: {
                    width: 80,
                    label: {
                        value: 'Daily',
                        position: 'insideLeft',
                        angle: -90,
                        styles: { fontSize: '20px' }
                    }
                },
                rightYAxis: {
                    width: 80,
                    label: {
                        value: 'Cumulative',
                        position: 'insideRight',
                        angle: 90,
                        styles: { fontSize: '20px' }
                    }
                }
            }, xTickFormatter: function (tick) { return formatDate(tick); }, leftYTickFormatter: function (tick) { return formatNumber(tick, 2, true); }, rightYTickFormatter: function (tick) { return formatNumber(tick, 2, true); }, dataOptions: {
                line: {
                    keys: chart.chartKeys.length > 0 ? ['Cumulative'] : [],
                    colors: ['#f3523f'],
                    yAxisId: 'right'
                },
                bar: {
                    keys: chart.chartKeys.length > 0 ? ['Daily'] : [],
                    colors: ['#F99D2A'],
                    yAxisId: 'left'
                }
            }, tooltipOptions: {
                formatCategory: function (tick) {
                    return formatDate(tick, CHART_TOOLTIP_DATE_PRIMARY);
                },
                formatTooltipValue: function (value) {
                    return "".concat(formatNumber(value, 2, true), " AVAX");
                }
            } })));
};
