// Libs
import React, { useMemo } from 'react';
import { ResponsiveContainer, LineChart as RLineChart, YAxis, XAxis, Line, CartesianGrid, Tooltip } from 'recharts';
// Components
import { EmptyPlaceholder } from '../placeholders';
// Utils
import { formatDate, formatMoney, calcAxisWidth, formatChartTooltipDate } from '../../common/utils';
export const LineChart = ({ data, xAxisProps, yAxisProps, cartesianGridProps, containerProps, lineProps, isLoading, minHeight, renderGradients = () => null, xTickFormatter = (item) => formatDate(item), yTickFormatter = (item) => formatMoney(item, 4, true), showTooltip, tooltipFormatter }) => {
    const yWidth = useMemo(() => !(yAxisProps === null || yAxisProps === void 0 ? void 0 : yAxisProps.width)
        ? calcAxisWidth(data, yTickFormatter)
        : yAxisProps.width, [data]);
    const renderChart = () => {
        if (isLoading) {
            return React.createElement(EmptyPlaceholder, { text: "Loading...", height: minHeight });
        }
        if (!data.length) {
            return React.createElement(EmptyPlaceholder, { height: minHeight });
        }
        return (React.createElement(RLineChart, { data: data },
            renderGradients(),
            React.createElement(YAxis, Object.assign({ allowDataOverflow: false, tickFormatter: yTickFormatter, width: yWidth }, defaultYAxisProps, yAxisProps)),
            React.createElement(XAxis, Object.assign({ tickFormatter: xTickFormatter }, defaultXAxisProps, xAxisProps)),
            showTooltip && (React.createElement(Tooltip, { contentStyle: {
                    background: 'var(--tabs-bg)',
                    color: 'var(--text-color)'
                }, labelStyle: { color: 'var(--text-color)' }, formatter: (value) => tooltipFormatter ? tooltipFormatter(value) : yTickFormatter(value), labelFormatter: (label) => formatChartTooltipDate(label) })),
            React.createElement(CartesianGrid, Object.assign({}, cartesianGridProps)),
            React.createElement(Line, Object.assign({ type: "monotone", dataKey: "value" }, lineProps))));
    };
    return (React.createElement(ResponsiveContainer, Object.assign({ width: "100%", height: "100%", minHeight: minHeight }, containerProps), renderChart()));
};
const defaultYAxisProps = {
    tickMargin: 20,
    tickLine: false,
    type: 'number',
    domain: [(dataMin) => dataMin * 0.999, 'auto'],
    tick: {
        fill: 'var(--text-color, #fff)',
        fontSize: '0.625rem'
    }
};
const defaultXAxisProps = {
    dataKey: 'date',
    tickMargin: 10,
    tickLine: false,
    tick: {
        fill: 'var(--text-color, #fff)',
        fontSize: '0.625rem'
    }
};
LineChart.defaultProps = {
    cartesianGridProps: {},
    yAxisProps: {},
    xAxisProps: {},
    lineProps: {},
    renderGradients: () => null,
    isLoading: false,
    minHeight: 300,
    showTooltip: true
};
