// Utils
import { formatNumber, formatPercent } from '../utils';
export var BASE_CHART_KEYS = ['value'];
export var DISTRIBUTION_CHARTS = {
    KEYCARD: {
        COLORS: ['#F3523F', '#F99D2A'],
        COLUMNS: [
            {
                name: '',
                key: '',
                isColorColumn: true,
                width: '1%'
            },
            {
                name: '',
                key: 'category',
                align: 'left',
                wrap: true,
                width: '10%'
            },
            {
                name: '',
                key: 'value',
                align: 'left',
                wrap: true,
                width: '10%',
                format: function (value) { return formatNumber(value); }
            },
            {
                name: '',
                key: 'percentage',
                align: 'left',
                wrap: true,
                width: '10%',
                format: function (value) { return formatPercent(value); }
            }
        ],
        SKELETON_DATA: [
            {
                category: 'Origin',
                percentage: undefined,
                value: undefined
            },
            {
                category: 'Drift',
                percentage: undefined,
                value: undefined
            }
        ]
    },
    CAPSULE: {
        COLORS: ['#F3523F', '#f8f8f833'],
        COLUMNS: [
            {
                name: '',
                key: '',
                isColorColumn: true,
                width: '1%'
            },
            {
                name: '',
                key: 'category',
                align: 'left',
                wrap: true,
                width: '10%'
            },
            {
                name: '',
                key: 'value',
                align: 'left',
                wrap: true,
                width: '10%',
                format: function (value) { return formatNumber(value); }
            },
            {
                name: '',
                key: 'percentage',
                align: 'left',
                wrap: true,
                width: '10%',
                format: function (value) { return formatPercent(value); }
            }
        ],
        SKELETON_DATA: [
            {
                category: 'Origin',
                percentage: undefined,
                value: undefined
            },
            {
                category: 'Drift',
                percentage: undefined,
                value: undefined
            }
        ]
    }
};
export var NFT_TYPES;
(function (NFT_TYPES) {
    NFT_TYPES["ORIGIN"] = "Origin";
    NFT_TYPES["DRIFT"] = "Drift";
    NFT_TYPES["KEY"] = "Keycards";
    NFT_TYPES["CAPSULE"] = "Capsules";
    NFT_TYPES["PERK"] = "Perks";
    NFT_TYPES["ARTWORK"] = "Artwork";
})(NFT_TYPES || (NFT_TYPES = {}));
export var CAPSULE_ANALYTICS_CHARTS = {
    KEYS: Object.keys(NFT_TYPES),
    COLORS: ['#F3523F', '#F99D2A', '#E2E729', '#5e5e5e', '#000', '#e4e1dc'],
    OPTIONS: {
        xAxis: { tick: { fill: 'var(--primary-text)' } },
        yAxis: { tick: { fill: 'var(--primary-text)' } },
        grid: { enable: true, opacity: 0.3 }
    }
};
export var PERKS_CHART = {
    COLORS: ['#F3523F', '#F99D2A', '#E2E729', '#5e5e5e']
};
export var SOLD_NFTS_CHART = {
    COLUMNS: [
        {
            name: '',
            key: '',
            isColorColumn: true,
            width: '1%'
        },
        {
            name: '',
            key: 'category',
            align: 'left',
            wrap: true,
            width: '10%'
        },
        {
            name: '',
            key: 'value',
            align: 'left',
            wrap: true,
            width: '10%',
            format: function (value) { return formatNumber(value, 0, true); }
        },
        {
            name: '',
            key: 'percentage',
            align: 'left',
            wrap: true,
            width: '10%',
            format: function (value) { return formatPercent(value); }
        }
    ]
};
