import CodeAccordion from '../components/CodeAccordion'
import Code from '../components/Code'

const CODE = `
    import {
      MultifarmProvider,
      Dashboard,
      DASHBOARD_TABS_VARIANTS,
    } from "@multifarm/widget";
    
    <MultifarmProvider
      token={...}
      themeColors="..."
      theme="..."
      provider="..."
      // Here you can override the dashboard tabs that should be rendered. 
      // In this case only PORTFOLIO_ALLOCATION will be rendered as a tab.
      dashboardTabs={[DASHBOARD_TABS_VARIANTS.PORTFOLIO_ALLOCATION]}
    >
      <Dashboard />
    </MultifarmProvider>
`

export default function DashboardTabs() {
  return (
    <div className="flex flex-col w-full">
      <p>Multifarm further provides ways to customise your dashboard.</p>
      <br />
      <p>
        By default, all of the tabs assgined to your dashbaord will be rendered.
        However, you can chose what tabs should the dashboard render by
        providing a <b>dashboardTabs</b> prop to the Mutlfiram provider.
      </p>
      <br />
      <p>
        The <b>DASHBOARD_TABS_VARIANTS</b> contstant holds the various types of
        tabs. Find the ones related to you dashboard and provide them in the
        following manner.
      </p>
      <br />
      <CodeAccordion
        defaultOpen
        title="Dashboard Tabs Customization"
        code={<Code>{CODE}</Code>}
      />
    </div>
  )
}
