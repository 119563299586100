import { PrismAsyncLight as SyntaxHighlighter } from 'react-syntax-highlighter'
import jsx from 'react-syntax-highlighter/dist/cjs/languages/prism/jsx'
import darcula from 'react-syntax-highlighter/dist/esm/styles/prism/darcula'

SyntaxHighlighter.registerLanguage('jsx', jsx)

export default function Code({ children }) {
  return (
    <SyntaxHighlighter language="jsx" style={darcula}>
      {children}
    </SyntaxHighlighter>
  )
}
