// Libs
import React, { useMemo, useState } from 'react';
// Components
import { BarChartCard } from '../../../components/BarChartCard';
import { Tabs } from '../../../components/Tabs';
// Hooks API
import { useLiquidationsVolume } from '../../../hooks/api/useLiquidationsVolume';
// Utils
import { parseRangeChart } from '../../../utils/chart';
// Constants
import { LIQUIDATION_VOLUME_RANGES } from '../../../constants/ranges';
export var LiquidationVolumeChart = function () {
    var _a = useState('weekly'), range = _a[0], setRange = _a[1];
    var _b = useLiquidationsVolume(), isLoading = _b.isLoading, _c = _b.data[0], _d = _c === void 0 ? {} : _c, _e = _d.volume_usd_historical, data = _e === void 0 ? [] : _e;
    var chartData = useMemo(function () { return parseRangeChart(range, data); }, [data, range]);
    var headerComponent = (React.createElement(Tabs, { tabs: LIQUIDATION_VOLUME_RANGES, value: range, onChange: function (value) { return setRange(value); } }));
    return (React.createElement(BarChartCard, { title: "Liquidation volume", data: chartData, dataKeys: ['value'], isLoading: isLoading, headerComponent: headerComponent }));
};
