import React, { useMemo, useState } from 'react';
// Pages
import { GeneralOverview, ValutDetails } from './pages';
// Components
import { Header } from './components/header/Header';
// Static
import { PageEnum } from './common/static/app.static';
export const PAGE_COMPONENTS = {
    [PageEnum.GENERAL_OVERVIEW]: GeneralOverview,
    [PageEnum.VAULT_DETAILS]: ValutDetails
};
export const App = () => {
    const [page, setPage] = useState(PageEnum.GENERAL_OVERVIEW);
    /**
     * Memorising the Page component.
     */
    const Page = useMemo(() => {
        return PAGE_COMPONENTS[page];
    }, [page]);
    return (React.createElement("div", { className: "numisme-app" },
        React.createElement(Header, { page: page, setPage: setPage }),
        React.createElement(Page, null)));
};
