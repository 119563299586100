import React from 'react';
// Components
import { HeaderTab } from './HeaderTab';
// Static
import { PageEnum } from '../../../common/static';
// Styles
import './header-tabs.scss';
export const HeaderTabs = ({ activePage, setPage }) => {
    return (React.createElement("div", { className: "page-header-tabs" },
        React.createElement(HeaderTab, { title: PageEnum.GENERAL_OVERVIEW, onClick: () => setPage(PageEnum.GENERAL_OVERVIEW), active: activePage === PageEnum.GENERAL_OVERVIEW }),
        React.createElement(HeaderTab, { title: PageEnum.VAULT_DETAILS, onClick: () => setPage(PageEnum.VAULT_DETAILS), active: activePage === PageEnum.VAULT_DETAILS })));
};
