// Static
import { NodeName } from '../../../common/static';
export var NODES_CHART_FILTER_TABS = [
    {
        name: 'Origin',
        value: NodeName.ORIGIN
    },
    {
        name: 'Drift',
        value: NodeName.DRIFT
    },
    {
        name: 'Perks',
        value: NodeName.PERK
    }
];
export var NodesChartIntervalEnum;
(function (NodesChartIntervalEnum) {
    NodesChartIntervalEnum["DAILY"] = "Daily";
    NodesChartIntervalEnum["WEEKLY"] = "Weekly";
    NodesChartIntervalEnum["MONTHLY"] = "Monthly";
    NodesChartIntervalEnum["ALL_TIME"] = "All Time";
})(NodesChartIntervalEnum || (NodesChartIntervalEnum = {}));
export var NODES_CHART_INTERVAL_ANALYTICS_MAP = new Map([
    [NodesChartIntervalEnum.DAILY, 'dailyNodesHistorical'],
    [NodesChartIntervalEnum.WEEKLY, 'weeklyNodesHistorical'],
    [NodesChartIntervalEnum.MONTHLY, 'monthlyNodesHistorical'],
    [NodesChartIntervalEnum.ALL_TIME, 'totalSupplyHistorical']
]);
export var NODES_CHART_INTERVAL_TABS = [
    {
        name: NodesChartIntervalEnum.DAILY,
        value: NodesChartIntervalEnum.DAILY
    },
    {
        name: NodesChartIntervalEnum.WEEKLY,
        value: NodesChartIntervalEnum.WEEKLY
    },
    {
        name: NodesChartIntervalEnum.MONTHLY,
        value: NodesChartIntervalEnum.MONTHLY
    },
    {
        name: NodesChartIntervalEnum.ALL_TIME,
        value: NodesChartIntervalEnum.ALL_TIME
    }
];
export var NODES_CHART = {
    KEYS: {
        PERKS: {
            DELTA: 'Delta',
            SIGMA: 'Sigma',
            GAMMA: 'Gamma',
            VOUCHER: 'Voucher',
            ALL: ['Cumulative', 'Delta', 'Sigma', 'Gamma', 'Voucher']
        },
        NODES: {
            THOR: 'Thor',
            ODIN: 'Odin',
            ALL: ['Cumulative', 'Thor', 'Odin']
        }
    },
    COLORS: {
        PERKS: ['#f3523f', '#F99D2A', '#e2e729', '#5e5e5e', '#1a1a1a'],
        NODES: ['#F3523F', '#F99D2A', '#e2e729']
    }
};
