import { useState } from 'react'
import LocalStorage from '../services/LocalStorage'

export default function usePersistState(initialValue, persistKey, json) {
  const [state, setState] = useState(() =>
    LocalStorage.get(persistKey)
      ? json
        ? JSON.parse(LocalStorage.get(persistKey))
        : LocalStorage.get(persistKey)
      : initialValue
  )

  LocalStorage.set(persistKey, json ? JSON.stringify(state) : state)

  return [state, setState]
}
