/**
 * Stringifies URL and the given query parameters.
 * @param url - Base URL
 * @param query - Query parameters.
 * @returns Stringified URL.
 */
export var stringifyURL = function (url, query) {
    if (!query)
        return url;
    var queryParams = Object.keys(query).map(function (key) {
        return Array.isArray(query[key])
            ? buildQueryParamsFromArray(key, query[key])
            : "".concat(key, "=").concat(query[key]);
    });
    var queryString = queryParams.join('&');
    return "".concat(url, "?").concat(queryString);
};
/**
 * Appends query paramaters of the given key to the query string.
 * @param key - Query string.
 * @param queryParam - Query param value.
 * @returns Formatter query string.
 */
var buildQueryParamsFromArray = function (key, queryParam) {
    return queryParam.map(function (item) { return "".concat(key, "=").concat(item); }).join('&');
};
