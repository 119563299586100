import React, { useMemo } from 'react';
import cx from 'clsx';
// Static
import { HYBRID_LOGO_LINK } from '../../common/static/app.static';
// Utils
import { formatMoney } from '../../common/utils/format.utils';
import { getBlockchainIcon } from '../../common/utils/icon.utils';
export const BlockchainShare = ({ blockchain, blockchainShare, onClick = () => { }, className }) => {
    const handleClick = () => {
        onClick(blockchain);
    };
    const BlockChainIcon = useMemo(() => {
        let icon = getBlockchainIcon(blockchain);
        if (!icon) {
            icon = () => React.createElement("img", { className: "unkown-metric-logo", src: HYBRID_LOGO_LINK });
        }
        return icon;
    }, [blockchain]);
    return (React.createElement("button", { type: "button", className: cx('treasury_stat-block w-inline-block w-tab-link', className), onClick: handleClick },
        React.createElement(BlockChainIcon, null),
        React.createElement("div", { className: "metric-label" }, blockchain),
        React.createElement("div", { className: "metric" }, formatMoney(blockchainShare))));
};
