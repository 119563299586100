import clsx from 'clsx';
import React from 'react';
// Styles
import './asset-item.scss';
export var AssetItem = function (_a) {
    var data = _a.data;
    return (React.createElement("div", { className: clsx('multifarm-wallet-info_asset-item', {
            clickable: !!data.onClick,
            'multifarm-wallet-info_asset-item__active': data.active
        }), onClick: data.onClick },
        React.createElement("div", { className: "multifarm-wallet-info_asset-item__info" },
            data.iconUrl && (React.createElement("span", { className: clsx('multifarm-wallet-info_asset-item__info-icon-wrapper', {
                    'multifarm-wallet-info_asset-item__info-icon-wrapper-round': data.iconShape !== 'square'
                }) },
                React.createElement("img", { className: "multifarm-wallet-info_asset-item__info-icon", src: data.iconUrl, alt: data.name }))),
            React.createElement("span", { className: "multifarm-wallet-info_asset-item__info-name" }, data.name)),
        React.createElement("div", { className: "multifarm-wallet-info_asset-item__balance" },
            React.createElement("div", null,
                React.createElement("span", { className: "multifarm-wallet-info_asset-item__balance-token" }, data.balance),
                ' ',
                data.symbol),
            data.balanceDetails && React.createElement("div", null, data.balanceDetails))));
};
