import React, { useCallback, useMemo, useState } from 'react';
import { Slider, Input } from '@multifarm/widget-components';
// Utils
import { formatMoney } from '../../../../../../../common/utils';
// Styles
import './borrow.scss';
const AVAILABLE_LOAN_AMOUNT = 120508;
export const BorrowTab = () => {
    const [loanUSD, setLoanUSD] = useState('');
    const [loanPercentage, setLoanPercentage] = useState(0);
    const MAX_LOAN_AMOUNT = useMemo(() => {
        return AVAILABLE_LOAN_AMOUNT / 2;
    }, []);
    /**
     * Handles the percentage change by calculating the appropriate loan USD amount based on it.
     * @param percentage - Loan percentage value.
     */
    const handlePercentageChange = useCallback((percentage) => {
        setLoanPercentage(percentage);
        if (percentage === 0) {
            setLoanUSD('');
            return;
        }
        const usd = (AVAILABLE_LOAN_AMOUNT * percentage) / 100;
        setLoanUSD(usd + '');
    }, []);
    /**
     * Handles the numeric change by calculating the appropriate loan percentage amount based on it.
     * @param value - Loan numeric value.
     */
    const handleLoanChange = useCallback((value) => {
        const numeric = +value;
        if (isNaN(numeric)) {
            return;
        }
        if (numeric === 0) {
            setLoanUSD('0');
            setLoanPercentage(0);
            return;
        }
        if (numeric > MAX_LOAN_AMOUNT) {
            setLoanUSD(MAX_LOAN_AMOUNT + '');
            setLoanPercentage(50);
            return;
        }
        setLoanPercentage((numeric / AVAILABLE_LOAN_AMOUNT) * 100);
        setLoanUSD(value);
    }, []);
    return (React.createElement("div", { className: "numisme-vault-tab-borrow" },
        React.createElement("div", { className: "numisme-vault-tab-section" },
            React.createElement("span", { className: "text-primary" },
                "Available loan amount:",
                ' ',
                React.createElement("span", { className: "text-secondary text-bold" }, formatMoney(AVAILABLE_LOAN_AMOUNT, 2, true)))),
        React.createElement("div", { className: "numisme-vault-tab-section numisme-vault-tab-borrow-loan-to-value-container" },
            React.createElement("div", { className: "numisme-vault-tab-borrow-loan-to-value  numisme-vault-tab-section-highlight" },
                React.createElement("span", { className: "text-primary " },
                    "Loan-To-Value ",
                    React.createElement("span", { className: "text-secondary text-bold" }, "%")),
                React.createElement(Slider, { value: loanPercentage, onChange: handlePercentageChange, marks: [0, 50], maxValue: 50 })),
            React.createElement("div", { className: "numisme-vault-tab-borrow-loan-to-value  numisme-vault-tab-section-highlight" },
                React.createElement("span", { className: "text-primary" },
                    "Loan-To-Value ",
                    React.createElement("span", { className: "text-secondary text-bold" }, "$")),
                React.createElement(Input, { onChange: handleLoanChange, value: loanUSD, placeholder: "0", title: "Borrow:", className: "text-secondary text-bold" }))),
        React.createElement("span", { className: "text-secondary numisme-vault-tab-section-title" }, "Loan Repayment Forecast"),
        React.createElement("span", { className: "text-primary numisme-vault-tab-section-highlight text-center" }, "Estimated Date for Full Repayment: 20/06/2023"),
        React.createElement("div", { className: "numisme-vault-tab-action-button clickable text-primary" }, "Borrow")));
};
