export var PageEnum;
(function (PageEnum) {
    PageEnum["GENERAL_OVERVIEW"] = "General Overview";
    PageEnum["VAULT_DETAILS"] = "Vault Details";
})(PageEnum || (PageEnum = {}));
export var VaultTabEnum;
(function (VaultTabEnum) {
    VaultTabEnum["DEPOSIT"] = "Deposit";
    VaultTabEnum["WITHDRAW"] = "Withdraw";
    VaultTabEnum["BORROW"] = "Borrow";
    VaultTabEnum["REPAY"] = "Repay";
})(VaultTabEnum || (VaultTabEnum = {}));
