import { HorizontalBarChart } from '@multifarm/widget-components';
import React, { useState } from 'react';
import { Card } from '../../../components/Card';
import { Select } from '../../../components/Select';
import { useChartColors } from '../../../hooks/useChartColors';
import { formatChartMoney } from '../../../utils/format';
import s from '../overview.module.css';
var BAR_CHART_DATA = [
    {
        auUSDC: '90293.00',
        category: '',
        x: 0,
        auWBTC: '5.00',
        auUSDT: '9817.00',
        auWNEAR: '2335.00',
        auSTNEAR: '0.00'
    },
    {
        auUSDC: '49367.00',
        category: '',
        x: 122.13,
        auWBTC: '5.00',
        auUSDT: '9780.00',
        auWNEAR: '200.00',
        auSTNEAR: '0.00'
    },
    {
        auUSDC: '48337.00',
        category: '',
        x: 244.26,
        auWBTC: '5.00',
        auUSDT: '9765.00',
        auWNEAR: '200.00',
        auSTNEAR: '0.00'
    },
    {
        auUSDC: '35374.00',
        category: '',
        x: 366.39,
        auWBTC: '5.00',
        auUSDT: '7776.00',
        auWNEAR: '145.00',
        auSTNEAR: '0.00'
    },
    {
        auUSDC: '30053.00',
        category: '',
        x: 488.52,
        auWBTC: '5.00',
        auUSDT: '7708.00',
        auWNEAR: '86.00',
        auSTNEAR: '0.00'
    },
    {
        auUSDC: '18009.00',
        category: '',
        x: 610.65,
        auWBTC: '0.00',
        auUSDT: '6168.00',
        auWNEAR: '64.00',
        auSTNEAR: '0.00'
    },
    {
        auUSDC: '12796.00',
        category: '',
        x: 732.79,
        auWBTC: '0.00',
        auUSDT: '6156.00',
        auWNEAR: '64.00',
        auSTNEAR: '0.00'
    },
    {
        auUSDC: '6654.00',
        category: '',
        x: 854.92,
        auWBTC: '0.00',
        auUSDT: '762.00',
        auWNEAR: '0.00',
        auSTNEAR: '0.00'
    },
    {
        auUSDC: '180.00',
        category: '',
        x: 977.05,
        auWBTC: '0.00',
        auUSDT: '466.00',
        auWNEAR: '0.00',
        auSTNEAR: '0.00'
    }
];
var OPTIONS = [
    { label: 'ETH', value: 'ETH' },
    { label: 'STNEAR', value: 'STNEAR' },
    { label: 'USDS', value: 'USDS' },
    { label: 'USDT', value: 'USDT' },
    { label: 'WBTC', value: 'WBTC' },
    { label: 'WNEAR', value: 'WNEAR' }
];
var chartOptions = {
    xAxis: {
        type: 'number',
        stroke: 'var(--text-color-primary)',
        line: true,
        tick: { fill: 'var(--text-color-secondary)' }
    },
    yAxis: {
        type: 'number',
        reversed: true,
        stroke: 'var(--text-color-primary)',
        tick: { fill: 'var(--text-color-secondary)' },
        padding: {
            top: 30,
            bottom: 30
        }
    },
    grid: {
        enable: true,
        vertical: false,
        stroke: 'var(--text-color-primary)',
        opacity: '0.5',
        strokeDasharray: '5 5'
    },
    referenceLine: {
        enable: true,
        y: 800,
        stroke: 'var(--text-color-primary)',
        strokeWidth: 2,
        label: {
            value: 'Current',
            fill: 'white',
            position: 'top',
            'text-anchor': 'end'
        }
    }
};
export var LiquidationOverviewChart = function () {
    var colors = useChartColors(BAR_CHART_DATA.length);
    var _a = useState('ETH'), selectedValue = _a[0], setSelectedValue = _a[1];
    return (React.createElement(Card, { titleComponent: React.createElement(Select, { className: "multifarm-widget-bastion-select", options: OPTIONS, value: selectedValue, onChange: setSelectedValue }), footerComponent: "Total Liquidatable Amount", footerClassName: s.liquidationOverviewFooter },
        React.createElement("div", { className: s.liquidationOverviewContent },
            React.createElement("div", { className: s.liquidationOverviewLabel }, "Collateral Price Level"),
            React.createElement(HorizontalBarChart, { data: BAR_CHART_DATA, colors: colors, xTickFormatter: formatChartMoney, yAxisDataKey: "x", chartOptions: chartOptions, dataKeys: ['auUSDC', 'auWBTC', 'auUSDT', 'auWNEAR', 'auSTNEAR'] }))));
};
