var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
// Context
import { useWeb3Context } from '../../web3/contexts/web3.context';
// Utils
import { getBalance, getWalletAddress } from '../../web3/utils/helpers';
export const MaxAmountButton = ({ title, contractAddress, onClick, className }) => {
    const { provider } = useWeb3Context();
    const handleClick = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!provider) {
            return;
        }
        const address = yield getWalletAddress(provider);
        const balance = yield getBalance(provider, contractAddress, address);
        onClick(balance);
    });
    return (React.createElement("button", { className: className, type: "button", disabled: !provider, onClick: handleClick }, title || 'Max'));
};
