import React, { useMemo, useState } from 'react';
import { Card, MultipleDomainChart } from '@multifarm/widget-components';
// Fragments
import { NodesChartHeader } from './header';
// Static
import { NodesChartIntervalEnum, NODES_CHART } from '../../../static';
import { NodeName, NODE_NAME_ANALYTICS_MAP, CHART_TOOLTIP_DATE_PRIMARY } from '../../../../../common/static';
// Utils
import { capitalize, formatDate, formatNumber, mergeHistoricalChartData, reverse } from '../../../../../common/utils';
// Styles
import './nodes-chart.scss';
export var NodesChart = function (_a) {
    var analytics = _a.analytics;
    var _b = useState(NodeName.ORIGIN), activeFilter = _b[0], setActiveFilter = _b[1];
    var _c = useState(NodesChartIntervalEnum.MONTHLY), activeInterval = _c[0], setActiveInterval = _c[1];
    /**
     * Memorising the nodes analytics based on the filter selection.
     */
    var nodesAnalytics = useMemo(function () {
        var analyticsPath = NODE_NAME_ANALYTICS_MAP.get(activeFilter);
        if (!analyticsPath) {
            return null;
        }
        return analytics[analyticsPath.base];
    }, [analytics, activeFilter]);
    var chartKeys = useMemo(function () {
        if (activeFilter === NodeName.PERK)
            return NODES_CHART.KEYS.PERKS.ALL.map(function (item) { return "".concat(capitalize(activeFilter), " ").concat(item); });
        else
            return NODES_CHART.KEYS.NODES.ALL.map(function (item) { return "".concat(capitalize(activeFilter), " ").concat(item); });
    }, [activeFilter]);
    /**
     * Memorising the merged chart data.
     */
    var chart = useMemo(function () {
        if (!nodesAnalytics) {
            return mergeHistoricalChartData({ charts: [] });
        }
        var charts = [];
        if (nodesAnalytics.total) {
            charts.push({
                name: 'Cumulative',
                data: reverse(nodesAnalytics.total.totalSupplyHistorical)
            });
        }
        if (activeFilter === NodeName.PERK) {
            if (nodesAnalytics.delta) {
                charts.push({
                    name: "".concat(capitalize(activeFilter), " ").concat(NODES_CHART.KEYS.PERKS.DELTA),
                    data: reverse(nodesAnalytics.delta.dailyNodesHistorical)
                });
            }
            if (nodesAnalytics.sigma) {
                charts.push({
                    name: "".concat(capitalize(activeFilter), " ").concat(NODES_CHART.KEYS.PERKS.SIGMA),
                    data: reverse(nodesAnalytics.sigma.dailyNodesHistorical)
                });
            }
            if (nodesAnalytics.gamma) {
                charts.push({
                    name: "".concat(capitalize(activeFilter), " ").concat(NODES_CHART.KEYS.PERKS.GAMMA),
                    data: reverse(nodesAnalytics.gamma.dailyNodesHistorical)
                });
            }
            if (nodesAnalytics.voucher) {
                charts.push({
                    name: "".concat(capitalize(activeFilter), " ").concat(NODES_CHART.KEYS.PERKS.VOUCHER),
                    data: reverse(nodesAnalytics.voucher.dailyNodesHistorical)
                });
            }
        }
        else {
            if (nodesAnalytics.thor) {
                charts.push({
                    name: "".concat(capitalize(activeFilter), " ").concat(NODES_CHART.KEYS.NODES.THOR),
                    data: reverse(nodesAnalytics.thor.dailyNodesHistorical)
                });
            }
            if (nodesAnalytics.odin) {
                charts.push({
                    name: "".concat(capitalize(activeFilter), " ").concat(NODES_CHART.KEYS.NODES.ODIN),
                    data: reverse(nodesAnalytics.odin.dailyNodesHistorical)
                });
            }
        }
        return mergeHistoricalChartData({
            charts: charts,
            //filterCallback: (value) => value != 0
        });
    }, [nodesAnalytics, activeInterval]);
    return (React.createElement(Card, { className: "multifarm-thor-finance-card multifarm-thor-finance-nodes-chart-card borderless-grid", title: "NUMBER OF ASSETS CREATED", headerComponent: React.createElement(NodesChartHeader, { chartKeys: chartKeys, chartColours: activeFilter === NodeName.PERK ? NODES_CHART.COLORS.PERKS : NODES_CHART.COLORS.NODES, activeFilter: activeFilter, setActiveFilter: setActiveFilter }) },
        React.createElement(MultipleDomainChart, { data: chart.chartData, chartOptions: {
                grid: { enable: true, opacity: 0.3 },
                leftYAxis: {
                    width: 80,
                    label: {
                        value: "".concat(capitalize(activeFilter), " Daily"),
                        position: 'insideLeft',
                        angle: -90,
                        styles: { fontSize: '20px' }
                    }
                },
                rightYAxis: {
                    width: 80,
                    label: {
                        value: 'Cumulative',
                        position: 'insideRight',
                        angle: 90,
                        styles: { fontSize: '20px' }
                    }
                }
            }, leftYTickFormatter: function (tick) { return formatNumber(tick, 2, true); }, rightYTickFormatter: function (tick) { return formatNumber(tick, 2, true); }, dataOptions: {
                line: {
                    keys: chart.chartKeys.length > 0 ? ['Cumulative'] : [],
                    colors: ['#f3523f'],
                    yAxisId: 'right'
                },
                bar: {
                    keys: chart.chartKeys.length > 0
                        ? chartKeys
                        : [],
                    colors: activeFilter === NodeName.PERK ? NODES_CHART.COLORS.PERKS : NODES_CHART.COLORS.NODES,
                    yAxisId: 'left',
                    stack: 1
                }
            }, xTickFormatter: function (tick) { return formatDate(tick); }, tooltipOptions: {
                formatTooltipValue: function (tick) {
                    return tick.toLocaleString('en', {
                        maximumFractionDigits: 2
                    });
                },
                formatCategory: function (tick) { return formatDate(tick, CHART_TOOLTIP_DATE_PRIMARY); }
            }, barOptions: { maxBarSize: 24 } })));
};
