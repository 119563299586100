import React from 'react';
// Components
import { InfoCards, InterestRateBreakdownChart, UtilisationChart, Vaults } from './components';
export const ValutDetails = () => {
    return (React.createElement("div", { className: "vault-details-page" },
        React.createElement(InfoCards, null),
        React.createElement(Vaults, null),
        React.createElement(InterestRateBreakdownChart, null),
        React.createElement(UtilisationChart, null)));
};
