import React, { useState } from 'react';
import { LineChart } from '@multifarm/widget-components';
// Components
import { ChardCard } from '../../../../../components/chart-card/ChardCard';
// Static
import { RangeTypeEnum } from '../../../../../common/static';
import { VAULT_APY_CHART } from '../../../../../common/static/placeholder.static';
// Utils
import { formatDate, formatPercent } from '../../../../../common/utils';
export const VaultApyChart = () => {
    const [range, setRange] = useState(RangeTypeEnum['14D']);
    return (React.createElement(ChardCard, { title: "Vault APY", rangeOptions: { range, onRange: setRange } },
        React.createElement(LineChart, { data: VAULT_APY_CHART.DATA, dataKeys: VAULT_APY_CHART.KEYS, colors: VAULT_APY_CHART.COLORS, yTickFormatter: (tick) => formatPercent(tick, 0), xTickFormatter: (tick) => formatDate(tick), tooltipOptions: {
                formatTooltipValue: (tick) => formatPercent(tick, 0)
            }, chartOptions: { yAxis: { width: 70 } }, legends: VAULT_APY_CHART.KEYS })));
};
