export var getRandomColor = function (colors) {
    if (colors === void 0) { colors = []; }
    return colors[Math.floor(Math.random() * colors.length)];
};
export var getRandomColors = function (colors, count) {
    if (count === void 0) { count = 1; }
    var colorsArr = [];
    while (colorsArr.length < count) {
        var color = getRandomColor(colors);
        if (!colorsArr.includes(color) || colorsArr.length >= colors.length) {
            colorsArr.push(color);
        }
    }
    return colorsArr;
};
