import {
  HermesV2Background,
  LogoIcon,
  SphereBackground,
  MetavaultBackground,
  ViralCoinBackground,
  NumismeBackground
} from './assets/icons'
import { Routes, Route, Link, useLocation } from 'react-router-dom'
import { renderToStaticMarkup } from 'react-dom/server'
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai'
import Invalid from './pages/Invalid'
import Dashboard from './pages/Dashboard'
import Responsive from './pages/Responsive'
import IframeContent from './pages/IframeContent'
import { ThemeProvider } from './providers/ThemeProvider'
import OlympusProPage from './pages/OlympusPro'
import ProteusPage from './pages/Proteus'
import Content from './components/Content'
import DashboardTabs from './pages/DashboardTabs'
import Themes from './pages/Themes'
import { useEffect, useState } from 'react'
import usePersistState from './hooks/usePersistState'
import clsx from 'clsx'
import { applyRawStyles, removeRawStyles } from './utils/theme'
import Splash from './components/Splash'
import './App.scss'

import HomeContent from './content/index.md'
import GettingsStartedContent from './content/gettings-started.md'
import ConfigurationContent from './content/configuration.md'
import CustomizationContent from './content/customization.md'
import AdvancedCustomization from './content/advances-customization.md'
import StrategiesConfigContent from './content/strategies-config.md'
import CategoriesConfigContent from './content/categories-config.md'

import expoCss from '!!raw-loader!@multifarm/widget/dist/expo.css'
import refiCss from '!!raw-loader!@multifarm/widget/dist/refi.css'
import foodFarmerCss from '!!raw-loader!@multifarm/widget/dist/food-farmer.css'
import digitsCss from '!!raw-loader!@multifarm/widget/dist/digits.css'
import paragonsCss from '!!raw-loader!@multifarm/widget/dist/paragons.css'
import redactedCss from '!!raw-loader!@multifarm/widget/dist/redacted.css'
import maiaCss from '!!raw-loader!@multifarm/widget/dist/maia.css'
import alchemixCss from '!!raw-loader!@multifarm/widget/dist/alchemix.css'
import sphereCss from '!!raw-loader!@multifarm/widget/dist/sphere.css'
import voltageCss from '!!raw-loader!@multifarm/widget/dist/voltage.css'
import balancerCss from '!!raw-loader!@multifarm/widget/dist/balancer.css'
import osmosisCss from '!!raw-loader!@multifarm/widget/dist/osmosis.css'
import hybridCss from '!!raw-loader!multifarm-hybrid-finance/dist/hybrid.css'
import hermesV2Css from '!!raw-loader!@multifarm/widget/dist/hermes-v2.css'
import layer2DaoCss from '!!raw-loader!@multifarm/widget/dist/layer2-dao.css'
import viralCoinCss from '!!raw-loader!@multifarm/widget/dist/viral-coin.css'
import bastionCss from '!!raw-loader!@multifarm/bastion/dist/bastion.css'
import numismeCss from '!!raw-loader!@multifarm/numisme/dist/numisme.css'
import thorFinancialCss from '!!raw-loader!@multifarm/thor-finance/dist/thor-finance.css'
import solanaRealmsCss from '!!raw-loader!@multifarm/solana-realms/dist/multifarm-solana-realms.css'

function createPage(route, name, Component, only) {
  return { route, name, Component, only }
}

const PAGES = [
  { title: 'General' },
  createPage('/', 'Home', () => <Content content={HomeContent} />),
  createPage('/getting-started', 'Getting Started', () => (
    <Content content={GettingsStartedContent} />
  )),
  createPage('/configuration', 'Configuration', () => (
    <Content content={ConfigurationContent} />
  )),
  createPage('/invalid', 'Invalid Installation', Invalid),
  { title: 'Dashboards' },
  createPage('/Dashboard', 'Dashboard', Dashboard),
  createPage('/OlympusPro', 'Olympus Pro', OlympusProPage, ['olympus']),
  createPage('/Proteus', 'Proteus', ProteusPage, ['olympus']),
  { title: 'Customization' },
  createPage('/customization', 'Customization', () => (
    <Content content={CustomizationContent} />
  )),
  createPage('/advanced-customization', 'Advanced', () => (
    <Content content={AdvancedCustomization} />
  )),
  createPage('/themes', 'Themes', Themes),
  createPage('/responsive', 'Mobile UI & Responsive', Responsive),
  createPage('/strategies-config', 'Strategies Config', () => (
    <Content content={StrategiesConfigContent} />
  )),
  createPage('/categories-config', 'Categories Config', () => (
    <Content content={CategoriesConfigContent} />
  )),
  createPage('/dashboard-tabs', 'Dashboard Tabs', DashboardTabs)
]

const BG_DARK_OLYMPUS =
  'linear-gradient(180deg, rgba(8, 15, 53, 0), rgba(0, 0, 10, 0.9)), linear-gradient(333deg, rgba(153, 207, 255, 0.2), rgba(180, 255, 217, 0.08)), radial-gradient(circle at 77% 89%, rgba(125, 163, 169, 0.8), rgba(125, 163, 169, 0) 50%), radial-gradient(circle at 15% 95%, rgba(125, 163, 169, 0.8), rgba(125, 163, 169, 0) 43%), radial-gradient(circle at 65% 23%, rgba(137, 151, 119, 0.4), rgba(137, 151, 119, 0) 70%), radial-gradient(circle at 10% 0%, rgba(187, 211, 204, 0.33), rgba(187,211,204,0) 35%), radial-gradient(circle at 11% 100%, rgba(131, 165, 203, 0.3), rgba(131, 165, 203, 0) 30%)'

const sphereSvgString = encodeURIComponent(
  renderToStaticMarkup(<SphereBackground />)
)

const hermesV2SvgString = encodeURIComponent(
  renderToStaticMarkup(<HermesV2Background />)
)

const metavaultSvgString = encodeURIComponent(
  renderToStaticMarkup(<MetavaultBackground />)
)

const viralCoinSvgString = encodeURIComponent(
  renderToStaticMarkup(<ViralCoinBackground />)
)

const numismeSvgString = encodeURIComponent(
  renderToStaticMarkup(<NumismeBackground />)
)

function App() {
  const [splash, setSplash] = useState(false)
  const [isMenuHidden, setIsMenuHidden] = useState(false)
  const location = useLocation()
  const [theme, setTheme] = usePersistState('olympus', 'multifarm/theme')
  const [themeColors, setThemeColors] = usePersistState(
    'light',
    'multifarm/themeColors'
  )
  const [lng, setLng] = usePersistState('en', 'multifarm/lng')
  const [expoProvider, onExpoProvider] = usePersistState(
    'Farming',
    'multifarm/expo-provider'
  )
  const [alchemixProvider, onAlchemixProvider] = usePersistState(
    'Treasury',
    'multifarm/alchemix-provider'
  )

  const [layer2DaoProvider, onLayer2DaoProvider] = usePersistState(
    'Tokenomics',
    'multifarm/layer2dao-provider'
  )
  const isIframe = location.pathname === '/iframe-content' || isMenuHidden

  useEffect(() => {
    window.addEventListener('message', (e) => {
      if (e.data.theme) {
        setTheme(e.data.theme)
        setThemeColors(e.data.themeColors)
      }
    })
  }, [])

  // const isOlympus = theme === 'olympus'
  const isDark = themeColors === 'dark'

  useEffect(() => {
    removeRawStyles('expo')
    removeRawStyles('refi')
    removeRawStyles('food-farmer')
    removeRawStyles('digits')
    removeRawStyles('paragons')
    removeRawStyles('redacted')
    removeRawStyles('maia')
    removeRawStyles('sphere')
    removeRawStyles('alchemix')
    removeRawStyles('sphere')
    removeRawStyles('voltage')
    removeRawStyles('balancer')
    removeRawStyles('osmosis')
    removeRawStyles('hybrid')
    removeRawStyles('hermes-v2')
    removeRawStyles('layer2-dao')
    removeRawStyles('viral-coin')
    removeRawStyles('bastion')
    removeRawStyles('numisme')
    removeRawStyles('thor-financial')
    removeRawStyles('solana-realms')

    if (theme === 'expo') {
      applyRawStyles('expo', expoCss)
    } else if (theme === 'refi') {
      applyRawStyles('refi', refiCss)
    } else if (theme === 'food-farmer') {
      applyRawStyles('food-farmer', foodFarmerCss)
    } else if (theme === 'digits') {
      applyRawStyles('digits', digitsCss)
    } else if (theme === 'paragons') {
      applyRawStyles('paragons', paragonsCss)
    } else if (theme === 'redacted') {
      applyRawStyles('redacted', redactedCss)
    } else if (theme === 'maia') {
      applyRawStyles('maia', maiaCss)
    } else if (theme === 'alchemix') {
      applyRawStyles('alchemix', alchemixCss)
    } else if (theme === 'sphere') {
      applyRawStyles('sphere', sphereCss)
    } else if (theme === 'voltage') {
      applyRawStyles('voltage', voltageCss)
    } else if (theme === 'balancer') {
      applyRawStyles('balancer', balancerCss)
    } else if (theme === 'osmosis') {
      applyRawStyles('osmosis', osmosisCss)
    } else if (theme === 'hybrid') {
      applyRawStyles('hybrid', hybridCss)
    } else if (theme === 'hermes-v2') {
      applyRawStyles('hermes-v2', hermesV2Css)
    } else if (theme === 'layer2-dao') {
      applyRawStyles('layer2-dao', layer2DaoCss)
    } else if (theme === 'viral-coin') {
      applyRawStyles('viral-coin', viralCoinCss)
    } else if (theme === 'bastion') {
      applyRawStyles('bastion', bastionCss)
    } else if (theme === 'numisme') {
      applyRawStyles('numisme', numismeCss)
    } else if (theme === 'thor-financial') {
      applyRawStyles('thor-financial', thorFinancialCss)
    } else if (theme === 'solana-realms') {
      applyRawStyles('solana-realms', solanaRealmsCss)
    }

    return () => {
      document.querySelectorAll('#multifarm-widget-theme').forEach((node) => {
        node.remove()
      })
    }
  }, [theme])

  useEffect(() => {
    document.body.style.fontFamily = rootStyle(themeColors, theme).fontFamily
    document.body.style.color = rootStyle(themeColors, theme).color
  })

  const onSplash = () => {
    setSplash(true)
    setTimeout(() => {
      setSplash(false)
    }, 1000)
  }

  return (
    <ThemeProvider
      theme={theme}
      themeColors={themeColors}
      lng={lng}
      onExpoProvider={onExpoProvider}
      expoProvider={expoProvider}
      alchemixProvider={alchemixProvider}
      onAlchemixProvider={onAlchemixProvider}
      layer2DaoProvider={layer2DaoProvider}
      onLayer2DaoProvider={onLayer2DaoProvider}
    >
      <div
        className="h-screen min-h-screen"
        style={rootStyle(themeColors, theme)}
      >
        {isMenuHidden && (
          <button
            className="nav-menu-button"
            onClick={() => setIsMenuHidden(false)}
          >
            <AiOutlineMenu />
          </button>
        )}
        {!isIframe && (
          <div
            className="navbar fixed z-20 w-80 bg-stone-100 h-screen overflow-x-auto py-10"
            style={drawerStyle(themeColors, theme)}
          >
            <div className="flex flex-col mb-10 mb-6">
              <div className="flex justify-center">
                <LogoIcon />
              </div>
            </div>

            <div className="px-10 mb-10">
              <a
                href="https://www.npmjs.com/package/@multifarm/widget"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className="font-bold hover:underline">
                  {process.env.REACT_APP_VERSION}
                </p>
              </a>
              <button
                className="nav-close-button"
                onClick={() => setIsMenuHidden(true)}
              >
                <AiOutlineClose />
              </button>
            </div>

            <div className="px-10 mb-10">
              <select
                className={getSelectClsx(theme, isDark)}
                value={theme}
                onChange={(e) => {
                  setTheme(e.target.value)
                  onSplash()
                }}
                style={selectStyle()}
              >
                <option>squiddao</option>
                <option>olympus</option>
                <option>vesq</option>
                <option>refi</option>
                <option>expo</option>
                <option>food-farmer</option>
                <option>digits</option>
                <option>paragons</option>
                <option>redacted</option>
                <option>maia</option>
                <option>alchemix</option>
                <option>sphere</option>
                <option>voltage</option>
                <option>umami</option>
                <option>balancer</option>
                <option>osmosis</option>
                <option>hybrid</option>
                <option>hermes-v2</option>
                <option>layer2-dao</option>
                <option>metavault</option>
                <option>viral-coin</option>
                <option>thor</option>
                <option>bastion</option>
                <option>numisme</option>
                <option>thor-financial</option>
                <option>solana-realms</option>
              </select>

              <select
                className={getSelectClsx(theme, isDark)}
                value={themeColors}
                onChange={(e) => {
                  setThemeColors(e.target.value)
                  onSplash()
                }}
                style={selectStyle()}
              >
                <option>light</option>
                <option>dark</option>
              </select>

              <select
                className={getSelectClsx(theme, isDark)}
                value={lng}
                onChange={(e) => setLng(e.target.value)}
                style={selectStyle()}
              >
                <option value="en">EN</option>
                <option value="de">DE</option>
              </select>
            </div>

            <div className="flex flex-col px-10">
              {PAGES.filter((page) =>
                page.only ? page.only.includes(theme) : true
              ).map((page) =>
                page.title ? (
                  <p className="py-2 font-bold text-xl" key={page.title}>
                    {page.title}
                  </p>
                ) : (
                  <Link key={page.route} to={page.route}>
                    <p className={getLinkClsx(location.pathname, page.route)}>
                      {page.name}
                    </p>
                  </Link>
                )
              )}
            </div>
          </div>
        )}

        <div
          className={clsx('w-screen min-h-screen', {
            'pl-80': !isIframe
          })}
          style={bodyStyle(themeColors, theme)}
        >
          {splash ? (
            <Splash />
          ) : (
            <div
              className={clsx({
                'p-10': !isIframe,
                'p-5': isIframe
              })}
            >
              <Routes>
                {PAGES.filter((page) => !page.title).map((page) => (
                  <Route
                    key={page.route}
                    path={page.route}
                    element={<page.Component />}
                  />
                ))}
                <Route path="/iframe-content" element={<IframeContent />} />
              </Routes>
            </div>
          )}
        </div>
      </div>
    </ThemeProvider>
  )
}

export default App

function drawerStyle(themeColors, theme) {
  return {
    background: '#fff',
    ...(themeColors === 'dark' &&
      theme === 'olympus' && {
        background: 'rgba(54, 56, 64, 0.6)'
      }),
    ...(themeColors === 'light' &&
      theme === 'olympus' && {
        background: '#E0F2FE'
      }),
    ...(theme === 'vesq' && {
      background: '#1E1E1E'
    }),
    ...(theme === 'food-farmer' && {
      background: '#eee'
    }),
    ...(theme === 'expo' && {
      background: '#161121'
    }),
    ...(theme === 'refi' && {
      ...(themeColors === 'dark' && {
        backgroundColor: '#170a0f'
      })
    }),
    ...(theme === 'food-farmer' && {
      background: '#270974'
    }),
    ...(theme === 'digits' && {
      background: '#040319'
    }),
    ...(theme === 'paragons' && {
      background: '#141414'
    }),
    ...(theme === 'redacted' && {
      background: '#040309'
    }),
    ...(theme === 'maia' && {
      background:
        'linear-gradient(197.3deg, #072154 0%, #345FB0 22.92%, #9B74D1 48.96%, #B67ADA 64.69%, #8A62BC 82.29%, #453B8D 100%)'
    }),
    ...(theme === 'sphere' && {
      background: '#04071f'
    }),
    ...(theme === 'alchemix' && {
      background: '#181E28'
    }),
    ...(theme === 'sphere' && {
      background: '#181E28'
    }),
    ...(theme === 'voltage' && {
      background: '#202230'
    }),
    ...(theme === 'balancer' && {
      background: '#0F172A'
    }),
    ...(themeColors === 'light' &&
      theme === 'balancer' && {
        background: '#FFF'
      }),
    ...(theme === 'osmosis' && {
      background: '#3C356D'
    }),
    ...(theme === 'hermes-v2' && {
      background: 'rgba(36, 28, 60, 1)'
    }),
    ...(theme === 'layer2-dao' && {
      background: 'rgba(5, 24, 28, 1)'
    }),
    ...(theme === 'metavault' && {
      background: 'rgba(43, 38, 85, 1)'
    }),
    ...(theme === 'thor' && {
      background: '#141720',
      color: '#fff'
    }),
    ...(theme === 'viral-coin' && {
      background: 'rgba(38, 36, 51, 1)'
    }),
    ...(theme === 'bastion' && {
      background: '#171717',
      color: '#fff'
    }),
    ...(theme === 'numisme' && {
      background: 'rgb(129,70,104)',
      color: '#fff'
    }),
    ...(theme === 'thor-financial' && {
      background: '#ffffff',
      color: '#000'
    }),
    ...(theme === 'solana-realms' && {
      background: '#17161c',
      color: '#ffffff'
    })
  }
}

function bodyStyle(themeColors, theme) {
  return {
    background: '#fff',
    ...(theme === 'olympus' &&
      themeColors === 'light' && {
        background: '#E0F2FE'
      }),
    ...(theme === 'olympus' &&
      themeColors === 'dark' && {
        background: BG_DARK_OLYMPUS,
        backgroundColor: 'rgba(8, 15, 53, 1)',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed'
      }),
    ...(theme === 'vesq' && {
      background: '#1E1E1E'
    }),
    ...(theme === 'refi' && {
      background:
        'linear-gradient(180deg, #FFEAEA 0%, rgba(255, 234, 234, 0) 100%)',
      backgroundColor: '#fff',

      ...(themeColors === 'dark' && {
        background:
          'linear-gradient(rgb(60, 24, 26) 0%, rgba(60, 24, 26, 0) 100%)',
        backgroundColor: '#161616'
      })
    }),
    ...(theme === 'food-farmer' && {
      // background: 'linear-gradient(127.94deg, #4917c7 27.22%, #270974 86.68%)'
      background: '#270974'
    }),
    ...(theme === 'expo' && {
      background: '#161121'
    }),
    ...(theme === 'digits' && {
      background: '#040319'
    }),

    ...(theme === 'paragons' && {
      background: '#141414'
    }),
    ...(theme === 'redacted' && {
      background: '#040309'
    }),
    ...(theme === 'maia' && {
      background:
        'linear-gradient(197.3deg, #072154 0%, #345FB0 22.92%, #9B74D1 48.96%, #B67ADA 64.69%, #8A62BC 82.29%, #453B8D 100%)'
    }),
    ...(theme === 'sphere' && {
      backgroundColor: '#04071E',
      background: `url("data:image/svg+xml,${sphereSvgString}")`,
      backgroundSize: 'cover'
    }),
    ...(theme === 'alchemix' && {
      background: '#181E28'
    }),
    ...(theme === 'sphere' && {
      background: '#181E28'
    }),
    ...(theme === 'voltage' && {
      background: '#000'
    }),
    ...(theme === 'balancer' && {
      background: '#0F172A'
    }),
    ...(theme === 'balancer' &&
      themeColors === 'light' && {
        background: '#FFF'
      }),
    ...(theme === 'osmosis' && {
      background: '#170F34'
    }),
    ...(theme === 'hermes-v2' && {
      backgroundColor: '#5119AE',
      background: `url("data:image/svg+xml,${hermesV2SvgString}")`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
    }),
    ...(theme === 'layer2-dao' && {
      background: 'rgba(3, 3, 9, 1)'
    }),
    ...(theme === 'metavault' && {
      backgroundColor: 'rgba(11, 8, 60, 1)',
      background: `url("data:image/svg+xml,${metavaultSvgString}")`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '35% 15%',
      backgroundSize: '40%'
    }),
    ...(theme === 'thor' && {
      background: '#141720'
    }),
    ...(theme === 'viral-coin' && {
      background: `url("data:image/svg+xml,${viralCoinSvgString}")`,
      backgroundSize: 'cover'
    }),
    ...(theme === 'bastion' && {
      background: '#202020'
    }),
    ...(theme === 'numisme' && {
      background: `url("data:image/svg+xml,${numismeSvgString}")`,
      backgroundColor: '#0C164F',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '35% 0%'
    }),
    ...(theme === 'thor-financial' && {
      background: '#ffffff',
      color: '#000000'
    }),
    ...(theme === 'solana-realms' && {
      background: '#17161c',
      color: '#ffffff'
    })
  }
}

function rootStyle(themeColors, theme) {
  return {
    color: '#000',
    fontFamily: 'Inter',

    ...(((theme === 'olympus' && themeColors === 'dark') ||
      theme === 'vesq' ||
      theme === 'expo' ||
      theme === 'paragons') && {
      color: '#fff'
    }),
    ...(theme === 'expo' && {
      fontFamily: '"Franktion"'
    }),
    ...(theme === 'refi' && {
      ...(themeColors === 'dark' && {
        color: '#fff'
      })
    }),
    ...(theme === 'food-farmer' && {
      color: '#fff',
      fontFamily: 'Outfit'
    }),
    ...(theme === 'digits' && {
      color: '#fff',
      fontFamily: 'Poppins'
    }),
    ...(theme === 'redacted' && {
      color: '#fff',
      fontFamily: 'Hind'
    }),
    ...(theme === 'maia' && {
      color: '#fff',
      fontFamily: 'Montserrat'
    }),
    ...(theme === 'sphere' && {
      color: '#fff'
    }),
    ...(theme === 'alchemix' && {
      color: '#fff',
      fontFamily: 'Montserrat'
    }),
    ...(theme === 'sphere' && {
      color: '#fff',
      fontFamily: 'Open Sans'
    }),
    ...(theme === 'voltage' && {
      color: '#fff'
    }),
    ...(theme === 'balancer' && {
      color: '#fff',
      fontFamily: 'Inter'
    }),
    ...(theme === 'balancer' &&
      themeColors === 'light' && {
        color: '#0F172A'
      }),
    ...(theme === 'osmosis' && {
      color: '#fff'
    }),
    ...(theme === 'hermes-v2' && {
      color: '#fff'
    }),
    ...(theme === 'layer2-dao' && {
      color: '#fff',
      fontFamily: 'Inter'
    }),
    ...(theme === 'metavault' && {
      color: '#fff',
      fontFamily: 'Inter'
    }),
    ...(theme === 'thor' && {
      background: '#141720',
      color: '#fff'
    }),
    ...(theme === 'viral-coin' && {
      color: '#fff',
      fontFamily: 'DM Sans'
    }),
    ...(theme === 'bastion' && {
      background: '#202020',
      color: '#fff'
    }),
    ...(theme === 'numisme' && {
      background: '#202020',
      color: '#fff',
      fontFamily: 'DM Sans'
    }),
    ...(theme === 'thor-financial' && {
      background: '#ffffff',
      color: '#000000',
      fontFamily: 'DM Sans'
    }),
    ...(theme === 'solana-realms' && {
      background: '#17161c',
      color: '#ffffff',
      fontFamily: 'DM Sans'
    })
  }
}

export function getSelectClsx(theme, isDark) {
  return clsx('w-full py-2.5 px-4 rounded-xl cursor-pointer mb-4', {
    'bg-stone-200': !(theme === 'olympus' && isDark),
    'bg-stone-600': theme === 'olympus' && isDark,
    'bg-sky-300': theme === 'olympus' && !isDark,
    'bg-pink-600': theme === 'expo',
    'bg-rose-900': theme === 'refi' && isDark,
    'bg-orange-500': theme === 'food-farmer' || theme === 'thor-financial',
    'bg-blue-900': theme === 'digits',
    'bg-lime-700': theme === 'paragons',
    'bg-stone-800':
      theme === 'redacted' ||
      theme === 'alchemix' ||
      theme === 'sphere' ||
      theme === 'voltage' ||
      theme === 'hermes-v2',
    'bg-indigo-400': theme === 'maia',
    'bg-yellow-500': (theme === 'balancer' && isDark) || theme === 'numisme',
    'bg-blue-500': theme === 'balancer' && !isDark,
    'bg-indigo-800': theme === 'osmosis',
    'bg-black': theme === 'layer2-dao',
    'bg-slate-700': theme === 'metavault',
    'bg-yellow-400': theme === 'thor',
    'bg-zinc-600': theme === 'viral-coin',
    'bg-green-600': theme === 'bastion',
    'bg-cyan-400': theme === 'solana-realms'
  })
}

export function selectStyle() {
  return {
    MozAppearance: 'none',
    WebkitAppearance: 'none',
    appearance: 'none'
  }
}

export function getLinkClsx(pathname, route) {
  return clsx('py-3 px-3 hover:font-semibold', {
    'font-bold': pathname === route
  })
}
