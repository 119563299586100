var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useState, useEffect } from 'react';
// Services
import { fetchStreamData } from '../../services';
// Models
import { NftCollection } from '../../models/domain';
/**
 * Hook to send a request to get a nfts of the given wallet address.
 * @param walletAddress - Wallet address string.
 * @returns NFTs if loaded.
 */
export var useNFTs = function (walletAddress) {
    var _a = useState({
        data: null,
        error: null,
        loading: false
    }), streamData = _a[0], setStreamData = _a[1];
    var successHandler = function (response) {
        var rawCollections = response[walletAddress.toLowerCase()];
        if (!rawCollections) {
            var keys = Object.keys(response);
            rawCollections = keys.length > 0 ? response[keys[0]] : [];
        }
        var collections = (rawCollections === null || rawCollections === void 0 ? void 0 : rawCollections.map(function (collection) { return new NftCollection(collection); })) || [];
        setStreamData(__assign(__assign({}, streamData), { data: collections, loading: false }));
    };
    var errorHandler = function (error) {
        setStreamData(__assign(__assign({}, streamData), { error: error, loading: false }));
    };
    var loadHandler = function () {
        setStreamData(__assign(__assign({}, streamData), { loading: false }));
    };
    useEffect(function () {
        fetchStreamData("/balance/nfts/".concat(walletAddress), {
            successHandler: successHandler,
            errorHandler: errorHandler,
            loadHandler: loadHandler
        });
    }, []);
    return streamData;
};
