/**
 * Capitalises the given word.
 * @param word
 * @returns Capitalized string.
 */
export var capitalize = function (word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
};
/**
 * Get shorten wallet address.
 * @param walletAddress
 * @param symbols
 * @returns short wallet string.
 */
export var getShortWalletAddress = function (walletAddress, symbols) {
    if (symbols === void 0) { symbols = 5; }
    var start = walletAddress.slice(0, symbols);
    var end = walletAddress.slice(-symbols);
    return "".concat(start, "...").concat(end);
};
