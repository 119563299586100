import React, { useMemo } from 'react';
import { ethers } from 'ethers';
// Hooks
import { useBalance } from '../web3/hooks/useBalance';
import { useHFIBalance } from '../web3/hooks/useHFIBalance';
import { useNextUnlockTime } from '../web3/hooks/useNextUnlockTime';
import { useNextTime } from '../web3/hooks/useNextTime';
import { useMaxUserLockInfo } from '../web3/hooks/useMaxUserLockInfo';
import { useAddress } from '../web3/hooks/useAddress';
import { useCustomerInvestment } from '../hooks/api/useCustomerInvestment';
// Components
import { LockerStats } from '../components/LockerStats/LockerStats';
import { InvestmentMetric } from '../components/InvestmentMetric/InvestmentMetric';
// Static
import { VEHYBRID_ADDRESS, LOCKER_3_ADDRESS, LOCKER_6_ADDRESS, LOCKER_12_ADDRESS } from '../constants';
// Utils
import { formatNumberString, formatPercent, getDifferenceInStringFormat } from '../common/utils';
// Icons
import { LogoHorzDarkIcon } from '../assets/icons';
const periods = [
    {
        period: '3 Months',
        address: LOCKER_3_ADDRESS
    },
    {
        period: '6 Months',
        address: LOCKER_6_ADDRESS
    },
    {
        period: '12 Months',
        address: LOCKER_12_ADDRESS
    }
];
export function MyInvestment() {
    // Tokens balance
    const balanceHFI = useHFIBalance();
    const balanceVeHFI = useBalance(VEHYBRID_ADDRESS);
    const { address } = useAddress();
    const { data: investmentData } = useCustomerInvestment(address);
    // Get the system wide unlock time
    const { nextTime } = useNextTime(LOCKER_3_ADDRESS);
    // Get user's max unlock time.
    const { maxLockInfo } = useMaxUserLockInfo();
    // Get the next unlock time.
    const { nextUnlockTime } = useNextUnlockTime({
        nextTime,
        userLockInfo: maxLockInfo
    });
    const dateDifferenceString = useMemo(() => getDifferenceInStringFormat(new Date(nextUnlockTime)), [nextUnlockTime]);
    const usdAmount = useMemo(() => {
        const amount = ethers.utils.formatUnits(balanceHFI.usdAmount, 'mwei');
        return formatNumberString(amount, 2);
    }, [balanceHFI.balance, balanceHFI.usdAmount]);
    return (React.createElement(React.Fragment, null,
        React.createElement("header", { className: "section_header light" },
            React.createElement("div", { className: "page-padding" },
                React.createElement("div", { className: "container-small" },
                    React.createElement("div", { className: "padding-vertical padding-huge" },
                        React.createElement("div", { "data-w-id": "b697fa6f-e63f-c47c-6eb6-8487e684c3ee", className: "text-align-center" },
                            React.createElement("div", { className: "margin-bottom margin-small" },
                                React.createElement(LogoHorzDarkIcon, { width: 27, height: 38 }),
                                React.createElement("h1", { className: "text-color-black" }, "My Investment")),
                            React.createElement("div", { className: "divider" }),
                            React.createElement("p", { className: "text-size-medium text-color-black" }, "The My Investment section allows you to view all the important metrics of your position within Hybrid Finance."),
                            React.createElement("div", { className: "divider" })))))),
        React.createElement("section", { className: "section_stats light" },
            React.createElement("div", { className: "page-padding" },
                React.createElement("div", { className: "container-medium" },
                    React.createElement("div", { className: "padding-bottom padding-xxhuge" },
                        React.createElement("div", { "data-w-id": "b8a1c0ad-f900-49a9-0316-7fefca482051", "data-current": "Tab 4", "data-easing": "ease", "data-duration-in": "300", "data-duration-out": "100", className: "tabs-2 w-tabs" },
                            React.createElement("div", { className: "tabs-menu-three w-tab-menu" },
                                React.createElement("a", { "data-w-tab": "Tab 1", className: "tab-link w-inline-block w-tab-link" },
                                    React.createElement("div", { className: "metric-wrapper" },
                                        React.createElement("div", { className: "metric-label dark" }, "Tokens"),
                                        React.createElement("div", { className: "metric dark" },
                                            React.createElement(InvestmentMetric, { className: "metric dark", value: balanceHFI.balance })),
                                        React.createElement("div", { className: "metric-detail dark" },
                                            "$ ",
                                            usdAmount)),
                                    React.createElement("div", { className: "vertical-divider dark" })),
                                investmentData && (React.createElement("a", { "data-w-tab": "Tab 2", className: "tab-link w-inline-block w-tab-link" },
                                    React.createElement("div", { className: "metric-wrapper" },
                                        React.createElement("div", { className: "metric-label dark" }, "ROI"),
                                        React.createElement(InvestmentMetric, { className: "metric dark", value: investmentData.holdings_usd, prefix: "$ ", decimals: 2 }),
                                        React.createElement("div", { className: "metric-detail dark" },
                                            investmentData.roi > 0 ? '+' : '',
                                            formatPercent(investmentData.roi * 100, 2))),
                                    React.createElement("div", { className: "vertical-divider dark" }))),
                                React.createElement("a", { "data-w-tab": "Tab 3", className: "tab-link w-inline-block w-tab-link" },
                                    React.createElement("div", { className: "metric-wrapper" },
                                        React.createElement("div", { className: "metric-label dark regular-case" }, "veHFI"),
                                        React.createElement(InvestmentMetric, { className: "metric dark", value: balanceVeHFI.balance })),
                                    React.createElement("div", { className: "vertical-divider dark" })),
                                React.createElement("a", { "data-w-tab": "Tab 4", className: "tab-link w-inline-block w-tab-link w--current" },
                                    React.createElement("div", { className: "metric-wrapper" },
                                        React.createElement("div", { className: "metric-label dark" }, "Unlock in"),
                                        React.createElement("div", { className: "metric dark" }, balanceVeHFI.balance.isZero()
                                            ? 'No veHFI Holdings'
                                            : dateDifferenceString)))),
                            !balanceVeHFI.balance.isZero() && (React.createElement("table", null,
                                React.createElement("thead", null,
                                    React.createElement("tr", null,
                                        React.createElement("th", { className: "metric dark" }, "Period"),
                                        React.createElement("th", { className: "metric dark" }, "veHFI"),
                                        React.createElement("th", { className: "metric dark" }, "Unlock in"))),
                                React.createElement("tbody", null, periods.map(({ address, period }) => (React.createElement(LockerStats, { key: address, period: period, lockerAddress: address })))))))))))));
}
