import useSwr from 'swr';
// Models
import { ThorNode } from '../../models/domain';
export var useThorNodes = function () {
    var _a = useSwr('/auth/thor_nodes'), response = _a.data, error = _a.error;
    var isLoading = !response && !error;
    var data = (response !== null && response !== void 0 ? response : {}).data;
    return {
        isLoading: isLoading,
        data: data ? data.map(function (node) { return new ThorNode(node); }) : []
    };
};
