import React from 'react';
// Components
import { ChardCard } from '../../../../../components/chart-card/ChardCard';
import { PieChartTable } from '../../../../../components/charts/pie-chart-table/PieChartTable';
// Static
import { LOAN_BREAKDOWN_CHART } from '../../../../../common/static/placeholder.static';
// Utils
import { capitalize, formatPercent } from '../../../../../common/utils';
export const LoanBreakdownChart = () => {
    return (React.createElement(ChardCard, { title: "Loan Breakdown" },
        React.createElement(PieChartTable, { data: LOAN_BREAKDOWN_CHART.DATA, chartDataKey: "breakdown", tableColumns: LOAN_BREAKDOWN_CHART.COLUMNS, colors: LOAN_BREAKDOWN_CHART.COLORS, tooltipOptions: {
                formatTooltipValue: (tick) => formatPercent(tick),
                formatTooltipKey: (key) => capitalize(key)
            } })));
};
