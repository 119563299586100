import React from 'react';
import { PieChart, DataTable, LoadingPlaceholder, NoDataPlaceholder } from '@multifarm/widget-components';
// Styles
import './pie-chart-table.scss';
export var PieChartTable = function (_a) {
    var data = _a.data, chartDataKey = _a.chartDataKey, tableColumns = _a.tableColumns, colors = _a.colors, isLoading = _a.isLoading, _b = _a.initialHeight, initialHeight = _b === void 0 ? 200 : _b, tooltipOptions = _a.tooltipOptions;
    if (isLoading) {
        return React.createElement(LoadingPlaceholder, { minHeight: initialHeight });
    }
    if (!data.length) {
        return React.createElement(NoDataPlaceholder, { minHeight: initialHeight });
    }
    return (React.createElement("div", { className: "pie-chart-table" },
        React.createElement(PieChart, { data: data, dataKey: chartDataKey, colors: colors, initialHeight: initialHeight, innerRadius: "75%", stroke: "var(--pie-chart-stroke)", tooltipOptions: tooltipOptions }),
        React.createElement(DataTable, { data: data, columns: tableColumns, colors: colors })));
};
