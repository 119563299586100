import React, { useMemo } from 'react';
import { Card } from '@multifarm/widget-components';
// Components
import { PieChartTable } from '../../../../../components/charts/pie-chart-table/PieChartTable';
// Static
import { DISTRIBUTION_CHARTS } from '../../../../../common/static/chart.static';
// Utils
import { formatPercent } from '../../../../../common/utils';
// Styles
import './distribution-charts.scss';
export var DistributionCharts = function (_a) {
    var analytics = _a.analytics;
    var keycardData = useMemo(function () {
        var total = analytics.keycardNodes.total;
        if (!total) {
            return DISTRIBUTION_CHARTS.KEYCARD.SKELETON_DATA;
        }
        return [
            {
                category: 'Origin',
                percentage: total.rarePercentage,
                value: (total.totalSupply * total.rarePercentage) / 100
            },
            {
                category: 'Drift',
                percentage: total.commonPercentage,
                value: (total.totalSupply * total.commonPercentage) / 100
            }
        ];
    }, [analytics.keycardNodes]);
    var capsuleData = useMemo(function () {
        var total = analytics.capsuleNodes.total;
        if (!total) {
            return DISTRIBUTION_CHARTS.CAPSULE.SKELETON_DATA;
        }
        return [
            {
                category: 'Origin',
                percentage: total.rarePercentage,
                value: (total.totalSupply * total.rarePercentage) / 100
            },
            {
                category: 'Drift',
                percentage: total.commonPercentage,
                value: (total.totalSupply * total.commonPercentage) / 100
            }
        ];
    }, [analytics.keycardNodes]);
    return (React.createElement("div", { className: "multifarm-thor-finance-distribution-charts" },
        React.createElement(Card, { className: "multifarm-thor-finance-card multifarm-thor-finance-distribution-chart-card", title: "KEYCARD DISTRIBUTION" },
            React.createElement(PieChartTable, { data: keycardData, chartDataKey: "percentage", tableColumns: DISTRIBUTION_CHARTS.KEYCARD.COLUMNS, colors: DISTRIBUTION_CHARTS.KEYCARD.COLORS, tooltipOptions: {
                    formatTooltipKey: function () { return 'Distribution'; },
                    formatTooltipValue: function (value) { return formatPercent(value); }
                } })),
        React.createElement(Card, { className: "multifarm-thor-finance-card multifarm-thor-finance-distribution-chart-card", title: "CAPSULE DISTRIBUTION" },
            React.createElement(PieChartTable, { data: capsuleData, chartDataKey: "percentage", tableColumns: DISTRIBUTION_CHARTS.KEYCARD.COLUMNS, colors: DISTRIBUTION_CHARTS.KEYCARD.COLORS, tooltipOptions: {
                    formatTooltipKey: function () { return 'Distribution'; },
                    formatTooltipValue: function (value) { return formatPercent(value); }
                } }))));
};
