import dateFormat from 'date-fns/format';
import dateParse from 'date-fns/parse';
// Static
import { API_DATE_FORMAT_SECONDARY, CHART_TOOLTIP_DATE_PRIMARY, DEFAULT_DATE_FORMAT } from '../static';
// Utils
import { isNotEmpty } from './global.utils';
/**
 * Formats the number to the string value.
 * @param number - Number to format.
 * @param fixed - Number of decimals.
 * @param includeZero - Should the zero be a valid result?
 * @returns Formatted money string.
 */
export var formatNumber = function (number, fixed, includeZero) {
    if (fixed === void 0) { fixed = 2; }
    if (includeZero === void 0) { includeZero = false; }
    if (!number || isNaN(Number(number))) {
        return includeZero ? (0).toFixed(fixed) : 'N/A';
    }
    var isNegative = number < 0;
    number = Math.abs(number);
    var formattedNumber;
    var regex = /\.?0+$/;
    if (number >= 1000000000) {
        formattedNumber =
            (number / 1000000000).toFixed(fixed).replace(regex, '') + 'B';
    }
    else if (number >= 1000000) {
        formattedNumber =
            (number / 1000000).toFixed(fixed).replace(regex, '') + 'M';
    }
    else if (number >= 1000) {
        formattedNumber = (number / 1000).toFixed(fixed).replace(regex, '') + 'k';
    }
    else {
        formattedNumber = number.toFixed(fixed).replace(regex, '');
    }
    if (isNegative) {
        formattedNumber = '-' + formattedNumber;
    }
    return formattedNumber;
};
/**
 * Formats the number to the string money value.
 * @param number - Number to format.
 * @param fixed - Number of decimals.
 * @param includeZero - Should the zero be a valid result?
 * @returns Formatted money string.
 */
export var formatMoney = function (number, fixed, includeZero) {
    if (fixed === void 0) { fixed = 2; }
    if (includeZero === void 0) { includeZero = false; }
    if (!number || isNaN(Number(number))) {
        return includeZero ? "$ ".concat((0).toFixed(fixed)) : 'N/A';
    }
    return "$ ".concat(formatNumber(number, fixed));
};
/**
 * Formats the given number to the percentage string.
 * @param number - Number to format.
 * @param fixed - Number of decimals.
 * @returns Formated percent string.
 */
export function formatPercent(number, fixed) {
    if (fixed === void 0) { fixed = 2; }
    if (!isNotEmpty(number) || isNaN(Number(number)))
        return 'N/A';
    return number || number === 0 ? "".concat(Number(number).toFixed(fixed), "%") : 'N/A';
}
/**
 * Formats the given date by the given locale and options.
 * @param date - Date string, number or object.
 * @param format - Date format.
 * @param parseFormat - Parse format.
 * @returns Formated string.
 */
export var formatDate = function (date, format, parseFormat) {
    if (format === void 0) { format = DEFAULT_DATE_FORMAT; }
    if (parseFormat === void 0) { parseFormat = API_DATE_FORMAT_SECONDARY; }
    try {
        var dateToFormat = typeof date === 'string'
            ? dateParse(date, parseFormat, new Date())
            : date;
        return dateFormat(dateToFormat, format);
    }
    catch (e) {
        return '';
    }
};
/**
 * Formats the given date for chart tooltips.
 * @param date - Date string or number.
 * @returns Formated string.
 */
export var formatChartTooltipDate = function (date) {
    return date ? formatDate(date, CHART_TOOLTIP_DATE_PRIMARY) : 'N/A';
};
export var formatWalletMoney = function (value) {
    return value.toLocaleString('en', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
    });
};
export var formatAsLocalNumber = function (value, options) {
    if (options === void 0) { options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 10
    }; }
    return value.toLocaleString('en', options);
};
