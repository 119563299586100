import dayjs from 'dayjs';
import isTodayPlugin from 'dayjs/plugin/isToday';
import isSameOrAfterPlugin from 'dayjs/plugin/isSameOrAfter';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
dayjs.extend(isTodayPlugin);
dayjs.extend(isSameOrAfterPlugin);
dayjs.extend(isBetweenPlugin);
export var subDate = function (date, count, unit) {
    return dayjs(date).subtract(count, unit);
};
export var isToday = function (date) {
    return dayjs(date).isToday();
};
export var isSameOfAfter = function (date1, date2) {
    return dayjs(date1).isSameOrAfter(date2);
};
export var isBetween = function (date, _a, unit) {
    var date1 = _a[0], date2 = _a[1];
    if (unit === void 0) { unit = null; }
    return dayjs(date).isBetween(date1, date2, unit);
};
export var startOf = function (date, unit) {
    return dayjs(date).startOf(unit);
};
export var endOf = function (date, unit) {
    return dayjs(date).endOf(unit);
};
export var isBetweenPeriod = function (date, unit) {
    if (unit === void 0) { unit = 'month'; }
    var today = new Date();
    var range = [startOf(today, unit), endOf(today, unit)];
    return isBetween(date, range);
};
export var subDays = function (date, days) {
    return subDate(date, days, 'day');
};
export var getDateDiff = function (startDate, endDate, unit) {
    if (endDate === void 0) { endDate = new Date(); }
    return dayjs(endDate).diff(startDate, unit);
};
export var getRalativeFormat = function (startDate, endDate, unit) {
    if (unit === void 0) { unit = 'hour'; }
    var formatter = new Intl.RelativeTimeFormat('en', { style: 'long' });
    var diff = getDateDiff(startDate, endDate, unit);
    return formatter.format(diff, unit);
};
