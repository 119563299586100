import React from 'react';
// Components
import { Vault } from '../vault/Vault';
// Styles
import './vaults.scss';
// Assets
import { AVAXIcon, ETHFilledIcon, PolygonIcon } from '../../../../assets/icons';
export const Vaults = () => {
    return (React.createElement("div", { className: "numisme-vaults" },
        React.createElement(Vault, { progress: 80, blockchainIcon: React.createElement(PolygonIcon, { width: 30, height: 30 }) }),
        React.createElement(Vault, { progress: 50, blockchainIcon: React.createElement(AVAXIcon, { width: 30, height: 30 }) }),
        React.createElement(Vault, { progress: 20, blockchainIcon: React.createElement(ETHFilledIcon, { width: 30, height: 30 }) })));
};
