import React from 'react';
import { InfoTooltip } from '@multifarm/widget-components';
// Components
import { InfoCard } from '../../../../components/info-card/InfoCard';
// Utils
import { formatMoney, formatPercent } from '../../../../common/utils/format.utils';
// Styles
import './info-cards.scss';
export const InfoCards = () => {
    return (React.createElement("div", { className: "vault-details-page-info-cards" },
        React.createElement(InfoCard, { title: "Total Deposited", body: formatMoney(123000000), titleTooltip: "Total value deposited into the vault" }),
        React.createElement(InfoCard, { title: "Available Credit", body: formatMoney(12000000) }),
        React.createElement(InfoCard, { title: "Collateral Value", body: formatMoney(123000000), footer: React.createElement("div", { className: "flex-row" },
                "Net position: 0 USD",
                ' ',
                React.createElement(InfoTooltip, { content: 'Your position in the collateral' })) }),
        React.createElement(InfoCard, { title: "Net APY", body: formatPercent(112.1, 2), footer: "$0 yearly" }),
        React.createElement(InfoCard, { title: "Loan-to-Value", body: "0", footer: "Borrowed: $0 USD" }),
        React.createElement(InfoCard, { title: "Est. Maturity Date", body: "Sep 19, 2022", footer: "Duration: 0 Days" })));
};
