import useSwr from 'swr';
// Modals
import { ProtocolMetrics } from '../../models/domain';
export var useProtocolMetrics = function () {
    var _a = useSwr('/auth/protocols/get_protocol_metrics'), response = _a.data, error = _a.error;
    var isLoading = !response && !error;
    var data = (response !== null && response !== void 0 ? response : {}).data;
    var protocolMetrics = (data !== null && data !== void 0 ? data : [undefined])[0];
    return {
        isLoading: isLoading,
        data: protocolMetrics ? new ProtocolMetrics(protocolMetrics) : undefined
    };
};
