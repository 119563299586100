// Static
import { NETWORK_ICONS, NETWORK_NAMES, NetworksEnum } from '../static';
// Utils
import { capitalize } from './string.utils';
// Icons
import { ETHIcon } from '../../assets/icons';
/**
 * Formats the given address string to a shorter version.
 * @param address - Web3 address.
 * @returns A shorter version of the address.
 */
export const shortenAddress = (address) => {
    return `${address.slice(0, 5)}...${address.slice(address.length - 3, address.length)}`;
};
/**
 * @param chainId
 * @returns Network's icon by its chain id.
 */
export const getNetworkIcon = (chainId) => {
    const icon = NETWORK_ICONS[chainId];
    if (!icon) {
        return ETHIcon;
    }
    return icon;
};
/**
 * @param network - Network details object.
 * @returns Network's icon by its chain id.
 */
export const getNetworkName = (network) => {
    let networkName = NETWORK_NAMES[network.chainId];
    if (networkName) {
        return networkName;
    }
    return capitalize(network.name);
};
export const getCustomRPC = () => ({
    [NetworksEnum.ETH]: 'https://eth.public-rpc.com',
    [NetworksEnum.AVAX]: 'https://avalanche.public-rpc.com',
    [NetworksEnum.POLYGON]: 'https://polygon-rpc.com',
    [NetworksEnum.BSC]: 'https://bscrpc.com'
});
