import React from 'react';
import clsx from 'clsx';
// Styles
import './header-tab.scss';
export const HeaderTab = ({ title, onClick, active = false }) => {
    return (React.createElement("div", { className: clsx('page-header-tab', {
            'page-header-tab-active': active,
            clickable: !!onClick
        }), onClick: onClick },
        React.createElement("span", null, title)));
};
