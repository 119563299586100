var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import { useWeb3Context } from '../contexts/web3.context';
import { getBalance, getWalletAddress } from '../utils/helpers';
export const useBalance = (contractAddress) => {
    const [isLoading, setLoading] = useState(false);
    const [balance, setBalance] = useState(ethers.BigNumber.from(0));
    const { provider } = useWeb3Context();
    useEffect(() => {
        const init = () => __awaiter(void 0, void 0, void 0, function* () {
            if (!provider) {
                return 0;
            }
            try {
                setLoading(true);
                const address = yield getWalletAddress(provider);
                const walletBalance = yield getBalance(provider, contractAddress, address);
                setBalance(walletBalance);
                setLoading(false);
            }
            catch (err) {
                setLoading(false);
                setBalance(ethers.BigNumber.from(0));
            }
        });
        init();
    }, [provider]);
    return { isLoading, balance };
};
