var ProtocolMetrics = /** @class */ (function () {
    function ProtocolMetrics(data) {
        this.id = data.id;
        this.dateUpdated = data.dateUpdated;
        this.artworkListed = data.artwork_listed;
        this.nodesListed = data.nodes_listed;
        this.totalListed = data.total_listed;
        this.artworkBids = data.artwork_bids;
        this.nodesBids = data.nodes_bids;
        this.totalBids = data.total_bids;
        this.artworkSold = data.artwork_sold;
        this.nodesSold = data.nodes_sold;
        this.totalSold = data.total_sold;
        this.nodesSoldPercentage = data.nodes_sold_percentage;
        this.artworkSoldPercentage = data.artwork_sold_percentage;
        this.thorListed = data.thor_listed;
        this.odinListed = data.odin_listed;
        this.odinBids = data.odin_bids;
        this.thorBids = data.thor_bids;
        this.odinSold = data.odin_sold;
        this.odinSoldPercentage = data.odin_sold_percentage;
        this.thorSold = data.thor_sold;
        this.thorSoldPercentage = data.thor_sold_percentage;
        this.artworkListedHistorical = data.artwork_listed_historical;
        this.nodesListedHistorical = data.nodes_listed_historical;
        this.totalListedHistorical = data.total_listed_historical;
        this.artworkBidsHistorical = data.artwork_bids_historical;
        this.nodesBidsHistorical = data.nodes_bids_historical;
        this.totalBidsHistorical = data.total_bids_historical;
        this.artworkSoldHistorical = data.artwork_sold_historical;
        this.nodesSoldHistorical = data.nodes_sold_historical;
        this.totalSoldHistorical = data.total_sold_historical;
        this.nodesSoldPercentageHistorical = data.nodes_sold_percentage_historical;
        this.artworkSoldPercentageHistorical =
            data.artwork_sold_percentage_historical;
        this.thorListedHistorical = data.thor_listed_historical;
        this.odinListedHistorical = data.odin_listed_historical;
        this.odinBidsHistorical = data.odin_bids_historical;
        this.thorBidsHistorical = data.thor_bids_historical;
        this.odinSoldHistorical = data.odin_sold_historical;
        this.odinSoldPercentageHistorical = data.odin_sold_percentage_historical;
        this.thorSoldHistorical = data.thor_sold_historical;
        this.thorSoldPercentageHistorical = data.thor_sold_percentage_historical;
        this.totalVolumeUsd = data.total_volume_usd;
        this.totalVolumeUsdHistorical = data.total_volume_usd_historical;
        this.totalVolumeAvax = data.total_volume_avax;
        this.totalVolumeAvaxHistorical = data.total_volume_avax_historical;
        this.totalVolumeAvaxDailyHistorical =
            data.total_volume_avax_daily_historical;
        this.totalVolumeAvaxCumulativeHistorical =
            data.total_volume_avax_cumulative_historical;
        this.bidsByTypeHistorical = data.bids_by_type_historical;
        this.listedByTypeHistorical = data.listed_by_type_historical;
        this.soldByType = data.sold_by_type;
        this.soldByTypeHistorical = data.sold_by_type_historical;
        this.soldByTypePercentage = data.sold_by_type_percentage;
        this.soldByTypePercentageHistorical =
            data.sold_by_type_percentage_historical;
    }
    return ProtocolMetrics;
}());
export { ProtocolMetrics };
