var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import { useWeb3Context } from '../contexts/web3.context';
import { getLockerCurrentRatio, getLockerMaxRatio } from '../utils/helpers';
export const useLockerRatio = (lockerAddress) => {
    const { provider } = useWeb3Context();
    const [lockerRatio, setLockerRatio] = useState({
        maxRatio: 0,
        currentRatio: 0
    });
    useEffect(() => {
        if (!provider) {
            return;
        }
        const init = () => __awaiter(void 0, void 0, void 0, function* () {
            const max = yield getLockerMaxRatio(provider, lockerAddress);
            const current = yield getLockerCurrentRatio(provider, lockerAddress);
            const maxRatio = Number.parseFloat(ethers.utils.formatEther(max));
            const currentRatio = Number.parseFloat(ethers.utils.formatEther(current));
            setLockerRatio({
                maxRatio,
                currentRatio: Math.min(maxRatio, currentRatio)
            });
        });
        init();
    }, [provider, lockerAddress]);
    return lockerRatio;
};
