var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// Libs
import React, { useMemo } from 'react';
// Components
import { PieChartCard } from '../../../components/PieChartCard';
// Hooks API
import { useLiquidationsTokens } from '../../../hooks/api/useLiquidationsTokens';
// Utils
import { getDepositBorrowCharts } from '../../../utils/chart';
import { formatPercent, formatTooltipMoney } from '../../../utils/format';
// Styles
import s from '../overview.module.css';
var toooltipOptions = {
    categoryKey: 'key',
    formatCategory: function (key) { return (React.createElement("span", { className: s.tooltipCategory }, key)); },
    tooltipKeys: ['weight', 'value'],
    formatTooltipKey: function (key) { return (React.createElement("span", { className: s.tooltipKey }, key)); },
    formatTooltipValue: function (tick, key) {
        if (key === 'weight') {
            return formatPercent(tick);
        }
        return formatTooltipMoney(tick);
    }
};
export var DepositBorrowCharts = function () {
    var _a = useLiquidationsTokens(), data = _a.data, isLoading = _a.isLoading;
    var _b = useMemo(function () { return getDepositBorrowCharts(data); }, [data]), depositChart = _b.depositChart, borrowChart = _b.borrowChart, keys = _b.keys, updated = _b.updated;
    return (React.createElement("div", { className: s.bastionCollateralBlock },
        React.createElement(PieChartCard, __assign({ title: "Deposit", data: depositChart, dataKey: "weight", details: "USDC", detailsClassName: s.chartDetails, legends: keys, legendsClassName: s.chartLegends, isLoading: isLoading, updatedAt: updated }, toooltipOptions)),
        React.createElement(PieChartCard, __assign({ title: "Borrow", data: borrowChart, dataKey: "weight", details: "USDC", detailsClassName: s.chartDetails, isLoading: isLoading, legends: keys, legendsClassName: s.chartLegends, updatedAt: updated }, toooltipOptions))));
};
