import React, { useMemo } from 'react';
import { SWRConfig } from 'swr';
// Services
import { getAuthFetcher } from './common/services';
// Components
import { App } from './App';
// Styles
import './assets/styles/main.scss';
export var ThorFinance = function (_a) {
    var token = _a.token;
    var config = useMemo(function () { return ({ fetcher: getAuthFetcher(token) }); }, [token]);
    return (React.createElement(SWRConfig, { value: config },
        React.createElement(App, null)));
};
