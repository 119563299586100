import { createContext, useContext } from 'react'

const ThemeContext = createContext({
  theme: 'squiddao',
  themeColors: 'light',
  lng: 'en',
  expoProvider: 'Farming',
  alchemixProvider: 'Treasury',
  layer2DaoProvider: 'Tokenomics'
})

export function ThemeProvider({
  children,
  theme,
  themeColors,
  lng,
  onExpoProvider,
  onAlchemixProvider,
  alchemixProvider,
  expoProvider,
  layer2DaoProvider,
  onLayer2DaoProvider
}) {
  return (
    <ThemeContext.Provider
      value={{
        theme,
        themeColors,
        lng,
        expoProvider,
        onExpoProvider,
        alchemixProvider,
        onAlchemixProvider,
        layer2DaoProvider,
        onLayer2DaoProvider
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}

export function useThemeContext() {
  return useContext(ThemeContext)
}
