// Icons
import { AVAXIcon, PolygonIcon, BSCIcon } from '../../assets/icons';
export const NETWORK_ICONS = {
    56: BSCIcon,
    137: PolygonIcon,
    43114: AVAXIcon
};
export const NETWORK_NAMES = {
    56: 'Binance',
    137: 'Polygon',
    43114: 'Avalanche'
};
export var NetworksEnum;
(function (NetworksEnum) {
    NetworksEnum[NetworksEnum["ETH"] = 4] = "ETH";
    NetworksEnum[NetworksEnum["POLYGON"] = 137] = "POLYGON";
    NetworksEnum[NetworksEnum["AVAX"] = 43114] = "AVAX";
    NetworksEnum[NetworksEnum["BSC"] = 56] = "BSC";
})(NetworksEnum || (NetworksEnum = {}));
