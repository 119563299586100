import React from 'react';
import clsx from 'clsx';
// Styles
import './header-tab.scss';
export var HeaderTab = function (_a) {
    var title = _a.title, onClick = _a.onClick, _b = _a.active, active = _b === void 0 ? false : _b, _c = _a.comingSoon, comingSoon = _c === void 0 ? false : _c;
    return (React.createElement("div", { className: clsx('multifarm-thor-finance-header-tab', {
            'multifarm-thor-finance-header-tab-active': active,
            clickable: !!onClick,
            'multifarm-thor-finance-header-tab-coming-soon': comingSoon
        }), onClick: onClick },
        React.createElement("div", null, title),
        comingSoon && React.createElement("div", null, "COMING SOON")));
};
