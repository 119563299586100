import React from 'react';
// Components
import { HeaderTab } from './HeaderTab';
// Static
import { PageEnum } from '../../../common/static';
// Styles
import './header-tabs.scss';
export var HeaderTabs = function (_a) {
    var activePage = _a.activePage, setPage = _a.setPage;
    return (React.createElement("div", { className: "multifarm-thor-finance-header-tabs" },
        React.createElement(HeaderTab, { title: PageEnum.CAPSULE_ANALYTICS, onClick: function () { return setPage(PageEnum.CAPSULE_ANALYTICS); }, active: activePage === PageEnum.CAPSULE_ANALYTICS }),
        React.createElement(HeaderTab, { title: PageEnum.GAMELOOP_STATS, onClick: function () { return setPage(PageEnum.GAMELOOP_STATS); }, active: activePage === PageEnum.GAMELOOP_STATS })));
};
