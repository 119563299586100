import { ACCEPTABLE_NODE_NAMES, NodeName } from '../../static';
var ThorNode = /** @class */ (function () {
    function ThorNode(data) {
        this.address = data.address;
        this.name = ACCEPTABLE_NODE_NAMES.includes(data.name)
            ? data.name
            : NodeName.UNKNOWN;
        this.collectionId = data.collection_id;
        this.dailyNodes = data.daily_nodes;
        this.dailyNodesHistorical = data.daily_nodes_historical;
        this.weeklyNodes = data.weekly_nodes;
        this.weeklyNodesHistorical = data.weekly_nodes_historical;
        this.monthlyNodes = data.monthly_nodes;
        this.monthlyNodesHistorical = data.monthly_nodes_historical;
        this.totalSupply = data.total_supply;
        this.totalSupplyHistorical = data.total_supply_historical;
        this.dateUpdated = data.dateUpdated;
        this.rarePercentage = data.rare_percentage;
        this.commonPercentage = data.common_percentage;
        this.perksAppliedSigma = data.perks_applied_sigma;
        this.perksAppliedSigmaHistorical = data.perks_applied_sigma_historical;
        this.perksAppliedGamma = data.perks_applied_gamma;
        this.perksAppliedGammaHistorical = data.perks_applied_gamma_historical;
        this.perksAppliedDelta = data.perks_applied_delta;
        this.perksAppliedDeltaHistorical = data.perks_applied_delta_historical;
        this.perksAppliedTotal = data.perks_applied_total;
        this.perksAppliedTotalHistorical = data.perks_applied_total_historical;
    }
    return ThorNode;
}());
export { ThorNode };
